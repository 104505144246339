import type { Component } from 'vue'
import colors from '~/colors.json'

import ITeamsIcon from '~icons/brands/microsoft'
import ISlackIcon from '~icons/brands/slack'
import ILightBell from '~icons/light/bell'
import ILightCodePullRequest from '~icons/light/code-pull-request'
import ILightDiagramProject from '~icons/light/diagram-project'
import ILightPaintbrushPencil from '~icons/light/paintbrush-pencil'
import ILightSquareCode from '~icons/light/square-code'

export interface TagMapping {
  label: string
  icon?: Component
  color?: string
  tag?: string
  type: TagTypeEnum
}

enum TagTypeEnum {
  Type = 'type',
  Category = 'category',
  Dora = 'dora',
  None = 'none',
}

export enum hiddenTags {
  Recommended = 'recommended',
  GitWrite = 'git_write',
  ProjectOnly = 'project_only',
}

const tagMappings = {
  pr_check: {
    label: 'PR check',
    icon: ILightCodePullRequest,
    color: colors.brand['500'],
    type: TagTypeEnum.Type,
  },
  notification: {
    label: 'Notification',
    icon: ILightBell,
    color: colors.purple['400'],
    type: TagTypeEnum.Type,
  },
  workflow: {
    label: 'Workflow',
    icon: ILightDiagramProject,
    color: colors.bluegray['400'],
    type: TagTypeEnum.Type,
  },
  custom: {
    label: 'Custom',
    icon: ILightPaintbrushPencil,
    color: colors.blue['500'],
    type: TagTypeEnum.Type,
  },
  action: {
    label: 'Action',
    icon: ILightSquareCode,
    color: colors.lightblue['400'],
    type: TagTypeEnum.Type,
  },
  pull_requests: {
    label: 'Pull requests',
    type: TagTypeEnum.Category,
  },
  incidents: {
    label: 'Incidents',
    type: TagTypeEnum.Category,
  },
  ci_cd: {
    label: 'CI/CD',
    type: TagTypeEnum.Category,
  },
  issues: {
    label: 'Issues',
    type: TagTypeEnum.Category,
  },
  frequency: {
    label: 'Frequency',
    type: TagTypeEnum.Dora,
  },
  lead_time: {
    label: 'Lead time',
    type: TagTypeEnum.Dora,
  },
  failure_rate: {
    label: 'Failure rate',
    type: TagTypeEnum.Dora,
  },
  mttr: {
    label: 'MTTR',
    type: TagTypeEnum.Dora,
  },
  review_lag: {
    label: 'Review lag',
    type: TagTypeEnum.Dora,
  },
  review_time: {
    label: 'Review time',
    type: TagTypeEnum.Dora,
  },
  deploy_time: {
    label: 'Deploy time',
    type: TagTypeEnum.Dora,
  },
  coding_time: {
    label: 'Coding time',
    type: TagTypeEnum.Dora,
  },
  batch_size: {
    label: 'Batch size',
    type: TagTypeEnum.Dora,
  },
  slack: {
    label: 'Slack',
    icon: ISlackIcon,
    color: colors.purple['400'],
    type: TagTypeEnum.None,
  },
  microsoft_teams: {
    label: 'Microsoft Teams',
    icon: ITeamsIcon,
    color: colors.purple['400'],
    type: TagTypeEnum.None,
  },
} as Record<string, TagMapping>

export default function getTagsAndToolType(tags: string[] = []): {
  tags: TagMapping[]
  toolType?: TagMapping
  isRecommended?: boolean
} {
  const sortOrder = [
    TagTypeEnum.Type,
    TagTypeEnum.Category,
    TagTypeEnum.Dora,
    TagTypeEnum.None,
  ]
  const tagsData = tags
    .filter(tag => !(Object.values(hiddenTags) as string[]).includes(tag))
    .map(tag =>
      tagMappings[tag]
        ? { ...tagMappings[tag], tag }
        : { label: tag, type: TagTypeEnum.None, tag },
    )
    .sort((a, b) => {
      const indexA = sortOrder.indexOf(a.type)
      const indexB = sortOrder.indexOf(b.type)
      return indexA - indexB
    })
  const toolType = tagsData.find(tag => tag.type === TagTypeEnum.Type)
  const isRecommended = tags.includes(hiddenTags.Recommended)

  return { tags: tagsData, toolType, isRecommended }
}
