<script setup lang="ts">
import { format, parseISO } from 'date-fns'

import type { GetToolDetailsQuery } from './ToolDetails.generated'
import getTagsAndToolType from '~/pages/automations/tools/tag-mapping'
import { DATE_FORMAT } from '~/utils/date'

const props = defineProps<{
  tool: GetToolDetailsQuery['tool']
  customName?: string
}>()
const { tags, toolType } = getTagsAndToolType(props.tool?.tags)
</script>

<template>
  <div class="tw-flex tw-flex-row">
    <div class="tw-mr-4">
      <div
        class="tw-w-max tw-rounded-md tw-p-7 tw-leading-none tw-text-white"
        :style="{ backgroundColor: toolType?.color }"
      >
        <component :is="toolType?.icon" class="tw-text-2xl" />
      </div>
    </div>
    <div class="tw-flex tw-flex-auto tw-flex-col">
      <div class="tw-mb-1 tw-text-xs tw-font-semibold">
        {{ toolType?.label }}
      </div>
      <div
        class="tw-text-xl tw-font-semibold tw-text-gray-900 dark:tw-text-white"
        :data-test="customName ? 'tool-name' : ''"
      >
        {{ customName || tool?.name }}
      </div>
      <div class="tw-mt-auto tw-flex tw-items-center">
        <div class="tw-mr-5">
          <RouterLink
            v-for="tag in tags"
            :key="tag.tag"
            :to="{ name: 'automations', query: { filter: tag.tag } }"
            class="tw-mr-1 tw-rounded-md tw-bg-gray-200 tw-px-1.5 tw-py-0.5 tw-text-xxs tw-font-semibold tw-text-gray-500 hover:tw-underline dark:tw-bg-gray-700 dark:tw-text-gray-300"
            data-element="tool-tag"
          >
            {{ tag.label }}
          </RouterLink>
        </div>
        <div class="svg-baseline tw-ml-auto tw-text-xs">
          <ILightClock class="tw-mr-1" />Published:
          {{ format(parseISO(tool?.publishedOn), DATE_FORMAT) }}
        </div>
      </div>
    </div>
  </div>
</template>
