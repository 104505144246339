import { useQuery } from 'villus'
import { type Ref, computed, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import {
  ProjectInstalledToolSlugsDocument,
  TeamInstalledToolSlugsDocument,
} from './CustomYaml.generated'
import { addToast } from '~/composables/toast-messages'
import { MessageTypeEnum } from '~/types/graphql'
import { getListOfNodes } from '~/utils/utils'

const FIRST_CUSTOM_YAML_TOOL_SLUG_NUMBER = 101
const LAST_AVAILABLE_CUSTOM_YAML_TOOL_SLUG_NUMBER = 109
const ONE_TOO_MANY_CUSTOM_YAML_TOOL_SLUG = `CT-${
  LAST_AVAILABLE_CUSTOM_YAML_TOOL_SLUG_NUMBER + 1
}`

export interface CustomYamlTool {
  yamlValue: string
  toolDescription: string
  toolName: string
  valid: boolean
}

// make all rules nested under `rules` key, to keep current validation logic
export function wrapYamlWithRulesRootKey(yaml: string) {
  return `rules:
  ${yaml.replaceAll('\n', '\n  ')}`
}

export function stringifyForSaving(customYamlTool?: CustomYamlTool) {
  return JSON.stringify({
    customYaml: customYamlTool?.yamlValue,
    description: customYamlTool?.toolDescription,
    name: customYamlTool?.toolName,
  })
}

export const isCustomYamlTool = (toolSlug: string) => toolSlug.startsWith('CT-')

function findFirstAvailableCustomYamlToolSlug(takenSlugs: string[]): string {
  // slugs start with `CT-101` and increment by 1
  let slugNumber = FIRST_CUSTOM_YAML_TOOL_SLUG_NUMBER
  while (takenSlugs.includes(`CT-${slugNumber}`)) {
    slugNumber++
    if (slugNumber > LAST_AVAILABLE_CUSTOM_YAML_TOOL_SLUG_NUMBER) {
      // sweet problem to have, Sentry will capture this
      console.error('Too many custom automation tools installed')
      addToast(
        'All custom automation tools already installed. If you need more, please contact support.',
        MessageTypeEnum.Error,
        true,
      )
      break
    }
  }
  return `CT-${slugNumber}`
}

export function useNextAvailableCustomAutomationSlug({
  orgSlug,
  automationOwner,
  projectSlug,
  teamSlug,
}: {
  orgSlug: string
  automationOwner: Ref<'team' | 'project'>
  projectSlug: Ref<string | null>
  teamSlug: Ref<string | null>
}) {
  const router = useRouter()
  const route = useRoute()

  const { data: projectTools } = useQuery({
    query: ProjectInstalledToolSlugsDocument,
    variables: {
      orgSlug,
      projectSlug: projectSlug.value?.toString() ?? '',
    },
    paused: () => !(automationOwner.value === 'project' && projectSlug.value),
    tags: ['installedTools'],
  })

  const { data: teamTools } = useQuery({
    query: TeamInstalledToolSlugsDocument,
    variables: {
      orgSlug,
      teamSlug: teamSlug.value?.toString() ?? '',
    },
    paused: () => !(automationOwner.value === 'team' && teamSlug.value),
    tags: ['installedTools'],
  })

  const nextAvailableCustomYamlToolSlug = computed(() => {
    return findFirstAvailableCustomYamlToolSlug(
      getListOfNodes(
        automationOwner.value === 'project'
          ? projectTools.value?.project.installedTools
          : teamTools.value?.team.installedTools,
      )
        .filter(node => node.tool.slug.startsWith('CT-'))
        .map(node => node.tool.slug),
    )
  })

  watch(nextAvailableCustomYamlToolSlug, async (value) => {
    if (value !== route.params.toolSlug) {
      // redirect to latest available custom automation slug
      await router.replace({
        name: 'toolInstall',
        params: {
          orgSlug,
          toolSlug: value,
        },
      })
    }
  })

  return {
    lastCustomToolInstalled: computed(
      () => route.params.toolSlug === ONE_TOO_MANY_CUSTOM_YAML_TOOL_SLUG,
    ),
  }
}
