export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /**
   * Same scalar as `DateTime` type but if date time string does not contain timezone information
   * it will default to UTC timezone.
   * Value is specified by [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: { input: any; output: any; }
  /**
   * Allows use of a JSON String for input / output from the GraphQL schema.
   *
   * Use of this type is *not recommended* as you lose the benefits of having a defined, static
   * schema (one of the key benefits of GraphQL).
   */
  JSONString: { input: any; output: any; }
  /**
   * The `Time` scalar type represents a Time value as
   * specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Time: { input: any; output: any; }
  /**
   * Create scalar that ignores normal serialization/deserialization, since
   * that will be handled by the multipart request spec
   */
  Upload: { input: any; output: any; }
};

export enum AccRanking {
  /** Elite */
  Elite = 'ELITE',
  /** High */
  High = 'HIGH',
  /** Low */
  Low = 'LOW',
  /** Medium */
  Medium = 'MEDIUM'
}

export type AccessTokenConnection = {
  __typename?: 'AccessTokenConnection';
  edges: Array<AccessTokenNodeEdge>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of edges. */
  totalCount: Scalars['Int']['output'];
};

export type AccessTokenNode = {
  __typename?: 'AccessTokenNode';
  created: Scalars['String']['output'];
  expires: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  label: Scalars['String']['output'];
  scope: OAuthScopeType;
  token: Scalars['String']['output'];
};

export type AccessTokenNodeEdge = {
  __typename?: 'AccessTokenNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: AccessTokenNode;
};

export type ActionResultApprovalVoteType = {
  __typename?: 'ActionResultApprovalVoteType';
  approved: Scalars['Boolean']['output'];
  on: Scalars['DateTime']['output'];
  user: UserType;
};

export enum ActionResultStatus {
  /** Error */
  Error = 'ERROR',
  /** Failure */
  Failure = 'FAILURE',
  /** Pending */
  Pending = 'PENDING',
  /** Success */
  Success = 'SUCCESS',
  /** Unknown */
  Unknown = 'UNKNOWN'
}

export type ActionResultType = {
  __typename?: 'ActionResultType';
  action: ActionType;
  message: Scalars['String']['output'];
  on: Scalars['DateTime']['output'];
  status: ActionResultStatus;
  votes: Array<ActionResultApprovalVoteType>;
};

export type ActionType = {
  __typename?: 'ActionType';
  description: Scalars['String']['output'];
  lineNumber: Scalars['Int']['output'];
  on: Scalars['DateTime']['output'];
  order: Scalars['Int']['output'];
  parameters: Scalars['JSONString']['output'];
  rule: RuleType;
  type: Scalars['String']['output'];
};

export type AddContributorsMutationInput = {
  /** List of contributor emails to be created and added to the team */
  emails: Array<InputMaybe<Scalars['String']['input']>>;
  teamSlug?: InputMaybe<Scalars['ID']['input']>;
};

export type AddContributorsMutationPayload = {
  __typename?: 'AddContributorsMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  success: Scalars['Boolean']['output'];
};

export type AddOrgMetricsNotification = {
  __typename?: 'AddOrgMetricsNotification';
  /** The newly created notification, if one was created */
  notification?: Maybe<NotificationModelType>;
};

export type AddTeamMembersMutationInput = {
  /** List of user IDs to be added to the team */
  members: Array<InputMaybe<Scalars['String']['input']>>;
  slug: Scalars['ID']['input'];
};

export type AddTeamMembersMutationPayload = {
  __typename?: 'AddTeamMembersMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  success: Scalars['Boolean']['output'];
};

export type AddTeamMetricsNotification = {
  __typename?: 'AddTeamMetricsNotification';
  /** The newly created notification, if one was created */
  notification?: Maybe<NotificationModelType>;
};

export type AddWipNotification = {
  __typename?: 'AddWipNotification';
  /** The newly created notification, if one was created */
  notification?: Maybe<NotificationModelType>;
};

export type AllowedLoginMethodGqlType = {
  __typename?: 'AllowedLoginMethodGQLType';
  provider?: Maybe<Scalars['String']['output']>;
  type: AllowedLoginMethodType;
  url: Scalars['String']['output'];
};

export enum AllowedLoginMethodType {
  /** Sleuth-managed username and password */
  Password = 'PASSWORD',
  /** Third-party OAuth-based authentication provider */
  Provider = 'PROVIDER'
}

export enum ApprovalStatus {
  Error = 'ERROR',
  Failure = 'FAILURE',
  Pending = 'PENDING',
  Success = 'SUCCESS',
  Unknown = 'UNKNOWN'
}

export type AuthorType = {
  __typename?: 'AuthorType';
  name: Scalars['String']['output'];
};

export enum BatchSize {
  /** Gigantic */
  Gigantic = 'GIGANTIC',
  /** Large */
  Large = 'LARGE',
  /** Medium */
  Medium = 'MEDIUM',
  /** Small */
  Small = 'SMALL'
}

export type BatchWorkingHoursDeleteInputType = {
  userIds: Array<Scalars['String']['input']>;
};

export type BatchWorkingHoursInputType = {
  duration: Scalars['String']['input'];
  recurrence: Scalars['String']['input'];
  startTime: Scalars['Time']['input'];
  userIds: Array<Scalars['String']['input']>;
};

export type BlamelessIncidentImpactSourceInputType = {
  integrationSlug?: InputMaybe<Scalars['String']['input']>;
  /** Blameless severity threshold, default is 'All' */
  remoteSeverityThreshold?: InputMaybe<Scalars['String']['input']>;
  remoteTypes?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type BlamelessProviderData = {
  __typename?: 'BlamelessProviderData';
  remoteSeverityThreshold?: Maybe<Scalars['String']['output']>;
  remoteTypes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type BlamelessRemoteSeverity = {
  __typename?: 'BlamelessRemoteSeverity';
  label: Scalars['String']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type BlamelessRemoteType = {
  __typename?: 'BlamelessRemoteType';
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type BranchType = {
  __typename?: 'BranchType';
  displayName: Scalars['String']['output'];
  prefix: Scalars['String']['output'];
  rawName: Scalars['String']['output'];
};

export type BreakdownFiltersInputType = {
  deploymentSlugs?: InputMaybe<Array<Scalars['ID']['input']>>;
  environmentName?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<IssueBreakdownType>;
  filterValuesExclude?: InputMaybe<Array<Scalars['String']['input']>>;
  filterValuesInclude?: InputMaybe<Array<Scalars['String']['input']>>;
  projectSlugs?: InputMaybe<Array<Scalars['ID']['input']>>;
  teamSlugs?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type BrokenConfigurationConnection = {
  __typename?: 'BrokenConfigurationConnection';
  edges: Array<BrokenConfigurationNodeTypeEdge>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of edges. */
  totalCount: Scalars['Int']['output'];
};

export type BrokenConfigurationNodeType = {
  __typename?: 'BrokenConfigurationNodeType';
  brokenOn: Scalars['DateTime']['output'];
  brokenProvider?: Maybe<Provider>;
  name: Scalars['String']['output'];
  projectName: Scalars['String']['output'];
  projectSlug: Scalars['String']['output'];
  reason: BrokenConfigurationReasonType;
  slug: Scalars['String']['output'];
  type: BrokenConfigurationType;
};

export type BrokenConfigurationNodeTypeEdge = {
  __typename?: 'BrokenConfigurationNodeTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: BrokenConfigurationNodeType;
};

export enum BrokenConfigurationReasonType {
  /** Code deployment's CI/CD configuration appears to be broken. */
  CiCdConfig = 'CI_CD_CONFIG',
  /** Connection with the remote system appears to be broken. */
  Connection = 'CONNECTION',
  /** Code deployment's configuration is not complete. Please configure deployment tracking type. */
  NotComplete = 'NOT_COMPLETE'
}

export enum BrokenConfigurationType {
  /** Build */
  Build = 'BUILD',
  /** Code */
  Code = 'CODE',
  /** Error */
  Error = 'ERROR',
  /** Feature Flag */
  FeatureFlag = 'FEATURE_FLAG',
  /** Incident */
  Incident = 'INCIDENT',
  /** Manual */
  Manual = 'MANUAL',
  /** Metric */
  Metric = 'METRIC'
}

export enum BuildImpactProvider {
  /** Azure */
  Azure = 'AZURE',
  /** Bitbucket Pipelines */
  BitbucketPipelines = 'BITBUCKET_PIPELINES',
  /** Buildkite */
  Buildkite = 'BUILDKITE',
  /** Circleci */
  Circleci = 'CIRCLECI',
  /** Github */
  Github = 'GITHUB',
  /** Github Enterprise */
  GithubEnterprise = 'GITHUB_ENTERPRISE',
  /** Gitlab */
  Gitlab = 'GITLAB',
  /** Jenkins */
  Jenkins = 'JENKINS'
}

export type BuildImpactSource = ImpactSourceInterfaceType & {
  __typename?: 'BuildImpactSource';
  buildBranch?: Maybe<Scalars['String']['output']>;
  buildJobName?: Maybe<Scalars['String']['output']>;
  buildName: Scalars['String']['output'];
  buildProjectKey?: Maybe<Scalars['String']['output']>;
  editLink: Scalars['String']['output'];
  environment: Environment;
  integrationAuthSlug?: Maybe<Scalars['String']['output']>;
  latestImpacts: Array<LinkedImpactType>;
  name: Scalars['String']['output'];
  ostracizedOn?: Maybe<Scalars['DateTime']['output']>;
  provider: Scalars['String']['output'];
  registerImpactLink: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  type: ImpactType;
};

export type BuildModelType = {
  __typename?: 'BuildModelType';
  name: Scalars['String']['output'];
  status: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type BuildPaginatedType = {
  __typename?: 'BuildPaginatedType';
  count: Scalars['Int']['output'];
  hasNext: Scalars['Boolean']['output'];
  hasPrev: Scalars['Boolean']['output'];
  objects: Array<BuildModelType>;
  page: Scalars['Int']['output'];
  pages: Scalars['Int']['output'];
};

export enum BuildProviderType {
  /** Azure pipelines */
  Azure = 'AZURE',
  /** Bitbucket Pipelines */
  BitbucketPipelines = 'BITBUCKET_PIPELINES',
  /** Builds by Buildkite */
  Buildkite = 'BUILDKITE',
  /** Builds by CircleCI */
  Circleci = 'CIRCLECI',
  /** CodeDeploy */
  Codedeploy = 'CODEDEPLOY',
  /** GitHub Actions */
  Github = 'GITHUB',
  /** GitHub Enterprise Cloud Actions */
  GithubEnterprise = 'GITHUB_ENTERPRISE',
  /** GitLab CI/CD */
  Gitlab = 'GITLAB',
  /** Jenkins jobs */
  Jenkins = 'JENKINS'
}

export enum CltStartDefinitionType {
  /** First time a commit is created. */
  Commit = 'COMMIT',
  /** Either first time an issue transitions to one of the configured states or first time a commit is created.Whatever occurred first */
  FirstEvent = 'FIRST_EVENT',
  /** First time an issue transitions to one of the configured states. */
  Issue = 'ISSUE'
}

export type ChangeLinkType = {
  __typename?: 'ChangeLinkType';
  deploymentName: Scalars['String']['output'];
  link: Scalars['String']['output'];
};

export type ChangeModelType = {
  __typename?: 'ChangeModelType';
  action?: Maybe<Scalars['String']['output']>;
  approvalStatuses: Array<ApprovalStatus>;
  approvals: Array<ActionResultType>;
  authorFullName: Scalars['String']['output'];
  authorGravatar: Scalars['String']['output'];
  authors: Array<DisplayAuthorType>;
  builds: BuildPaginatedType;
  changeLeadTime: Scalars['Int']['output'];
  changeSourceType: ChangeSourceType;
  /** Time spent between first commit or first issue transition and a pull request being opened */
  codingTime: Scalars['Int']['output'];
  commits: CommitPaginatedType;
  compareUrl: Scalars['String']['output'];
  deployedToEnvironments: Array<DeployEnvironmentType>;
  /** Time spent between PR merge and deploy */
  deployingTime: Scalars['Int']['output'];
  deploymentBranch: Scalars['String']['output'];
  deploymentCollectImpact: Scalars['Boolean']['output'];
  deploymentName: Scalars['String']['output'];
  deploymentSlug: Scalars['String']['output'];
  deploymentTrackingType?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  environment: Environment;
  executions: RuleExecutionPaginatedType;
  files: FilePaginatedType;
  /** DEPRECATED We don't create future deploys anymore. */
  futureDeploy: Scalars['Boolean']['output'];
  healthEvents: EnvHealthEventConnection;
  healthManuallySet: Scalars['Boolean']['output'];
  healthTimeBreakdown: HealthBreakdownType;
  impactSuccessor?: Maybe<ChangeModelType>;
  impactSynopses: Array<ImpactSynopsisUnionType>;
  isRollback: Scalars['Boolean']['output'];
  issues: IssuePaginatedType;
  links: Array<LinkType>;
  magnitude: Scalars['String']['output'];
  name: Scalars['String']['output'];
  nextDeploy?: Maybe<ChangeSummary>;
  numOfAllPullRequests: Scalars['Int']['output'];
  numOfIgnoredPullRequests: Scalars['Int']['output'];
  on: Scalars['DateTime']['output'];
  previousDeploy?: Maybe<ChangeSummary>;
  projectEnvironment: Scalars['String']['output'];
  projectName: Scalars['String']['output'];
  projectSlug: Scalars['String']['output'];
  pullRequests: PullRequestPaginatedType;
  relatedChanges: ChangePaginatedType;
  remoteProviderUrl: Scalars['String']['output'];
  /** Time spent between a pull request being opened and the first review */
  reviewLagTime: Scalars['Int']['output'];
  /** Time spent between first review and merge */
  reviewTime: Scalars['Int']['output'];
  revision: Scalars['String']['output'];
  rolledBackDeploys: ChangePaginatedType;
  slug: Scalars['String']['output'];
  startingEnvHealth: DeployHealthType;
  summary: Scalars['String']['output'];
  tags: Array<Scalars['String']['output']>;
  teams: Array<TeamType>;
  timeline?: Maybe<TimelineType>;
  trackingType?: Maybe<DeployTrackingChoiceType>;
};


export type ChangeModelTypeBuildsArgs = {
  page: Scalars['Int']['input'];
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type ChangeModelTypeCommitsArgs = {
  page: Scalars['Int']['input'];
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type ChangeModelTypeExecutionsArgs = {
  page: Scalars['Int']['input'];
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};


export type ChangeModelTypeFilesArgs = {
  page: Scalars['Int']['input'];
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type ChangeModelTypeHealthEventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type ChangeModelTypeIssuesArgs = {
  page: Scalars['Int']['input'];
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type ChangeModelTypePullRequestsArgs = {
  page: Scalars['Int']['input'];
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type ChangeModelTypeRelatedChangesArgs = {
  endDate?: InputMaybe<Scalars['String']['input']>;
  health?: InputMaybe<Scalars['String']['input']>;
  page: Scalars['Int']['input'];
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
};


export type ChangeModelTypeRolledBackDeploysArgs = {
  page: Scalars['Int']['input'];
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type ChangeModelTypeEdge = {
  __typename?: 'ChangeModelTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: ChangeModelType;
};

export type ChangePaginatedType = {
  __typename?: 'ChangePaginatedType';
  count: Scalars['Int']['output'];
  hasNext: Scalars['Boolean']['output'];
  hasPrev: Scalars['Boolean']['output'];
  objects: Array<ChangeType>;
  page: Scalars['Int']['output'];
  pages: Scalars['Int']['output'];
};

export type ChangeRoleMutationInput = {
  role: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export type ChangeRoleMutationPayload = {
  __typename?: 'ChangeRoleMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  success: Scalars['Boolean']['output'];
};

export type ChangeSource = {
  changes: ChangePaginatedType;
  collectImpact: Scalars['Boolean']['output'];
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isBroken: Scalars['Boolean']['output'];
  isCicdBroken: Scalars['Boolean']['output'];
  latestChange?: Maybe<ChangeType>;
  lock: CodeDeploymentLockStatus;
  name: Scalars['String']['output'];
  nextDeploy?: Maybe<ChangeType>;
  noDeploys: Scalars['Boolean']['output'];
  project: ProjectType;
  slug: Scalars['String']['output'];
  type: ChangeSourceType;
};


export type ChangeSourceChangesArgs = {
  endDate?: InputMaybe<Scalars['String']['input']>;
  environmentSlug: Scalars['ID']['input'];
  health?: InputMaybe<Scalars['String']['input']>;
  omitLatestChange?: InputMaybe<Scalars['Boolean']['input']>;
  page: Scalars['Int']['input'];
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
};


export type ChangeSourceLatestChangeArgs = {
  environmentSlug: Scalars['ID']['input'];
};


export type ChangeSourceNextDeployArgs = {
  environmentSlug?: InputMaybe<Scalars['ID']['input']>;
};


export type ChangeSourceNoDeploysArgs = {
  environmentSlug: Scalars['ID']['input'];
};

export type ChangeSourceConnection = {
  __typename?: 'ChangeSourceConnection';
  edges: Array<ChangeSourceFieldTypeEdge>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of edges. */
  totalCount: Scalars['Int']['output'];
};

export type ChangeSourceFieldType = CodeChangeSource | FeatureFlagChangeSource | ManualChangeSource;

export type ChangeSourceFieldTypeEdge = {
  __typename?: 'ChangeSourceFieldTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: ChangeSourceFieldType;
};

export enum ChangeSourceType {
  /** Code */
  Code = 'CODE',
  /** Feature Flag */
  FeatureFlag = 'FEATURE_FLAG',
  /** Manual */
  Manual = 'MANUAL'
}

export type ChangeSummary = {
  __typename?: 'ChangeSummary';
  isAnchorDeploy: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  on: Scalars['String']['output'];
  slug: Scalars['String']['output'];
};

export type ChangeType = {
  __typename?: 'ChangeType';
  approvalStatuses: Array<ApprovalStatus>;
  authorFullName: Scalars['String']['output'];
  authorGravatar: Scalars['String']['output'];
  authors: Array<AuthorType>;
  branch?: Maybe<Scalars['String']['output']>;
  changeLeadTime: Scalars['Int']['output'];
  changeSourceType: ChangeSourceType;
  /** Time spent between first commit or first issue transition and a pull request being opened */
  codingTime: Scalars['Int']['output'];
  commits: Array<CommitType>;
  compareUrl: Scalars['String']['output'];
  /** Time spent between PR merge and deploy */
  deployingTime: Scalars['Int']['output'];
  deploymentCollectImpact: Scalars['Boolean']['output'];
  deploymentName: Scalars['String']['output'];
  deploymentSlug: Scalars['String']['output'];
  deploymentTrackingType?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  environment: Environment;
  healthManuallySet: Scalars['Boolean']['output'];
  healthTimeBreakdown: HealthBreakdownType;
  impactSuccessor?: Maybe<ChangeType>;
  isAnchorDeploy?: Maybe<Scalars['Boolean']['output']>;
  issues: Array<IssueType>;
  magnitude: Scalars['String']['output'];
  name: Scalars['String']['output'];
  nextDeploy?: Maybe<ChangeSummary>;
  numOfAllPullRequests: Scalars['Int']['output'];
  numOfIgnoredPullRequests: Scalars['Int']['output'];
  on: Scalars['DateTime']['output'];
  previousDeploy?: Maybe<ChangeSummary>;
  projectName: Array<Scalars['String']['output']>;
  projectSlug: Array<Scalars['String']['output']>;
  pullRequests: Array<PullRequestType>;
  /** Time spent between a pull request being opened and the first review */
  reviewLagTime: Scalars['Int']['output'];
  /** Time spent between first review and merge */
  reviewTime: Scalars['Int']['output'];
  revision: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  summary: Scalars['String']['output'];
  tags: Array<Scalars['String']['output']>;
  trackingType?: Maybe<DeployTrackingChoiceType>;
  url: Scalars['String']['output'];
  workingHoursUsedForCltCalculation: Scalars['Boolean']['output'];
};

export type ChannelEnvironmentMappingInputType = {
  channelEnvMappings: Array<ChannelMappingInputType>;
  orgSlug: Scalars['ID']['input'];
  projectSlug: Scalars['ID']['input'];
};

export type ChannelEnvironmentMappingType = {
  __typename?: 'ChannelEnvironmentMappingType';
  channelId?: Maybe<Scalars['String']['output']>;
  channelName?: Maybe<Scalars['String']['output']>;
  environmentSlug: Scalars['String']['output'];
};

export type ChannelMappingInputType = {
  /** Channel ID. Only use when channel name couldn't be found */
  channelId?: InputMaybe<Scalars['ID']['input']>;
  /** Name of the channel. Required when channel ID not set. Don't specify when you want to remove channel mapping. */
  channelName?: InputMaybe<Scalars['ID']['input']>;
  environmentSlug: Scalars['ID']['input'];
};

export type ChatChannelDetailsType = {
  __typename?: 'ChatChannelDetailsType';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  provider: Scalars['String']['output'];
};

export type ClaimEmailDomainMutation = {
  __typename?: 'ClaimEmailDomainMutation';
  success: Scalars['Boolean']['output'];
};

export type ClubhouseIncidentImpactSourceInputType = {
  integrationSlug?: InputMaybe<Scalars['String']['input']>;
  /** Need help finding query expression? See the [documentation](https://help.shortcut.com/hc/en-us/articles/360000046646-Searching-in-Shortcut-Using-Search-Operators) for more information. */
  remoteQuery: Scalars['String']['input'];
};

export type ClubhouseProviderData = {
  __typename?: 'ClubhouseProviderData';
  remoteQuery: Scalars['String']['output'];
};

export type CodeChangeSource = ChangeSource & {
  __typename?: 'CodeChangeSource';
  anchorChange?: Maybe<ChangeType>;
  autoTrackingDelay: Scalars['Int']['output'];
  branch: BranchType;
  buildIntegrationSlug?: Maybe<Scalars['String']['output']>;
  buildProvider?: Maybe<TrackBuildProviderType>;
  changes: ChangePaginatedType;
  collectImpact: Scalars['Boolean']['output'];
  deployTrackingBuildMappings: Array<DeployTrackingBuildMappingType>;
  deployTrackingType?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  environmentMappings: Array<EnvironmentMappingType>;
  hasActiveBackfill: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  includeInDashboard: Scalars['Boolean']['output'];
  initializeChangesOnEnvCreation: Scalars['Boolean']['output'];
  isBroken: Scalars['Boolean']['output'];
  isCicdBroken: Scalars['Boolean']['output'];
  isComplete: Scalars['Boolean']['output'];
  lastLock?: Maybe<CodeDeploymentLock>;
  latestChange?: Maybe<ChangeType>;
  lock: CodeDeploymentLockStatus;
  lockingType: CodeDeploymentLockingType;
  locks: Array<CodeDeploymentLock>;
  name: Scalars['String']['output'];
  nextDeploy?: Maybe<ChangeType>;
  noDeploys: Scalars['Boolean']['output'];
  notifyInSlack: Scalars['Boolean']['output'];
  pathPrefix?: Maybe<Scalars['String']['output']>;
  project: ProjectType;
  repository: RepositoryType;
  ruleExecutions: RuleExecutionPaginatedType;
  rules: Array<RuleType>;
  slug: Scalars['String']['output'];
  type: ChangeSourceType;
};


export type CodeChangeSourceAnchorChangeArgs = {
  environmentSlug: Scalars['ID']['input'];
};


export type CodeChangeSourceBranchArgs = {
  environmentSlug: Scalars['ID']['input'];
};


export type CodeChangeSourceChangesArgs = {
  endDate?: InputMaybe<Scalars['String']['input']>;
  environmentSlug: Scalars['ID']['input'];
  health?: InputMaybe<Scalars['String']['input']>;
  omitLatestChange?: InputMaybe<Scalars['Boolean']['input']>;
  page: Scalars['Int']['input'];
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
};


export type CodeChangeSourceLatestChangeArgs = {
  environmentSlug: Scalars['ID']['input'];
};


export type CodeChangeSourceNextDeployArgs = {
  environmentSlug?: InputMaybe<Scalars['ID']['input']>;
};


export type CodeChangeSourceNoDeploysArgs = {
  environmentSlug: Scalars['ID']['input'];
};


export type CodeChangeSourceRuleExecutionsArgs = {
  page: Scalars['Int']['input'];
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export type CodeDeploymentLock = {
  __typename?: 'CodeDeploymentLock';
  automaticallyLocked: Scalars['Boolean']['output'];
  automaticallyUnlocked: Scalars['Boolean']['output'];
  codeDeployment: CodeChangeSource;
  id: Scalars['ID']['output'];
  lockReason?: Maybe<Scalars['String']['output']>;
  lockedBy?: Maybe<UserType>;
  lockedDeploy?: Maybe<ChangeModelType>;
  lockedDuration?: Maybe<Scalars['Int']['output']>;
  lockedOn: Scalars['DateTime']['output'];
  org: OrganizationType;
  unlockReason?: Maybe<Scalars['String']['output']>;
  unlockedBy?: Maybe<UserType>;
  unlockedDeploy?: Maybe<ChangeModelType>;
  unlockedOn?: Maybe<Scalars['DateTime']['output']>;
};

export enum CodeDeploymentLockStatus {
  Locked = 'Locked',
  ManuallyLocked = 'ManuallyLocked',
  Unlocked = 'Unlocked'
}

export enum CodeDeploymentLockingType {
  /** Automatically lock when the target branch has unreleased code */
  NextDeploy = 'NEXT_DEPLOY',
  /** No attempt to lock deployments */
  None = 'NONE'
}

export enum CodeDeploymentProviderType {
  /** Azure DevOps */
  Azure = 'AZURE',
  /** Bitbucket */
  Bitbucket = 'BITBUCKET',
  /** Custom - any git repository */
  CustomGit = 'CUSTOM_GIT',
  /** GitHub */
  Github = 'GITHUB',
  /** GitHub Enterprise Server */
  GithubEnterprise = 'GITHUB_ENTERPRISE',
  /** GitLab */
  Gitlab = 'GITLAB'
}

export type CommitModelType = {
  __typename?: 'CommitModelType';
  authorGravatar: Scalars['String']['output'];
  authorName: Scalars['String']['output'];
  deployOn: Scalars['DateTime']['output'];
  deploymentBranch: Scalars['String']['output'];
  linkifiedMessage: Scalars['String']['output'];
  message: Scalars['String']['output'];
  on: Scalars['DateTime']['output'];
  revision: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type CommitModelTypeEdge = {
  __typename?: 'CommitModelTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: CommitModelType;
};

export type CommitPaginatedType = {
  __typename?: 'CommitPaginatedType';
  count: Scalars['Int']['output'];
  hasNext: Scalars['Boolean']['output'];
  hasPrev: Scalars['Boolean']['output'];
  objects: Array<CommitModelType>;
  page: Scalars['Int']['output'];
  pages: Scalars['Int']['output'];
};

export type CommitType = {
  __typename?: 'CommitType';
  message?: Maybe<Scalars['String']['output']>;
  on: Scalars['DateTime']['output'];
  revision: Scalars['String']['output'];
};

export type CommitTypeEdge = {
  __typename?: 'CommitTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: CommitType;
};

/** Paginated connection for Commits. */
export type CommitsConnection = {
  __typename?: 'CommitsConnection';
  edges: Array<CommitTypeEdge>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of edges. */
  totalCount: Scalars['Int']['output'];
};

export type ConditionType = {
  __typename?: 'ConditionType';
  description: Scalars['String']['output'];
  expression: Scalars['String']['output'];
  lineNumber: Scalars['Int']['output'];
  on: Scalars['DateTime']['output'];
  order: Scalars['Int']['output'];
};

export type ConnectionTestResultType = {
  __typename?: 'ConnectionTestResultType';
  markedWorkingAgainMsg: Scalars['String']['output'];
  noData: Scalars['Boolean']['output'];
  remoteMsg?: Maybe<Scalars['String']['output']>;
  result?: Maybe<Scalars['String']['output']>;
  wasMarkedWorkingAgain: Scalars['Boolean']['output'];
};

export type CreateAwsIntegrationMutationInput = {
  apiToken: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  provider?: InputMaybe<Scalars['String']['input']>;
  regionName: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type CreateAwsIntegrationMutationPayload = {
  __typename?: 'CreateAWSIntegrationMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  integration?: Maybe<IntegrationAuthenticationType>;
};

export type CreateAccessTokenInputType = {
  label: Scalars['String']['input'];
  orgSlug: Scalars['ID']['input'];
  scope: OAuthScopeType;
};

export type CreateAccessTokenMutation = {
  __typename?: 'CreateAccessTokenMutation';
  errors?: Maybe<Array<Scalars['String']['output']>>;
  token?: Maybe<Scalars['String']['output']>;
};

export type CreateAppDynamicsIntegrationMutationInput = {
  apiClientName: Scalars['String']['input'];
  apiSecondaryToken?: InputMaybe<Scalars['String']['input']>;
  apiToken: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  provider?: InputMaybe<Scalars['String']['input']>;
  username: Scalars['String']['input'];
};

export type CreateAppDynamicsIntegrationMutationPayload = {
  __typename?: 'CreateAppDynamicsIntegrationMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  integration?: Maybe<IntegrationAuthenticationType>;
};

export type CreateAzureIntegrationMutationInput = {
  apiSecondaryToken?: InputMaybe<Scalars['String']['input']>;
  apiToken?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  /** Coma separated list of custom headers Sleuth will send with every request. Specify in the format: my-header=my-value,my-other-header=value2 */
  extraHeaders?: InputMaybe<Scalars['String']['input']>;
  provider: Scalars['String']['input'];
  /** Whether only read operations will be allowed or not. If enabled, some features may be unavailable. */
  readOnly?: InputMaybe<Scalars['Boolean']['input']>;
  /** The fully qualified url of your instance. */
  url?: InputMaybe<Scalars['String']['input']>;
  /** The username of the user used to create the API token */
  username?: InputMaybe<Scalars['String']['input']>;
};

export type CreateAzureIntegrationMutationPayload = {
  __typename?: 'CreateAzureIntegrationMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  integration?: Maybe<IntegrationAuthenticationType>;
};

export type CreateBitbucketIntegrationMutationInput = {
  apiSecondaryToken?: InputMaybe<Scalars['String']['input']>;
  apiToken?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  /** Coma separated list of custom headers Sleuth will send with every request. Specify in the format: my-header=my-value,my-other-header=value2 */
  extraHeaders?: InputMaybe<Scalars['String']['input']>;
  provider: Scalars['String']['input'];
  /** Whether only read operations will be allowed or not. If enabled, some features may be unavailable. */
  readOnly?: InputMaybe<Scalars['Boolean']['input']>;
  /** The fully qualified url of your instance. */
  url?: InputMaybe<Scalars['String']['input']>;
  /** The username of the user used to create the API token */
  username?: InputMaybe<Scalars['String']['input']>;
};

export type CreateBitbucketIntegrationMutationPayload = {
  __typename?: 'CreateBitbucketIntegrationMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  integration?: Maybe<IntegrationAuthenticationType>;
};

export type CreateBitbucketPipelinesIntegrationMutationInput = {
  apiSecondaryToken?: InputMaybe<Scalars['String']['input']>;
  apiToken?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  /** Coma separated list of custom headers Sleuth will send with every request. Specify in the format: my-header=my-value,my-other-header=value2 */
  extraHeaders?: InputMaybe<Scalars['String']['input']>;
  provider: Scalars['String']['input'];
  /** Whether only read operations will be allowed or not. If enabled, some features may be unavailable. */
  readOnly?: InputMaybe<Scalars['Boolean']['input']>;
  /** The fully qualified url of your instance. */
  url?: InputMaybe<Scalars['String']['input']>;
  /** The username of the user used to create the API token */
  username?: InputMaybe<Scalars['String']['input']>;
};

export type CreateBitbucketPipelinesIntegrationMutationPayload = {
  __typename?: 'CreateBitbucketPipelinesIntegrationMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  integration?: Maybe<IntegrationAuthenticationType>;
};

export type CreateBlamelessIntegrationMutationInput = {
  /** Reach out to Blameless Customer Success Manager (CSM) and they can provide you with your client secret */
  apiSecondaryToken: Scalars['String']['input'];
  /** Reach out to Blameless Customer Success Manager (CSM) and they can provide you with your client ID */
  apiToken: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  /** Coma separated list of custom headers Sleuth will send with every request. Specify in the format: my-header=my-value,my-other-header=value2 */
  extraHeaders?: InputMaybe<Scalars['String']['input']>;
  provider: Scalars['String']['input'];
  /** Whether only read operations will be allowed or not. If enabled, some features may be unavailable. */
  readOnly?: InputMaybe<Scalars['Boolean']['input']>;
  /** The fully qualified url of your instance (https://customer.blameless.io) */
  url: Scalars['String']['input'];
  /** The username of the user used to create the API token */
  username?: InputMaybe<Scalars['String']['input']>;
};

export type CreateBlamelessIntegrationMutationPayload = {
  __typename?: 'CreateBlamelessIntegrationMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  integration?: Maybe<IntegrationAuthenticationType>;
};

export type CreateBugsnagIntegrationMutationInput = {
  apiSecondaryToken?: InputMaybe<Scalars['String']['input']>;
  apiToken?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  /** Coma separated list of custom headers Sleuth will send with every request. Specify in the format: my-header=my-value,my-other-header=value2 */
  extraHeaders?: InputMaybe<Scalars['String']['input']>;
  provider: Scalars['String']['input'];
  /** Whether only read operations will be allowed or not. If enabled, some features may be unavailable. */
  readOnly?: InputMaybe<Scalars['Boolean']['input']>;
  /** The fully qualified url of your instance. */
  url?: InputMaybe<Scalars['String']['input']>;
  /** The username of the user used to create the API token */
  username?: InputMaybe<Scalars['String']['input']>;
};

export type CreateBugsnagIntegrationMutationPayload = {
  __typename?: 'CreateBugsnagIntegrationMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  integration?: Maybe<IntegrationAuthenticationType>;
};

export type CreateBuildImpactSourceMutationInput = {
  /** Your organization has multiple integrations connected. Choose which one this impact source will use. */
  auth?: InputMaybe<Scalars['String']['input']>;
  buildBranch?: InputMaybe<Scalars['String']['input']>;
  buildJobName?: InputMaybe<Scalars['String']['input']>;
  buildName: Scalars['String']['input'];
  buildProjectKey?: InputMaybe<Scalars['String']['input']>;
  environment: Scalars['String']['input'];
  /** Populate with data from the last 30 days. */
  historicInit?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  projectSlug: Scalars['ID']['input'];
  provider: BuildImpactProvider;
};

export type CreateBuildImpactSourceMutationPayload = {
  __typename?: 'CreateBuildImpactSourceMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  impactSource?: Maybe<BuildImpactSource>;
};

export type CreateBuildkiteIntegrationMutationInput = {
  apiSecondaryToken?: InputMaybe<Scalars['String']['input']>;
  apiToken?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  /** Coma separated list of custom headers Sleuth will send with every request. Specify in the format: my-header=my-value,my-other-header=value2 */
  extraHeaders?: InputMaybe<Scalars['String']['input']>;
  provider: Scalars['String']['input'];
  /** Whether only read operations will be allowed or not. If enabled, some features may be unavailable. */
  readOnly?: InputMaybe<Scalars['Boolean']['input']>;
  /** The fully qualified url of your instance. */
  url?: InputMaybe<Scalars['String']['input']>;
  /** The username of the user used to create the API token */
  username?: InputMaybe<Scalars['String']['input']>;
};

export type CreateBuildkiteIntegrationMutationPayload = {
  __typename?: 'CreateBuildkiteIntegrationMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  integration?: Maybe<IntegrationAuthenticationType>;
};

export type CreateCircleCiIntegrationMutationInput = {
  apiSecondaryToken?: InputMaybe<Scalars['String']['input']>;
  apiToken?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  /** Coma separated list of custom headers Sleuth will send with every request. Specify in the format: my-header=my-value,my-other-header=value2 */
  extraHeaders?: InputMaybe<Scalars['String']['input']>;
  provider: Scalars['String']['input'];
  /** Whether only read operations will be allowed or not. If enabled, some features may be unavailable. */
  readOnly?: InputMaybe<Scalars['Boolean']['input']>;
  /** The fully qualified url of your instance. */
  url?: InputMaybe<Scalars['String']['input']>;
  /** The username of the user used to create the API token */
  username?: InputMaybe<Scalars['String']['input']>;
};

export type CreateCircleCiIntegrationMutationPayload = {
  __typename?: 'CreateCircleCiIntegrationMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  integration?: Maybe<IntegrationAuthenticationType>;
};

export type CreateCloudWatchIntegrationMutationInput = {
  apiToken: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  provider?: InputMaybe<Scalars['String']['input']>;
  regionName: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type CreateCloudWatchIntegrationMutationPayload = {
  __typename?: 'CreateCloudWatchIntegrationMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  integration?: Maybe<IntegrationAuthenticationType>;
};

export type CreateClubhouseIntegrationMutationInput = {
  apiSecondaryToken?: InputMaybe<Scalars['String']['input']>;
  apiToken?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  /** Coma separated list of custom headers Sleuth will send with every request. Specify in the format: my-header=my-value,my-other-header=value2 */
  extraHeaders?: InputMaybe<Scalars['String']['input']>;
  provider: Scalars['String']['input'];
  /** Whether only read operations will be allowed or not. If enabled, some features may be unavailable. */
  readOnly?: InputMaybe<Scalars['Boolean']['input']>;
  /** The fully qualified url of your instance. */
  url?: InputMaybe<Scalars['String']['input']>;
  /** The username of the user used to create the API token */
  username?: InputMaybe<Scalars['String']['input']>;
};

export type CreateClubhouseIntegrationMutationPayload = {
  __typename?: 'CreateClubhouseIntegrationMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  integration?: Maybe<IntegrationAuthenticationType>;
};

export type CreateCodeChangeSourceMutationInput = {
  /**
   * Tell us the average time it takes for your code merge to deploy.
   *                     We will register the deploy after the specified delay.
   *             <sleuth-alert variant="warning">
   *                 Impact tracking and Slack notifications may be inaccurate when using this setting.
   *                 We recommend using precise deploy registration instead.
   *             </sleuth-alert>
   */
  autoTrackingDelay?: InputMaybe<Scalars['Int']['input']>;
  /** Don't see the desired build integration here? Make sure you configured it in settings. */
  buildIntegrationSlug?: InputMaybe<Scalars['String']['input']>;
  buildMappings?: InputMaybe<Array<EnvironmentBuildMappingType>>;
  buildProvider?: InputMaybe<TrackBuildProviderType>;
  /** If unchecked Impact won't be collected upon deploy and thus won't affect your project's health. */
  collectImpact?: InputMaybe<Scalars['Boolean']['input']>;
  deployTrackingType?: InputMaybe<DeployTrackingType>;
  environmentMappings?: InputMaybe<Array<EnvironmentBranchMappingType>>;
  /** Filter this change source from appearing in the project dashboard, stats, and graphs */
  includeInDashboard?: InputMaybe<Scalars['Boolean']['input']>;
  /** Using the chosen tracking type, try to determine what possible deploys happened in the last 30 days. Deploys found this way are marked as 'Approximate'. */
  initializeChanges?: InputMaybe<Scalars['Boolean']['input']>;
  initializeChangesOnEnvCreation?: InputMaybe<Scalars['Boolean']['input']>;
  lockingType?: InputMaybe<CodeDeploymentLockingType>;
  name: Scalars['String']['input'];
  /** Receive Slack notifications on deploy events. */
  notifyInSlack?: InputMaybe<Scalars['Boolean']['input']>;
  /** Restricts deployment information to only files matching this prefix, primarily useful for monorepos. Use comma-separated list of prefixes to cover multiple paths. */
  pathPrefix?: InputMaybe<Scalars['String']['input']>;
  projectSlug: Scalars['ID']['input'];
  repository: RepositoryInputType;
};

export type CreateCodeChangeSourceMutationPayload = {
  __typename?: 'CreateCodeChangeSourceMutationPayload';
  changeSource?: Maybe<CodeChangeSource>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
};

export type CreateDdIntegrationMutationInput = {
  /** Your <a href='https://app.datadoghq.com/organization-settings/application-keys' target='_blank'>generated</a> Application key (links to Datadog's default US site) */
  apiSecondaryToken: Scalars['String']['input'];
  /** Your <a href='https://app.datadoghq.com/organization-settings/api-keys' target='_blank'>generated</a> API key (links to Datadog's default US site) */
  apiToken: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  inEurope?: InputMaybe<Scalars['Boolean']['input']>;
  provider?: InputMaybe<Scalars['String']['input']>;
  /** URL of your Datadog instance, only required if you are using a custom subdomain. */
  url?: InputMaybe<Scalars['String']['input']>;
};

export type CreateDdIntegrationMutationPayload = {
  __typename?: 'CreateDDIntegrationMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  integration?: Maybe<IntegrationAuthenticationType>;
};

export type CreateEnvironmentMutationInput = {
  color?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  projectSlug: Scalars['ID']['input'];
};

export type CreateEnvironmentMutationPayload = {
  __typename?: 'CreateEnvironmentMutationPayload';
  environment?: Maybe<Environment>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
};

export type CreateErrorImpactSourceMutationInput = {
  /** Your organization has multiple integrations connected. Choose which one this impact source will use. */
  auth?: InputMaybe<Scalars['String']['input']>;
  /** All values below this value will be considered healthy. For values at or above this value we will perform a statistical analysis.  */
  earliestNonhealthyThreshold?: InputMaybe<Scalars['Float']['input']>;
  environment: Scalars['String']['input'];
  /** The environment name for the error impact source. */
  errorEnvironment: Scalars['String']['input'];
  /** The error impact source organization key. */
  errorOrgKey: Scalars['String']['input'];
  errorProjectId?: InputMaybe<Scalars['String']['input']>;
  /** The error impact source project key. */
  errorProjectKey: Scalars['String']['input'];
  /** Populate with data from the last 30 days. */
  historicInit?: InputMaybe<Scalars['Boolean']['input']>;
  /** All values below this value will be considered healthy. All values at or above it unhealthy. We won't perform any statistical analysis. */
  indisputableThreshold?: InputMaybe<Scalars['Float']['input']>;
  /** DEPRECATED, Use earliestNonhealthyThreshold instead. */
  manuallySetHealthThreshold?: InputMaybe<Scalars['Float']['input']>;
  name: Scalars['String']['input'];
  projectSlug: Scalars['ID']['input'];
  provider: ErrorImpactSourceProvider;
};

export type CreateErrorImpactSourceMutationPayload = {
  __typename?: 'CreateErrorImpactSourceMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  impactSource?: Maybe<ErrorImpactSource>;
};

export type CreateFeatureFlagChangeSourceMutationInput = {
  /** Includes this source’s impact within your project's health metrics. */
  collectImpact?: InputMaybe<Scalars['Boolean']['input']>;
  environmentMappings: Array<EnvironmentRemoteEnvironmentMappingType>;
  /** Filter this change source from appearing in the project dashboard, stats, and graphs */
  includeInDashboard?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  /** Receive Slack notifications on deploy events. */
  notifyInSlack?: InputMaybe<Scalars['Boolean']['input']>;
  projectSlug: Scalars['ID']['input'];
  provider: FlagProvider;
  remoteProjectKey: Scalars['String']['input'];
  usedByDeployments: Array<Scalars['String']['input']>;
};

export type CreateFeatureFlagChangeSourceMutationPayload = {
  __typename?: 'CreateFeatureFlagChangeSourceMutationPayload';
  changeSource?: Maybe<FeatureFlagChangeSource>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
};

export type CreateFireHydrantIntegrationMutationInput = {
  apiSecondaryToken?: InputMaybe<Scalars['String']['input']>;
  apiToken?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  /** Coma separated list of custom headers Sleuth will send with every request. Specify in the format: my-header=my-value,my-other-header=value2 */
  extraHeaders?: InputMaybe<Scalars['String']['input']>;
  provider: Scalars['String']['input'];
  /** Whether only read operations will be allowed or not. If enabled, some features may be unavailable. */
  readOnly?: InputMaybe<Scalars['Boolean']['input']>;
  /** The fully qualified url of your instance. */
  url?: InputMaybe<Scalars['String']['input']>;
  /** The username of the user used to create the API token */
  username?: InputMaybe<Scalars['String']['input']>;
};

export type CreateFireHydrantIntegrationMutationPayload = {
  __typename?: 'CreateFireHydrantIntegrationMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  integration?: Maybe<IntegrationAuthenticationType>;
};

export type CreateGithubEnterpriseIntegrationMutationInput = {
  apiSecondaryToken?: InputMaybe<Scalars['String']['input']>;
  apiToken?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  /** Coma separated list of custom headers Sleuth will send with every request. Specify in the format: my-header=my-value,my-other-header=value2 */
  extraHeaders?: InputMaybe<Scalars['String']['input']>;
  provider: Scalars['String']['input'];
  /** Whether only read operations will be allowed or not. If enabled, some features may be unavailable. */
  readOnly?: InputMaybe<Scalars['Boolean']['input']>;
  /** The fully qualified url of your instance. */
  url?: InputMaybe<Scalars['String']['input']>;
  /** The username of the user used to create the API token */
  username?: InputMaybe<Scalars['String']['input']>;
};

export type CreateGithubEnterpriseIntegrationMutationPayload = {
  __typename?: 'CreateGithubEnterpriseIntegrationMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  integration?: Maybe<IntegrationAuthenticationType>;
};

export type CreateGitlabIntegrationMutationInput = {
  apiSecondaryToken?: InputMaybe<Scalars['String']['input']>;
  apiToken?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  /** Coma separated list of custom headers Sleuth will send with every request. Specify in the format: my-header=my-value,my-other-header=value2 */
  extraHeaders?: InputMaybe<Scalars['String']['input']>;
  provider: Scalars['String']['input'];
  /** Whether only read operations will be allowed or not. If enabled, some features may be unavailable. */
  readOnly?: InputMaybe<Scalars['Boolean']['input']>;
  /** The fully qualified url of your instance. */
  url?: InputMaybe<Scalars['String']['input']>;
  /** The username of the user used to create the API token */
  username?: InputMaybe<Scalars['String']['input']>;
};

export type CreateGitlabIntegrationMutationPayload = {
  __typename?: 'CreateGitlabIntegrationMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  integration?: Maybe<IntegrationAuthenticationType>;
};

export type CreateHoneybadgerIntegrationMutationInput = {
  apiSecondaryToken?: InputMaybe<Scalars['String']['input']>;
  apiToken?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  /** Coma separated list of custom headers Sleuth will send with every request. Specify in the format: my-header=my-value,my-other-header=value2 */
  extraHeaders?: InputMaybe<Scalars['String']['input']>;
  provider: Scalars['String']['input'];
  /** Whether only read operations will be allowed or not. If enabled, some features may be unavailable. */
  readOnly?: InputMaybe<Scalars['Boolean']['input']>;
  /** The fully qualified url of your instance. */
  url?: InputMaybe<Scalars['String']['input']>;
  /** The username of the user used to create the API token */
  username?: InputMaybe<Scalars['String']['input']>;
};

export type CreateHoneybadgerIntegrationMutationPayload = {
  __typename?: 'CreateHoneybadgerIntegrationMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  integration?: Maybe<IntegrationAuthenticationType>;
};

export type CreateIntegrationMutationInput = {
  apiSecondaryToken?: InputMaybe<Scalars['String']['input']>;
  apiToken?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  /** Coma separated list of custom headers Sleuth will send with every request. Specify in the format: my-header=my-value,my-other-header=value2 */
  extraHeaders?: InputMaybe<Scalars['String']['input']>;
  provider: Scalars['String']['input'];
  /** Whether only read operations will be allowed or not. If enabled, some features may be unavailable. */
  readOnly?: InputMaybe<Scalars['Boolean']['input']>;
  /** The fully qualified url of your instance. */
  url?: InputMaybe<Scalars['String']['input']>;
  /** The username of the user used to create the API token */
  username?: InputMaybe<Scalars['String']['input']>;
};

export type CreateIntegrationMutationPayload = {
  __typename?: 'CreateIntegrationMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  integration?: Maybe<IntegrationAuthenticationType>;
};

export type CreateJenkinsIntegrationMutationInput = {
  apiSecondaryToken?: InputMaybe<Scalars['String']['input']>;
  apiToken?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  /** Coma separated list of custom headers Sleuth will send with every request. Specify in the format: my-header=my-value,my-other-header=value2 */
  extraHeaders?: InputMaybe<Scalars['String']['input']>;
  provider: Scalars['String']['input'];
  /** Whether only read operations will be allowed or not. If enabled, some features may be unavailable. */
  readOnly?: InputMaybe<Scalars['Boolean']['input']>;
  /** The fully qualified url of your instance. */
  url?: InputMaybe<Scalars['String']['input']>;
  /** The username of the user used to create the API token */
  username?: InputMaybe<Scalars['String']['input']>;
};

export type CreateJenkinsIntegrationMutationPayload = {
  __typename?: 'CreateJenkinsIntegrationMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  integration?: Maybe<IntegrationAuthenticationType>;
};

export type CreateJiraCloudIntegrationMutationInput = {
  apiSecondaryToken?: InputMaybe<Scalars['String']['input']>;
  apiToken?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  /** Coma separated list of custom headers Sleuth will send with every request. Specify in the format: my-header=my-value,my-other-header=value2 */
  extraHeaders?: InputMaybe<Scalars['String']['input']>;
  provider: Scalars['String']['input'];
  /** Whether only read operations will be allowed or not. If enabled, some features may be unavailable. */
  readOnly?: InputMaybe<Scalars['Boolean']['input']>;
  /** The fully qualified url of your instance. */
  url?: InputMaybe<Scalars['String']['input']>;
  /** The username of the user used to create the API token */
  username?: InputMaybe<Scalars['String']['input']>;
};

export type CreateJiraCloudIntegrationMutationPayload = {
  __typename?: 'CreateJiraCloudIntegrationMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  integration?: Maybe<IntegrationAuthenticationType>;
};

export type CreateJiraDatacenterIntegrationMutationInput = {
  apiSecondaryToken?: InputMaybe<Scalars['String']['input']>;
  apiToken?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  /** Coma separated list of custom headers Sleuth will send with every request. Specify in the format: my-header=my-value,my-other-header=value2 */
  extraHeaders?: InputMaybe<Scalars['String']['input']>;
  provider: Scalars['String']['input'];
  /** Whether only read operations will be allowed or not. If enabled, some features may be unavailable. */
  readOnly?: InputMaybe<Scalars['Boolean']['input']>;
  /** The fully qualified url of your instance. */
  url?: InputMaybe<Scalars['String']['input']>;
  /** The username of the user used to create the API token */
  username?: InputMaybe<Scalars['String']['input']>;
};

export type CreateJiraDatacenterIntegrationMutationPayload = {
  __typename?: 'CreateJiraDatacenterIntegrationMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  integration?: Maybe<IntegrationAuthenticationType>;
};

export type CreateLabelMutationInput = {
  name: Scalars['String']['input'];
  projects?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type CreateLabelMutationPayload = {
  __typename?: 'CreateLabelMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  label?: Maybe<LabelType>;
};

export type CreateLinearIntegrationMutationInput = {
  apiSecondaryToken?: InputMaybe<Scalars['String']['input']>;
  apiToken?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  /** Coma separated list of custom headers Sleuth will send with every request. Specify in the format: my-header=my-value,my-other-header=value2 */
  extraHeaders?: InputMaybe<Scalars['String']['input']>;
  provider: Scalars['String']['input'];
  /** Whether only read operations will be allowed or not. If enabled, some features may be unavailable. */
  readOnly?: InputMaybe<Scalars['Boolean']['input']>;
  /** The fully qualified url of your instance. */
  url?: InputMaybe<Scalars['String']['input']>;
  /** The username of the user used to create the API token */
  username?: InputMaybe<Scalars['String']['input']>;
};

export type CreateLinearIntegrationMutationPayload = {
  __typename?: 'CreateLinearIntegrationMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  integration?: Maybe<IntegrationAuthenticationType>;
};

export type CreateMetricImpactSourceMutationInput = {
  /** Your organization has multiple integrations connected. Choose which one this impact source will use. */
  auth?: InputMaybe<Scalars['String']['input']>;
  /** All values below this value will be considered healthy. For values at or above this value we will perform a statistical analysis. If higher values indicate improvement for this impact source, then the comparison is turned around and all values above this value will be considered healthy, while for values at or below this value we will perform the statistical analysis. */
  earliestNonhealthyThreshold?: InputMaybe<Scalars['Float']['input']>;
  environment: Scalars['String']['input'];
  /** Populate with data from the last 30 days */
  historicInit?: InputMaybe<Scalars['Boolean']['input']>;
  /** All values below this value will be considered healthy. All values at or above it unhealthy. We won't perform any statistical analysis. If higher values indicate improvement for this impact source, then the comparison is turned around and all values above this value will be considered healthy, while all values at or below this value will be considered unhealthy. */
  indisputableThreshold?: InputMaybe<Scalars['Float']['input']>;
  /** By default we treat a lower value as indicating an improvement of service. Changing this value changes the metrics vector such that lower values will indicate the service is degraded. */
  lessIsBetter?: InputMaybe<Scalars['Boolean']['input']>;
  /** DEPRECATED, Use earliestNonhealthyThreshold instead. */
  manuallySetHealthThreshold?: InputMaybe<Scalars['Float']['input']>;
  name: Scalars['String']['input'];
  projectSlug: Scalars['ID']['input'];
  provider: MetricImpactProvider;
  query: Scalars['String']['input'];
};

export type CreateMetricImpactSourceMutationPayload = {
  __typename?: 'CreateMetricImpactSourceMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  impactSource?: Maybe<MetricImpactSource>;
};

export type CreateNrIntegrationMutationInput = {
  apiToken: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  /** New Relic provides different API endpoints for their EU and US servers. */
  inEurope?: InputMaybe<Scalars['Boolean']['input']>;
  provider?: InputMaybe<Scalars['String']['input']>;
  /** You can find your account id on the same page as your user key. The account id consists of only numbers. <a href='https://docs.newrelic.com/docs/accounts/accounts-billing/account-setup/account-id' target='_blank'>New Relic docs about Account ID.</a> */
  username: Scalars['String']['input'];
};

export type CreateNrIntegrationMutationPayload = {
  __typename?: 'CreateNRIntegrationMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  integration?: Maybe<IntegrationAuthenticationType>;
};

export type CreateOpsgenieIntegrationMutationInput = {
  /** Required scopes: <code>Read</code> and <code>Configuration Access</code> */
  apiToken: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  /** Opsgenie provides different API endpoints for their EU and US servers. */
  inEurope?: InputMaybe<Scalars['Boolean']['input']>;
  provider?: InputMaybe<Scalars['String']['input']>;
};

export type CreateOpsgenieIntegrationMutationPayload = {
  __typename?: 'CreateOpsgenieIntegrationMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  integration?: Maybe<IntegrationAuthenticationType>;
};

export type CreatePagerDutyImpactSourceMutationInput = {
  environmentName: Scalars['String']['input'];
  /** Populate with data from the last 30 days */
  historicInit?: InputMaybe<Scalars['Boolean']['input']>;
  integrationSlug?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  projectSlug: Scalars['ID']['input'];
  /** PagerDuty service ID, empty means all */
  remoteServices?: InputMaybe<Scalars['String']['input']>;
  /** PagerDuty incident urgency */
  remoteUrgency?: InputMaybe<PagerDutyUrgencyOptions>;
};

export type CreatePagerDutyImpactSourceMutationPayload = {
  __typename?: 'CreatePagerDutyImpactSourceMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  impactSource?: Maybe<PagerDutyIncidentImpactSource>;
};

export type CreatePersonalTokenMutation = {
  __typename?: 'CreatePersonalTokenMutation';
  errors?: Maybe<Array<Scalars['String']['output']>>;
  token: Scalars['String']['output'];
};

export type CreateProjectMutationInput = {
  /** Don't see the desired build integration here? Make sure you configured it in settings. */
  buildIntegrationSlug?: InputMaybe<Scalars['String']['input']>;
  buildProvider?: InputMaybe<TrackBuildProviderType>;
  changeFailureRateBoundary?: InputMaybe<DeployHealthType>;
  cltStartDefinition?: InputMaybe<CltStartDefinitionType>;
  cltStartStates?: InputMaybe<Array<Scalars['ID']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  detectRollbacks?: InputMaybe<Scalars['Boolean']['input']>;
  /** The amount of time (in minutes) a deploy must spend in a failure status (Unhealthy, Incident, etc.) before it is determined a failure. Setting this value to a longer time means that less deploys will be classified. */
  failureSensitivity?: InputMaybe<Scalars['Int']['input']>;
  impactSensitivity?: InputMaybe<ImpactSensitivity>;
  issueIntegrationSlug?: InputMaybe<Scalars['String']['input']>;
  issueTrackerProvider?: InputMaybe<IssueTrackerChoices>;
  lockable?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  strictIssueMatching?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateProjectMutationPayload = {
  __typename?: 'CreateProjectMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  project?: Maybe<ProjectType>;
};

export type CreateRollbarIntegrationMutationInput = {
  apiSecondaryToken?: InputMaybe<Scalars['String']['input']>;
  apiToken?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  /** Coma separated list of custom headers Sleuth will send with every request. Specify in the format: my-header=my-value,my-other-header=value2 */
  extraHeaders?: InputMaybe<Scalars['String']['input']>;
  provider: Scalars['String']['input'];
  /** Whether only read operations will be allowed or not. If enabled, some features may be unavailable. */
  readOnly?: InputMaybe<Scalars['Boolean']['input']>;
  /** The fully qualified url of your instance. */
  url?: InputMaybe<Scalars['String']['input']>;
  /** The username of the user used to create the API token */
  username?: InputMaybe<Scalars['String']['input']>;
};

export type CreateRollbarIntegrationMutationPayload = {
  __typename?: 'CreateRollbarIntegrationMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  integration?: Maybe<IntegrationAuthenticationType>;
};

export type CreateRootlyIntegrationMutationInput = {
  apiSecondaryToken?: InputMaybe<Scalars['String']['input']>;
  apiToken?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  /** Coma separated list of custom headers Sleuth will send with every request. Specify in the format: my-header=my-value,my-other-header=value2 */
  extraHeaders?: InputMaybe<Scalars['String']['input']>;
  provider: Scalars['String']['input'];
  /** Whether only read operations will be allowed or not. If enabled, some features may be unavailable. */
  readOnly?: InputMaybe<Scalars['Boolean']['input']>;
  /** The fully qualified url of your instance. */
  url?: InputMaybe<Scalars['String']['input']>;
  /** The username of the user used to create the API token */
  username?: InputMaybe<Scalars['String']['input']>;
};

export type CreateRootlyIntegrationMutationPayload = {
  __typename?: 'CreateRootlyIntegrationMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  integration?: Maybe<IntegrationAuthenticationType>;
};

export type CreateSentryIntegrationMutationInput = {
  apiSecondaryToken?: InputMaybe<Scalars['String']['input']>;
  /** Ensure the token has the following scopes: <br /><code>event:read, member:read, org:read, project:read, project:releases, team:read</code> */
  apiToken?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  /** Coma separated list of custom headers Sleuth will send with every request. Specify in the format: my-header=my-value,my-other-header=value2 */
  extraHeaders?: InputMaybe<Scalars['String']['input']>;
  provider: Scalars['String']['input'];
  /** Sleuth code deploys will be published to Sentry as releases for any referenced Sentry projects */
  publishReleases?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether only read operations will be allowed or not. If enabled, some features may be unavailable. */
  readOnly?: InputMaybe<Scalars['Boolean']['input']>;
  /** The fully qualified url of your instance. */
  url?: InputMaybe<Scalars['String']['input']>;
  /** The username of the user used to create the API token */
  username?: InputMaybe<Scalars['String']['input']>;
};

export type CreateSentryIntegrationMutationPayload = {
  __typename?: 'CreateSentryIntegrationMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  integration?: Maybe<IntegrationAuthenticationType>;
};

export type CreateSentrySelfHostedIntegrationMutationInput = {
  apiSecondaryToken?: InputMaybe<Scalars['String']['input']>;
  /** Ensure the token has the following scopes: <br /><code>event:read, member:read, org:read, project:read, project:releases, team:read</code> */
  apiToken?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  /** Coma separated list of custom headers Sleuth will send with every request. Specify in the format: my-header=my-value,my-other-header=value2 */
  extraHeaders?: InputMaybe<Scalars['String']['input']>;
  provider: Scalars['String']['input'];
  /** Sleuth code deploys will be published to Sentry as releases for any referenced Sentry projects */
  publishReleases?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether only read operations will be allowed or not. If enabled, some features may be unavailable. */
  readOnly?: InputMaybe<Scalars['Boolean']['input']>;
  /** The fully qualified url of your instance. */
  url?: InputMaybe<Scalars['String']['input']>;
  /** The username of the user used to create the API token */
  username?: InputMaybe<Scalars['String']['input']>;
};

export type CreateSentrySelfHostedIntegrationMutationPayload = {
  __typename?: 'CreateSentrySelfHostedIntegrationMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  integration?: Maybe<IntegrationAuthenticationType>;
};

export type CreateServiceNowIntegrationMutationInput = {
  apiToken: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  provider?: InputMaybe<Scalars['String']['input']>;
  /** Blah */
  url: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type CreateServiceNowIntegrationMutationPayload = {
  __typename?: 'CreateServiceNowIntegrationMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  integration?: Maybe<IntegrationAuthenticationType>;
};

export type CreateSignalFxIntegrationMutationInput = {
  apiToken: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  provider?: InputMaybe<Scalars['String']['input']>;
  realm?: InputMaybe<Scalars['String']['input']>;
};

export type CreateSignalFxIntegrationMutationPayload = {
  __typename?: 'CreateSignalFxIntegrationMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  integration?: Maybe<IntegrationAuthenticationType>;
};

export type CreateStatuspageIntegrationMutationInput = {
  apiSecondaryToken?: InputMaybe<Scalars['String']['input']>;
  apiToken?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  /** Coma separated list of custom headers Sleuth will send with every request. Specify in the format: my-header=my-value,my-other-header=value2 */
  extraHeaders?: InputMaybe<Scalars['String']['input']>;
  provider: Scalars['String']['input'];
  /** Whether only read operations will be allowed or not. If enabled, some features may be unavailable. */
  readOnly?: InputMaybe<Scalars['Boolean']['input']>;
  /** The fully qualified url of your instance. */
  url?: InputMaybe<Scalars['String']['input']>;
  /** The username of the user used to create the API token */
  username?: InputMaybe<Scalars['String']['input']>;
};

export type CreateStatuspageIntegrationMutationPayload = {
  __typename?: 'CreateStatuspageIntegrationMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  integration?: Maybe<IntegrationAuthenticationType>;
};

export type CreateTeamMutationInput = {
  name: Scalars['String']['input'];
  parent?: InputMaybe<Scalars['String']['input']>;
};

export type CreateTeamMutationPayload = {
  __typename?: 'CreateTeamMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  team?: Maybe<TeamType>;
};

export enum CycleBucketType {
  /** coding */
  Coding = 'CODING',
  /** deploying */
  Deploying = 'DEPLOYING',
  /** review */
  Review = 'REVIEW',
  /** review lag */
  ReviewLag = 'REVIEW_LAG'
}

export type DoraQuarterType = {
  __typename?: 'DORAQuarterType';
  avgLeadTimeInSec: Scalars['Int']['output'];
  avgMttrDurationInSec: Scalars['Int']['output'];
  endNotIncl: Scalars['DateTime']['output'];
  failureRatePercent: Scalars['Float']['output'];
  numOfDeploysPerDay: Scalars['Float']['output'];
  startIncl: Scalars['DateTime']['output'];
};

export type DataDogInputType = {
  integrationSlug?: InputMaybe<Scalars['String']['input']>;
  /** The query to scope the monitors to track, for example, `environment:production service:myservice`If you are using a custom facet you would need to add @ to the beginning of the facet name, `@myfacet:myvalue`. If empty, all monitors in Datadog will be matched regardless of environment or service. See the [documentation](https://docs.datadoghq.com/monitors/manage/search/) for more information. */
  query?: InputMaybe<Scalars['String']['input']>;
  /** Monitor states with matching or higher priorities will be considered a failure in Sleuth */
  remotePriorityThreshold?: InputMaybe<PriorityThresholdChoices>;
};

export type DataDogProviderData = {
  __typename?: 'DataDogProviderData';
  query?: Maybe<Scalars['String']['output']>;
  remotePriorityThreshold?: Maybe<PriorityThresholdChoices>;
};

export type DeleteAccessTokenInputType = {
  orgSlug: Scalars['ID']['input'];
  tokenId: Scalars['ID']['input'];
};

export type DeleteAccessTokenMutation = {
  __typename?: 'DeleteAccessTokenMutation';
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
};

export type DeleteBulkUserWorkingHoursMutation = {
  __typename?: 'DeleteBulkUserWorkingHoursMutation';
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
};

export type DeleteChangeSourceMutationInput = {
  projectSlug: Scalars['ID']['input'];
  slug: Scalars['ID']['input'];
};

export type DeleteChangeSourceMutationPayload = {
  __typename?: 'DeleteChangeSourceMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  success: Scalars['Boolean']['output'];
};

export type DeleteCurrentUserWorkingHoursMutation = {
  __typename?: 'DeleteCurrentUserWorkingHoursMutation';
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
};

export type DeleteEnvironmentMutationInput = {
  projectSlug: Scalars['ID']['input'];
  slug: Scalars['ID']['input'];
};

export type DeleteEnvironmentMutationPayload = {
  __typename?: 'DeleteEnvironmentMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  success: Scalars['Boolean']['output'];
};

export type DeleteImpactSourceMutationInput = {
  projectSlug: Scalars['ID']['input'];
  /** DEPRECATED! This field will always be considered TRUE. */
  recalculateHistory?: InputMaybe<Scalars['Boolean']['input']>;
  slug: Scalars['ID']['input'];
};

export type DeleteImpactSourceMutationPayload = {
  __typename?: 'DeleteImpactSourceMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  success: Scalars['Boolean']['output'];
};

export type DeleteIntegrationAuthenticationMutationInput = {
  provider: Scalars['String']['input'];
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type DeleteIntegrationAuthenticationMutationPayload = {
  __typename?: 'DeleteIntegrationAuthenticationMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  success: Scalars['Boolean']['output'];
};

export type DeleteLabelMutationInput = {
  slug: Scalars['ID']['input'];
};

export type DeleteLabelMutationPayload = {
  __typename?: 'DeleteLabelMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  success: Scalars['Boolean']['output'];
};

export type DeleteMemberMutationInput = {
  userId: Scalars['ID']['input'];
};

export type DeleteMemberMutationPayload = {
  __typename?: 'DeleteMemberMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  success: Scalars['Boolean']['output'];
};

export type DeleteOrganizationWorkingHoursMutation = {
  __typename?: 'DeleteOrganizationWorkingHoursMutation';
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
};

export type DeletePersonalTokenMutation = {
  __typename?: 'DeletePersonalTokenMutation';
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
};

export type DeleteProjectMutationInput = {
  slug: Scalars['ID']['input'];
};

export type DeleteProjectMutationPayload = {
  __typename?: 'DeleteProjectMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  success: Scalars['Boolean']['output'];
};

export type DeleteTeamMutationInput = {
  slug: Scalars['ID']['input'];
};

export type DeleteTeamMutationPayload = {
  __typename?: 'DeleteTeamMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  success: Scalars['Boolean']['output'];
};

export type DeployEnvironmentType = {
  __typename?: 'DeployEnvironmentType';
  deploySlug: Scalars['String']['output'];
  deployedAt: Scalars['String']['output'];
  environmentSlug: Scalars['String']['output'];
};

export enum DeployHealthType {
  /** Ailing */
  Ailing = 'AILING',
  /** Healthy */
  Healthy = 'HEALTHY',
  /** Improved */
  Improved = 'IMPROVED',
  /** Incident */
  Incident = 'INCIDENT',
  /** Rolled Back */
  RolledBack = 'ROLLED_BACK',
  /** Unhealthy */
  Unhealthy = 'UNHEALTHY'
}

/** An enumeration. */
export enum DeployLinkedPullRequestHistoryEventChoices {
  /** closed */
  Closed = 'CLOSED',
  /** edited */
  Edited = 'EDITED',
  /** merged */
  Merged = 'MERGED',
  /** opened */
  Opened = 'OPENED',
  /** ready_for_review */
  ReadyForReview = 'READY_FOR_REVIEW',
  /** reopened */
  Reopened = 'REOPENED',
  /** review_requested */
  ReviewRequested = 'REVIEW_REQUESTED',
  /** review_submitted */
  ReviewSubmitted = 'REVIEW_SUBMITTED',
  /** synchronize */
  Synchronize = 'SYNCHRONIZE',
  /** unknown */
  Unknown = 'UNKNOWN'
}

export type DeploySizesType = {
  __typename?: 'DeploySizesType';
  gigantic: Scalars['Int']['output'];
  large: Scalars['Int']['output'];
  medium: Scalars['Int']['output'];
  small: Scalars['Int']['output'];
};

export type DeployTrackingBuildMappingType = {
  __typename?: 'DeployTrackingBuildMappingType';
  buildBranch: Scalars['String']['output'];
  buildName: Scalars['String']['output'];
  buildProjectKey: Scalars['String']['output'];
  environment: Environment;
  integrationSlug?: Maybe<Scalars['String']['output']>;
  isCustom: Scalars['Boolean']['output'];
  jobName?: Maybe<Scalars['String']['output']>;
  /** If true, the build branch will have to match the branch name defined for the Environment */
  matchBranchToEnvironment: Scalars['Boolean']['output'];
  provider: Scalars['String']['output'];
};

export enum DeployTrackingChoiceType {
  /** Approximate - created by merged PR */
  AutoPr = 'auto_pr',
  /** Approximate - created from push to branch */
  AutoPush = 'auto_push',
  /** Approximate - created from tag */
  AutoTag = 'auto_tag',
  /** Precise - created from a detected completed build */
  Build = 'build',
  /** Created in deployment initialization - not precise. */
  Init = 'init',
  /** Precise - you told us when and what was deployed */
  Manual = 'manual'
}

export enum DeployTrackingType {
  /** Approximate (automatically create deploys for every PR merged) */
  AutoPr = 'AUTO_PR',
  /** Approximate (automatically create deploys for every push to branch) */
  AutoPush = 'AUTO_PUSH',
  /** Approximate (automatically create deploys for every tag) */
  AutoTag = 'AUTO_TAG',
  /** Precise (automatically detect deploys from completed builds) */
  Build = 'BUILD',
  /** Precise (send Sleuth exact deploy events via webhook) */
  Manual = 'MANUAL'
}

export type DeploymentFieldType = CodeChangeSource | FeatureFlagChangeSource | ManualChangeSource;

export type DisplayAuthorConnection = {
  __typename?: 'DisplayAuthorConnection';
  edges: Array<DisplayAuthorTypeEdge>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of edges. */
  totalCount: Scalars['Int']['output'];
};

export type DisplayAuthorType = {
  __typename?: 'DisplayAuthorType';
  email: Scalars['String']['output'];
  gravatar: Scalars['String']['output'];
  links: Array<DisplayLinkType>;
  name: Scalars['String']['output'];
};

export type DisplayAuthorTypeEdge = {
  __typename?: 'DisplayAuthorTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: DisplayAuthorType;
};

export type DisplayLinkType = {
  __typename?: 'DisplayLinkType';
  isSystemUser: Scalars['Boolean']['output'];
  provider: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type DriftChangesConnection = {
  __typename?: 'DriftChangesConnection';
  edges: Array<ChangeModelTypeEdge>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of edges. */
  totalCount: Scalars['Int']['output'];
};

export type DriftCommitsConnection = {
  __typename?: 'DriftCommitsConnection';
  edges: Array<CommitModelTypeEdge>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of edges. */
  totalCount: Scalars['Int']['output'];
};

export type DriftIssuesConnection = {
  __typename?: 'DriftIssuesConnection';
  edges: Array<IssueModelTypeEdge>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of edges. */
  totalCount: Scalars['Int']['output'];
};

export type DriftPullRequestsConnection = {
  __typename?: 'DriftPullRequestsConnection';
  edges: Array<LinkedPullRequestTypeEdge>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of edges. */
  totalCount: Scalars['Int']['output'];
};

export type DriftType = {
  __typename?: 'DriftType';
  ahead: Scalars['Boolean']['output'];
  baseRevision: Scalars['String']['output'];
  changes: DriftChangesConnection;
  commits: DriftCommitsConnection;
  compareRevision: Scalars['String']['output'];
  deployDrift: Scalars['Int']['output'];
  issues: DriftIssuesConnection;
  pullRequests: DriftPullRequestsConnection;
  revisionInCommon: Scalars['Boolean']['output'];
  secondsApart: Scalars['Float']['output'];
};


export type DriftTypeChangesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type DriftTypeCommitsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type DriftTypeIssuesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type DriftTypePullRequestsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type DurationsType = {
  __typename?: 'DurationsType';
  /** Time spent between first commit or first issue transition and a pull request being opened */
  codingTime?: Maybe<Scalars['Int']['output']>;
  /** Time spent between PR merge and deploy */
  deployingTime?: Maybe<Scalars['Int']['output']>;
  /** Time spent between a pull request being opened and the first review */
  reviewLagTime?: Maybe<Scalars['Int']['output']>;
  /** Time spent between first review and merge */
  reviewTime?: Maybe<Scalars['Int']['output']>;
};

export type EmptyImpactSynopsisType = {
  __typename?: 'EmptyImpactSynopsisType';
  sourceName: Scalars['String']['output'];
  sourceProvider: ImpactProvider;
  sourceSlug?: Maybe<Scalars['String']['output']>;
  sourceType: ImpactType;
};

export type EnvHealthEventConnection = {
  __typename?: 'EnvHealthEventConnection';
  edges: Array<EnvHealthEventTypeEdge>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of edges. */
  totalCount: Scalars['Int']['output'];
};

export type EnvHealthEventType = Node & {
  __typename?: 'EnvHealthEventType';
  health: DeployHealthType;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  on: Scalars['DateTime']['output'];
  originImpactSpans?: Maybe<Array<OriginalHealthSpanType>>;
};

export type EnvHealthEventTypeEdge = {
  __typename?: 'EnvHealthEventTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: EnvHealthEventType;
};

export type Environment = {
  __typename?: 'Environment';
  color: Scalars['String']['output'];
  currentHealth: DeployHealthType;
  description: Scalars['String']['output'];
  healthEvents: EnvHealthEventConnection;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  originalHealthSpans: OriginalHealthSpanConnection;
  slug: Scalars['String']['output'];
};


export type EnvironmentHealthEventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  end: Scalars['DateTime']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  start: Scalars['DateTime']['input'];
};


export type EnvironmentOriginalHealthSpansArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  end: Scalars['DateTime']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  start: Scalars['DateTime']['input'];
};

export type EnvironmentBranchMappingType = {
  branch: Scalars['String']['input'];
  environmentSlug: Scalars['String']['input'];
};

export type EnvironmentBuildMappingType = {
  buildBranch: Scalars['String']['input'];
  buildName: Scalars['String']['input'];
  buildProjectKey: Scalars['String']['input'];
  environmentSlug: Scalars['ID']['input'];
  integrationSlug: Scalars['ID']['input'];
  isCustom?: InputMaybe<Scalars['Boolean']['input']>;
  jobName?: InputMaybe<Scalars['String']['input']>;
  matchBranchToEnvironment?: InputMaybe<Scalars['Boolean']['input']>;
  provider: BuildProviderType;
};

export type EnvironmentConnection = {
  __typename?: 'EnvironmentConnection';
  edges: Array<EnvironmentEdge>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of edges. */
  totalCount: Scalars['Int']['output'];
};

export type EnvironmentEdge = {
  __typename?: 'EnvironmentEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Environment;
};

export type EnvironmentMapping = {
  sourceEnvSlug: Scalars['String']['input'];
  targetEnvSlug?: InputMaybe<Scalars['String']['input']>;
};

export type EnvironmentMappingType = {
  __typename?: 'EnvironmentMappingType';
  branch: Scalars['String']['output'];
  environmentName: Scalars['String']['output'];
  environmentSlug: Scalars['String']['output'];
};

export type EnvironmentMutation = {
  __typename?: 'EnvironmentMutation';
  success: Scalars['Boolean']['output'];
};

export type EnvironmentNames = {
  __typename?: 'EnvironmentNames';
  name: Scalars['String']['output'];
};

export type EnvironmentNamesEdge = {
  __typename?: 'EnvironmentNamesEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: EnvironmentNames;
};

export type EnvironmentRemoteEnvironmentMappingType = {
  environmentSlug: Scalars['String']['input'];
  remoteEnvironmentKey: Scalars['String']['input'];
};

export type EnvironmentsNamesConnection = {
  __typename?: 'EnvironmentsNamesConnection';
  edges: Array<EnvironmentNamesEdge>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of edges. */
  totalCount: Scalars['Int']['output'];
};

export type ErrorImpactSource = ImpactSourceInterfaceType & {
  __typename?: 'ErrorImpactSource';
  baselineFrom: Scalars['DateTime']['output'];
  earliestNonhealthyThreshold?: Maybe<Scalars['Float']['output']>;
  editLink: Scalars['String']['output'];
  environment: Environment;
  errorEnvironment: Scalars['String']['output'];
  errorOrgKey?: Maybe<Scalars['String']['output']>;
  errorProjectId?: Maybe<Scalars['String']['output']>;
  errorProjectKey: Scalars['String']['output'];
  healthAnalysis?: Maybe<ImpactSourceHealthResultType>;
  indisputableThreshold?: Maybe<Scalars['Float']['output']>;
  integrationAuthSlug?: Maybe<Scalars['String']['output']>;
  /** DEPRECATED Use earliestNonhealthyThreshold instead. */
  manuallySetHealthThreshold?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  ostracizedOn?: Maybe<Scalars['DateTime']['output']>;
  provider: Scalars['String']['output'];
  reBaselineLink: Scalars['String']['output'];
  registerImpactLink: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  type: ImpactType;
};


export type ErrorImpactSourceHealthAnalysisArgs = {
  endDate?: InputMaybe<Scalars['String']['input']>;
};

export enum ErrorImpactSourceProvider {
  /** Bugsnag */
  Bugsnag = 'BUGSNAG',
  /** Honeybadger */
  Honeybadger = 'HONEYBADGER',
  /** Rollbar */
  Rollbar = 'ROLLBAR',
  /** Sentry */
  Sentry = 'SENTRY',
  /** Sentry Self Hosted */
  SentrySelfHosted = 'SENTRY_SELF_HOSTED'
}

export type ErrorType = {
  __typename?: 'ErrorType';
  field: Scalars['String']['output'];
  messages: Array<Scalars['String']['output']>;
};

export type ExternalIssueProject = {
  __typename?: 'ExternalIssueProject';
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
};

export type ExternalIssueProjectEdge = {
  __typename?: 'ExternalIssueProjectEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: ExternalIssueProject;
};

export type ExternalIssueProjectsConnection = {
  __typename?: 'ExternalIssueProjectsConnection';
  edges: Array<ExternalIssueProjectEdge>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of edges. */
  totalCount: Scalars['Int']['output'];
};

export type ExternalUserType = {
  __typename?: 'ExternalUserType';
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  provider: Scalars['String']['output'];
  user?: Maybe<UserType>;
  username: Scalars['String']['output'];
};

export type ExternalUserTypeEdge = {
  __typename?: 'ExternalUserTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: ExternalUserType;
};

export type ExternalUsersConnection = {
  __typename?: 'ExternalUsersConnection';
  edges: Array<ExternalUserTypeEdge>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of edges. */
  totalCount: Scalars['Int']['output'];
};

export enum Feature {
  OrganizationMetrics = 'ORGANIZATION_METRICS',
  Saml = 'SAML',
  TeamsSync = 'TEAMS_SYNC'
}

export type FeatureFlagChangeSource = ChangeSource & {
  __typename?: 'FeatureFlagChangeSource';
  changes: ChangePaginatedType;
  collectImpact: Scalars['Boolean']['output'];
  description: Scalars['String']['output'];
  environmentMappings: Array<EnvironmentMappingType>;
  hasActiveBackfill: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  includeInDashboard: Scalars['Boolean']['output'];
  isBroken: Scalars['Boolean']['output'];
  isCicdBroken: Scalars['Boolean']['output'];
  latestChange?: Maybe<ChangeType>;
  lock: CodeDeploymentLockStatus;
  name: Scalars['String']['output'];
  nextDeploy?: Maybe<ChangeType>;
  noDeploys: Scalars['Boolean']['output'];
  notifyInSlack: Scalars['Boolean']['output'];
  project: ProjectType;
  projectEnvironment?: Maybe<Scalars['String']['output']>;
  projectKey: Scalars['String']['output'];
  projectName: Scalars['String']['output'];
  projectUrl: Scalars['String']['output'];
  provider: FlagProvider;
  slug: Scalars['String']['output'];
  type: ChangeSourceType;
  usedByDeployments: Array<CodeChangeSource>;
};


export type FeatureFlagChangeSourceChangesArgs = {
  endDate?: InputMaybe<Scalars['String']['input']>;
  environmentSlug: Scalars['ID']['input'];
  health?: InputMaybe<Scalars['String']['input']>;
  omitLatestChange?: InputMaybe<Scalars['Boolean']['input']>;
  page: Scalars['Int']['input'];
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
};


export type FeatureFlagChangeSourceLatestChangeArgs = {
  environmentSlug: Scalars['ID']['input'];
};


export type FeatureFlagChangeSourceNextDeployArgs = {
  environmentSlug?: InputMaybe<Scalars['ID']['input']>;
};


export type FeatureFlagChangeSourceNoDeploysArgs = {
  environmentSlug: Scalars['ID']['input'];
};


export type FeatureFlagChangeSourceProjectEnvironmentArgs = {
  environmentSlug: Scalars['ID']['input'];
};

export type FileModelType = {
  __typename?: 'FileModelType';
  additions: Scalars['Int']['output'];
  deletions: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type FilePaginatedType = {
  __typename?: 'FilePaginatedType';
  count: Scalars['Int']['output'];
  hasNext: Scalars['Boolean']['output'];
  hasPrev: Scalars['Boolean']['output'];
  objects: Array<FileModelType>;
  page: Scalars['Int']['output'];
  pages: Scalars['Int']['output'];
};

export type FireHydrantIncidentImpactSourceInputType = {
  /** The slug for the integration */
  integrationSlug?: InputMaybe<Scalars['String']['input']>;
  /** The environment defined in FireHydrant to monitor */
  remoteEnvironments?: InputMaybe<Scalars['String']['input']>;
  /** If true, incident considered to have ended once reaching mitigated Milestone or it is resolved */
  remoteMitigatedIsHealthy?: InputMaybe<Scalars['Boolean']['input']>;
  /** The service defined in FireHydrant to monitor */
  remoteServices?: InputMaybe<Scalars['String']['input']>;
};

export type FireHydrantProviderData = {
  __typename?: 'FireHydrantProviderData';
  remoteEnvironments?: Maybe<Scalars['String']['output']>;
  remoteMitigatedIsHealthy?: Maybe<Scalars['Boolean']['output']>;
  remoteServices?: Maybe<Scalars['String']['output']>;
};

export type FirehydrantRemoteEnvironment = {
  __typename?: 'FirehydrantRemoteEnvironment';
  label: Scalars['String']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type FirehydrantRemoteService = {
  __typename?: 'FirehydrantRemoteService';
  label: Scalars['String']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export enum FlagProvider {
  /** Launchdarkly */
  Launchdarkly = 'LAUNCHDARKLY'
}

export type GoalCreateMutation = {
  __typename?: 'GoalCreateMutation';
  slug: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type GoalDeleteInputType = {
  goalSlug: Scalars['ID']['input'];
  orgSlug: Scalars['ID']['input'];
};

export type GoalDeleteMutation = {
  __typename?: 'GoalDeleteMutation';
  success: Scalars['Boolean']['output'];
};

export enum GoalExpressionType {
  /** Bound to the baseline */
  Baseline = 'BASELINE',
  /** Static - maintain static value */
  Static = 'STATIC'
}

export type GoalInputType = {
  codeChangeSourceSlug?: InputMaybe<Scalars['ID']['input']>;
  dueOn?: InputMaybe<Scalars['DateTime']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  environmentName?: InputMaybe<Scalars['String']['input']>;
  environmentSlug?: InputMaybe<Scalars['ID']['input']>;
  expressionType: GoalExpressionType;
  lessIsBetter?: InputMaybe<Scalars['Boolean']['input']>;
  metric: GoalMetricType;
  orgSlug: Scalars['ID']['input'];
  projectSlug?: InputMaybe<Scalars['ID']['input']>;
  startOn?: InputMaybe<Scalars['DateTime']['input']>;
  target: Scalars['Float']['input'];
  teamSlug?: InputMaybe<Scalars['ID']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export enum GoalMetricType {
  /** Coding */
  Coding = 'CODING',
  /** Deploying */
  Deploying = 'DEPLOYING',
  /** Review */
  Review = 'REVIEW',
  /** Review Lag */
  ReviewLag = 'REVIEW_LAG'
}

export enum GoalNotificationChatProviderType {
  /** Slack */
  Slack = 'SLACK'
}

export enum GoalNotificationEnumType {
  /** Send message to a channel */
  Channel = 'CHANNEL',
  /** Send message to a user */
  User = 'USER'
}

export type GoalNotificationInputType = {
  atChannel?: InputMaybe<Scalars['Boolean']['input']>;
  atMentionsUser?: InputMaybe<Scalars['Boolean']['input']>;
  /** Mandatory when notification type is CHANNEL */
  channel?: InputMaybe<Scalars['String']['input']>;
  ignoreDraftPrs?: InputMaybe<Scalars['Boolean']['input']>;
  integrationAuthSlug?: InputMaybe<Scalars['ID']['input']>;
  provider: GoalNotificationChatProviderType;
  threshold: Scalars['Float']['input'];
  thresholdType: GoalNotificationThresholdType;
  type: GoalNotificationEnumType;
};

export enum GoalNotificationThresholdType {
  /** Threshold represents exact time on which notification should happen */
  Exact = 'EXACT',
  /** Threshold represents a % on when notification should happen */
  Percentage = 'PERCENTAGE'
}

export type GoalNotificationType = {
  __typename?: 'GoalNotificationType';
  atChannel: Scalars['Boolean']['output'];
  atMentionsUser: Scalars['Boolean']['output'];
  channel?: Maybe<Scalars['String']['output']>;
  integrationAuth?: Maybe<IntegrationAuthenticationType>;
  provider: GoalNotificationChatProviderType;
  threshold: Scalars['Float']['output'];
  thresholdType: GoalNotificationThresholdType;
  type: GoalNotificationEnumType;
  user?: Maybe<UserType>;
};

export type GoalNotificationTypeEdge = {
  __typename?: 'GoalNotificationTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: GoalNotificationType;
};

export type GoalNotificationsConnection = {
  __typename?: 'GoalNotificationsConnection';
  edges: Array<GoalNotificationTypeEdge>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of edges. */
  totalCount: Scalars['Int']['output'];
};

export type GoalNotificationsCreateMutation = {
  __typename?: 'GoalNotificationsCreateMutation';
  success: Scalars['Boolean']['output'];
};

export type GoalType = {
  __typename?: 'GoalType';
  dueOn?: Maybe<Scalars['DateTime']['output']>;
  enabled: Scalars['Boolean']['output'];
  expressionType: GoalExpressionType;
  lessIsBetter: Scalars['Boolean']['output'];
  metric: GoalMetricType;
  notifications: GoalNotificationsConnection;
  slug: Scalars['String']['output'];
  startOn?: Maybe<Scalars['DateTime']['output']>;
  target: Scalars['Float']['output'];
  title: Scalars['String']['output'];
};


export type GoalTypeNotificationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type GoalTypeEdge = {
  __typename?: 'GoalTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: GoalType;
};

export type GoalUpdateInputType = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  goalSlug: Scalars['ID']['input'];
  orgSlug: Scalars['ID']['input'];
  target?: InputMaybe<Scalars['Float']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type GoalUpdateMutation = {
  __typename?: 'GoalUpdateMutation';
  success: Scalars['Boolean']['output'];
};

export type GoalsConnection = {
  __typename?: 'GoalsConnection';
  edges: Array<GoalTypeEdge>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of edges. */
  totalCount: Scalars['Int']['output'];
};

export type GreatTeamInsightType = {
  __typename?: 'GreatTeamInsightType';
  icon: Scalars['String']['output'];
  message: Scalars['String']['output'];
  teamLevel: AccRanking;
};

export type HealthBreakdownType = {
  __typename?: 'HealthBreakdownType';
  ailing: Scalars['Float']['output'];
  healthy: Scalars['Float']['output'];
  incident: Scalars['Float']['output'];
  rolledBack: Scalars['Float']['output'];
  unhealthy: Scalars['Float']['output'];
};

export enum HealthSpanType {
  /** manual */
  Manual = 'MANUAL',
  /** rollback */
  Rollback = 'ROLLBACK',
  /** source */
  Source = 'SOURCE'
}

export type IgnorePullRequestCheckMutation = {
  __typename?: 'IgnorePullRequestCheckMutation';
  success: Scalars['Boolean']['output'];
};

export type IgnorePullRequestCheckType = {
  ignore?: InputMaybe<Scalars['Boolean']['input']>;
  prCheckId: Scalars['ID']['input'];
};

export enum IgnorePullRequestEventSourceType {
  DeployDetails = 'DEPLOY_DETAILS',
  NotSpecified = 'NOT_SPECIFIED',
  WorkInProgress = 'WORK_IN_PROGRESS'
}

export type ImpactErrorProjectConnection = {
  __typename?: 'ImpactErrorProjectConnection';
  edges: Array<ImpactErrorProjectTypeEdge>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of edges. */
  totalCount: Scalars['Int']['output'];
};

export type ImpactErrorProjectType = {
  __typename?: 'ImpactErrorProjectType';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  orgSlug: Scalars['String']['output'];
  provider: Scalars['String']['output'];
  slug: Scalars['String']['output'];
};

export type ImpactErrorProjectTypeEdge = {
  __typename?: 'ImpactErrorProjectTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: ImpactErrorProjectType;
};

export enum ImpactProvider {
  /** Appdynamics */
  Appdynamics = 'APPDYNAMICS',
  /** Azure */
  Azure = 'AZURE',
  /** Bitbucket Pipelines */
  BitbucketPipelines = 'BITBUCKET_PIPELINES',
  /** Blameless */
  Blameless = 'BLAMELESS',
  /** Bugsnag */
  Bugsnag = 'BUGSNAG',
  /** Buildkite */
  Buildkite = 'BUILDKITE',
  /** Circleci */
  Circleci = 'CIRCLECI',
  /** Cloudwatch */
  Cloudwatch = 'CLOUDWATCH',
  /** Clubhouse */
  Clubhouse = 'CLUBHOUSE',
  /** Custom Incident */
  CustomIncident = 'CUSTOM_INCIDENT',
  /** Custom Metric */
  CustomMetric = 'CUSTOM_METRIC',
  /** Datadog */
  Datadog = 'DATADOG',
  /** Firehydrant */
  Firehydrant = 'FIREHYDRANT',
  /** Github */
  Github = 'GITHUB',
  /** Github Enterprise */
  GithubEnterprise = 'GITHUB_ENTERPRISE',
  /** Gitlab */
  Gitlab = 'GITLAB',
  /** Honeybadger */
  Honeybadger = 'HONEYBADGER',
  /** Jenkins */
  Jenkins = 'JENKINS',
  /** Jira */
  Jira = 'JIRA',
  /** Jira Datacenter */
  JiraDatacenter = 'JIRA_DATACENTER',
  /** Newrelic */
  Newrelic = 'NEWRELIC',
  /** Opsgenie */
  Opsgenie = 'OPSGENIE',
  /** Pagerduty */
  Pagerduty = 'PAGERDUTY',
  /** Rollbar */
  Rollbar = 'ROLLBAR',
  /** Rootly */
  Rootly = 'ROOTLY',
  /** Sentry */
  Sentry = 'SENTRY',
  /** Sentry Self Hosted */
  SentrySelfHosted = 'SENTRY_SELF_HOSTED',
  /** Servicenow */
  Servicenow = 'SERVICENOW',
  /** Signalfx */
  Signalfx = 'SIGNALFX',
  /** Statuspage */
  Statuspage = 'STATUSPAGE'
}

export enum ImpactSensitivity {
  /** Coarse */
  Coarse = 'COARSE',
  /** Fine */
  Fine = 'FINE',
  /** Normal */
  Normal = 'NORMAL'
}

export type ImpactSourceConnection = {
  __typename?: 'ImpactSourceConnection';
  edges: Array<ImpactSourceFieldTypeEdge>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of edges. */
  totalCount: Scalars['Int']['output'];
};

export type ImpactSourceFieldType = BuildImpactSource | ErrorImpactSource | IncidentImpactSource | MetricImpactSource;

export type ImpactSourceFieldTypeEdge = {
  __typename?: 'ImpactSourceFieldTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: ImpactSourceFieldType;
};

export type ImpactSourceHealthResultType = {
  __typename?: 'ImpactSourceHealthResultType';
  ailingBorder?: Maybe<Scalars['Float']['output']>;
  analysisRan: Scalars['Boolean']['output'];
  deploysName: Array<Scalars['String']['output']>;
  deploysOn: Array<Scalars['DateTime']['output']>;
  deploysShortRevision: Array<Scalars['String']['output']>;
  deploysSlug: Array<Scalars['String']['output']>;
  means: Array<Scalars['Float']['output']>;
  meansMeasuredOn?: Maybe<Array<Maybe<Scalars['DateTime']['output']>>>;
  moreIsBetter: Scalars['Boolean']['output'];
  outliers: Array<Scalars['Float']['output']>;
  outliersMeasuredOn: Array<Maybe<Scalars['DateTime']['output']>>;
  pastMeasurementsHealth: Array<Scalars['String']['output']>;
  pastMeasurementsOn: Array<Scalars['DateTime']['output']>;
  pastMeasurementsValue: Array<Scalars['Float']['output']>;
  statsMean?: Maybe<Scalars['Float']['output']>;
  statsStd?: Maybe<Scalars['Float']['output']>;
  unhealthyBorder?: Maybe<Scalars['Float']['output']>;
};

export type ImpactSourceInterfaceType = {
  editLink: Scalars['String']['output'];
  environment: Environment;
  integrationAuthSlug?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  ostracizedOn?: Maybe<Scalars['DateTime']['output']>;
  provider: Scalars['String']['output'];
  registerImpactLink: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  type: ImpactType;
};

export type ImpactSynopsisType = {
  __typename?: 'ImpactSynopsisType';
  /** Impact source health that is limited by the worst health of the deploy. The health of incident and build sources cannot be bound. */
  boundHealth: DeployHealthType;
  /** Impact source health */
  health: DeployHealthType;
  remoteDashboardUrl?: Maybe<Scalars['String']['output']>;
  sourceName: Scalars['String']['output'];
  sourceProvider: ImpactProvider;
  sourceSlug?: Maybe<Scalars['String']['output']>;
  sourceType: ImpactType;
  /** An example value that the impact source had while in its worst health */
  typicalValue: Scalars['Float']['output'];
  valueChange: Scalars['Float']['output'];
  valueLabel: Scalars['String']['output'];
  /** The datetime when the typical value was recorded */
  valueMeasuredOn: Scalars['DateTime']['output'];
};

export type ImpactSynopsisUnionType = EmptyImpactSynopsisType | ImpactSynopsisType;

export enum ImpactType {
  /** Build */
  Build = 'BUILD',
  /** Error */
  Error = 'ERROR',
  /** Incident */
  Incident = 'INCIDENT',
  /** Metric */
  Metric = 'METRIC'
}

export type IncidentImpactSource = ImpactSourceInterfaceType & {
  __typename?: 'IncidentImpactSource';
  editLink: Scalars['String']['output'];
  environment: Environment;
  incidentSpans: OriginalHealthSpanConnection;
  integrationAuthSlug?: Maybe<Scalars['String']['output']>;
  latestImpacts: Array<LinkedImpactType>;
  name: Scalars['String']['output'];
  ostracizedOn?: Maybe<Scalars['DateTime']['output']>;
  provider: Scalars['String']['output'];
  providerData?: Maybe<ProviderDataFieldType>;
  registerImpactLink: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  type: ImpactType;
};


export type IncidentImpactSourceIncidentSpansArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  end: Scalars['DateTime']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  start: Scalars['DateTime']['input'];
};

export type IncidentImpactSourceCreateMutation = {
  __typename?: 'IncidentImpactSourceCreateMutation';
  errors?: Maybe<Array<ErrorType>>;
  impactSource?: Maybe<IncidentImpactSource>;
};

export type IncidentImpactSourceDeleteInputType = {
  projectSlug: Scalars['ID']['input'];
  slug: Scalars['ID']['input'];
};

export type IncidentImpactSourceDeleteMutation = {
  __typename?: 'IncidentImpactSourceDeleteMutation';
  success: Scalars['Boolean']['output'];
};

export type IncidentImpactSourceInputType = {
  blamelessInput?: InputMaybe<BlamelessIncidentImpactSourceInputType>;
  clubhouseInput?: InputMaybe<ClubhouseIncidentImpactSourceInputType>;
  datadogInput?: InputMaybe<DataDogInputType>;
  environmentName: Scalars['String']['input'];
  firehydrantInput?: InputMaybe<FireHydrantIncidentImpactSourceInputType>;
  jiraInput?: InputMaybe<JiraIncidentImpactSourceInputType>;
  name: Scalars['String']['input'];
  opsgenieInput?: InputMaybe<OpsgenieIncidentImpactSourceInputType>;
  pagerDutyInput?: InputMaybe<PagerDutyInputType>;
  projectSlug: Scalars['ID']['input'];
  provider: IncidentImpactSourceProvider;
  rootlyInput?: InputMaybe<RootlyIncidentImpactSourceInputType>;
  serviceNowInput?: InputMaybe<ServiceNowInputType>;
  statuspageInput?: InputMaybe<StatuspageIncidentImpactSourceInputType>;
};

export type IncidentImpactSourceInputUpdateType = {
  blamelessInput?: InputMaybe<BlamelessIncidentImpactSourceInputType>;
  clubhouseInput?: InputMaybe<ClubhouseIncidentImpactSourceInputType>;
  datadogInput?: InputMaybe<DataDogInputType>;
  environmentName?: InputMaybe<Scalars['String']['input']>;
  firehydrantInput?: InputMaybe<FireHydrantIncidentImpactSourceInputType>;
  jiraInput?: InputMaybe<JiraIncidentImpactSourceInputType>;
  name?: InputMaybe<Scalars['String']['input']>;
  opsgenieInput?: InputMaybe<OpsgenieIncidentImpactSourceInputType>;
  pagerDutyInput?: InputMaybe<PagerDutyInputType>;
  projectSlug: Scalars['ID']['input'];
  provider: IncidentImpactSourceProvider;
  rootlyInput?: InputMaybe<RootlyIncidentImpactSourceInputType>;
  serviceNowInput?: InputMaybe<ServiceNowInputType>;
  slug: Scalars['ID']['input'];
  statuspageInput?: InputMaybe<StatuspageIncidentImpactSourceInputType>;
};

export enum IncidentImpactSourceProvider {
  /** Blameless */
  Blameless = 'BLAMELESS',
  /** Clubhouse */
  Clubhouse = 'CLUBHOUSE',
  /** Custom Incident */
  CustomIncident = 'CUSTOM_INCIDENT',
  /** Datadog */
  Datadog = 'DATADOG',
  /** Firehydrant */
  Firehydrant = 'FIREHYDRANT',
  /** Jira */
  Jira = 'JIRA',
  /** Jira Datacenter */
  JiraDatacenter = 'JIRA_DATACENTER',
  /** Opsgenie */
  Opsgenie = 'OPSGENIE',
  /** Pagerduty */
  Pagerduty = 'PAGERDUTY',
  /** Rootly */
  Rootly = 'ROOTLY',
  /** Servicenow */
  Servicenow = 'SERVICENOW',
  /** Statuspage */
  Statuspage = 'STATUSPAGE'
}

export type IncidentImpactSourceUpdateMutation = {
  __typename?: 'IncidentImpactSourceUpdateMutation';
  errors?: Maybe<Array<ErrorType>>;
  impactSource?: Maybe<IncidentImpactSource>;
};

export enum InsightMetricType {
  /** batch size */
  BatchSize = 'BATCH_SIZE',
  /** coding */
  Coding = 'CODING',
  /** deploying */
  Deploying = 'DEPLOYING',
  /** failure rate */
  FailureRate = 'FAILURE_RATE',
  /** frequency */
  Frequency = 'FREQUENCY',
  /** lead time */
  LeadTime = 'LEAD_TIME',
  /** MTTR */
  Mttr = 'MTTR',
  /** review */
  Review = 'REVIEW',
  /** review lag */
  ReviewLag = 'REVIEW_LAG'
}

export type InsightUnionType = GreatTeamInsightType | MetricOffsetFromAverageInsightType | SteadyMetricsInsightType | TeamMetricLowInsightType | WorstDeployInsightType;

/** Paginated connection for installed Tools. */
export type InstalledToolsConnection = {
  __typename?: 'InstalledToolsConnection';
  edges: Array<ToolInstallationTypeEdge>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of edges. */
  totalCount: Scalars['Int']['output'];
};

export enum IntegrationAuthenticationConnectionType {
  /** Uses an API key to authenticate the integration */
  ApiKey = 'API_KEY',
  /** Uses a provider-specific app to authenticate the integration */
  App = 'APP',
  /** Users a SocialAuthUser to authenticate the integration */
  Oauth = 'OAUTH'
}

export type IntegrationAuthenticationType = {
  __typename?: 'IntegrationAuthenticationType';
  description: Scalars['String']['output'];
  org: OrganizationType;
  provider: Scalars['String']['output'];
  /** Be careful when changing this. Some relationships are referenced by slugs as strings. */
  slug: Scalars['String']['output'];
  type: IntegrationAuthenticationConnectionType;
  url?: Maybe<Scalars['String']['output']>;
};

export type IntegrationConnectionType = {
  __typename?: 'IntegrationConnectionType';
  apiClientName?: Maybe<Scalars['String']['output']>;
  camouflagedApiSecondaryToken: Scalars['String']['output'];
  camouflagedApiToken: Scalars['String']['output'];
  canBeEdited: Scalars['Boolean']['output'];
  description: Scalars['String']['output'];
  disconnectPath?: Maybe<Scalars['String']['output']>;
  extraHeaders?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  publishReleases?: Maybe<Scalars['Boolean']['output']>;
  readOnly?: Maybe<Scalars['Boolean']['output']>;
  realm?: Maybe<Scalars['String']['output']>;
  regionName?: Maybe<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
  type: IntegrationAuthenticationConnectionType;
  url?: Maybe<Scalars['String']['output']>;
  userLabel?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export enum IntegrationProvider {
  /** Appdynamics */
  Appdynamics = 'APPDYNAMICS',
  /** Aws */
  Aws = 'AWS',
  /** Azure */
  Azure = 'AZURE',
  /** Bitbucket */
  Bitbucket = 'BITBUCKET',
  /** Bitbucket Pipelines */
  BitbucketPipelines = 'BITBUCKET_PIPELINES',
  /** Blameless */
  Blameless = 'BLAMELESS',
  /** Bugsnag */
  Bugsnag = 'BUGSNAG',
  /** Buildkite */
  Buildkite = 'BUILDKITE',
  /** Circleci */
  Circleci = 'CIRCLECI',
  /** Cloudwatch */
  Cloudwatch = 'CLOUDWATCH',
  /** Clubhouse */
  Clubhouse = 'CLUBHOUSE',
  /** Codedeploy */
  Codedeploy = 'CODEDEPLOY',
  /** Custom Git */
  CustomGit = 'CUSTOM_GIT',
  /** Custom Incident */
  CustomIncident = 'CUSTOM_INCIDENT',
  /** Custom Metric */
  CustomMetric = 'CUSTOM_METRIC',
  /** Datadog */
  Datadog = 'DATADOG',
  /** Firehydrant */
  Firehydrant = 'FIREHYDRANT',
  /** Github */
  Github = 'GITHUB',
  /** Github Automations */
  GithubAutomations = 'GITHUB_AUTOMATIONS',
  /** Github Enterprise */
  GithubEnterprise = 'GITHUB_ENTERPRISE',
  /** Gitlab */
  Gitlab = 'GITLAB',
  /** Honeybadger */
  Honeybadger = 'HONEYBADGER',
  /** Jenkins */
  Jenkins = 'JENKINS',
  /** Jira */
  Jira = 'JIRA',
  /** Jira Datacenter */
  JiraDatacenter = 'JIRA_DATACENTER',
  /** Launchdarkly */
  Launchdarkly = 'LAUNCHDARKLY',
  /** Linear */
  Linear = 'LINEAR',
  /** Newrelic */
  Newrelic = 'NEWRELIC',
  /** Opsgenie */
  Opsgenie = 'OPSGENIE',
  /** Pagerduty */
  Pagerduty = 'PAGERDUTY',
  /** Rollbar */
  Rollbar = 'ROLLBAR',
  /** Rootly */
  Rootly = 'ROOTLY',
  /** Sentry */
  Sentry = 'SENTRY',
  /** Sentry Self Hosted */
  SentrySelfHosted = 'SENTRY_SELF_HOSTED',
  /** Servicenow */
  Servicenow = 'SERVICENOW',
  /** Signalfx */
  Signalfx = 'SIGNALFX',
  /** Slack */
  Slack = 'SLACK',
  /** Statuspage */
  Statuspage = 'STATUSPAGE'
}

export type IntegrationProviderAuthType = {
  __typename?: 'IntegrationProviderAuthType';
  /** Is true if the integration provider supports multiple authentication connections. */
  isMulti: Scalars['Boolean']['output'];
  oauthPath?: Maybe<Scalars['String']['output']>;
  type: IntegrationAuthenticationConnectionType;
};

export type IntegrationProviderDefinitionType = {
  __typename?: 'IntegrationProviderDefinitionType';
  auth: IntegrationProviderAuthType;
  category: ProviderCategory;
  connections: Array<IntegrationConnectionType>;
  integrationConfigured: Scalars['Boolean']['output'];
  integrationEnabled: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
  provider: IntegrationProvider;
};

export type InvitationConnection = {
  __typename?: 'InvitationConnection';
  edges: Array<InvitationTypeEdge>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of edges. */
  totalCount: Scalars['Int']['output'];
};

export type InvitationGqlType = {
  __typename?: 'InvitationGQLType';
  email: Scalars['String']['output'];
  orgName: Scalars['String']['output'];
  orgSlug: Scalars['String']['output'];
};

export type InvitationType = Node & {
  __typename?: 'InvitationType';
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  invitedBy?: Maybe<UserType>;
  on: Scalars['DateTime']['output'];
};

export type InvitationTypeEdge = {
  __typename?: 'InvitationTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: InvitationType;
};

export type InviteMemberMutationInput = {
  email: Scalars['String']['input'];
  role: Scalars['String']['input'];
};

export type InviteMemberMutationPayload = {
  __typename?: 'InviteMemberMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  success: Scalars['Boolean']['output'];
};

export type IssueBreakdownConnection = {
  __typename?: 'IssueBreakdownConnection';
  edges: Array<IssueBreakdownItemTypeEdge>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of edges. */
  totalCount: Scalars['Int']['output'];
};

export type IssueBreakdownIssuesConnection = {
  __typename?: 'IssueBreakdownIssuesConnection';
  edges: Array<LinkedIssueTypeEdge>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of edges. */
  totalCount: Scalars['Int']['output'];
};

export type IssueBreakdownItemType = {
  __typename?: 'IssueBreakdownItemType';
  count: Scalars['Int']['output'];
  label: Scalars['String']['output'];
};

export type IssueBreakdownItemTypeEdge = {
  __typename?: 'IssueBreakdownItemTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: IssueBreakdownItemType;
};

export enum IssueBreakdownPage {
  /** Metrics */
  Metrics = 'METRICS',
  /** Work In Progress */
  Wip = 'WIP'
}

export enum IssueBreakdownType {
  /** Issue state */
  State = 'STATE',
  /** Issue type */
  Type = 'TYPE'
}

export type IssueModelType = {
  __typename?: 'IssueModelType';
  assigneeGravatar: Scalars['String']['output'];
  assigneeName: Scalars['String']['output'];
  createdOn: Scalars['DateTime']['output'];
  deployOn: Scalars['DateTime']['output'];
  deploymentBranch: Scalars['String']['output'];
  description: Scalars['String']['output'];
  key: Scalars['String']['output'];
  linkifiedTitle: Scalars['String']['output'];
  title: Scalars['String']['output'];
  type: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type IssueModelTypeEdge = {
  __typename?: 'IssueModelTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: IssueModelType;
};

export type IssuePaginatedType = {
  __typename?: 'IssuePaginatedType';
  count: Scalars['Int']['output'];
  hasNext: Scalars['Boolean']['output'];
  hasPrev: Scalars['Boolean']['output'];
  objects: Array<IssueModelType>;
  page: Scalars['Int']['output'];
  pages: Scalars['Int']['output'];
};

export type IssueStateType = {
  __typename?: 'IssueStateType';
  externalId: Scalars['String']['output'];
  externalLabel: Scalars['String']['output'];
  externalProjectId: Scalars['String']['output'];
  externalProjectLabel: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type IssueStateTypeEdge = {
  __typename?: 'IssueStateTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: IssueStateType;
};

export type IssueStatesConnection = {
  __typename?: 'IssueStatesConnection';
  edges: Array<IssueStateTypeEdge>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of edges. */
  totalCount: Scalars['Int']['output'];
};

/** Where to find issues linked to by changes */
export enum IssueTrackerChoices {
  /** Issues tracked by a Shortcut project */
  Clubhouse = 'CLUBHOUSE',
  /** Issues tracked by a Jira Cloud project */
  Jira = 'JIRA',
  /** Issues tracked by a Jira Data Center project */
  JiraDatacenter = 'JIRA_DATACENTER',
  /** Issues tracked by a Linear workspace */
  Linear = 'LINEAR',
  /** Don't track issues for each deploy */
  None = 'NONE',
  /** Issues tracked by your source code repository provider */
  SourceProvider = 'SOURCE_PROVIDER'
}

export type IssueType = {
  __typename?: 'IssueType';
  key: Scalars['String']['output'];
};

export type JiraDatacenterProviderData = {
  __typename?: 'JiraDatacenterProviderData';
  remoteJql?: Maybe<Scalars['String']['output']>;
};

export type JiraIncidentImpactSourceInputType = {
  integrationSlug?: InputMaybe<Scalars['String']['input']>;
  /** JQL expression */
  remoteJql?: InputMaybe<Scalars['String']['input']>;
};

export type JiraProviderData = {
  __typename?: 'JiraProviderData';
  remoteJql?: Maybe<Scalars['String']['output']>;
};

export type LabelAndValueType = {
  __typename?: 'LabelAndValueType';
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type LabelConnection = {
  __typename?: 'LabelConnection';
  edges: Array<ProjectLabelTypeEdge>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of edges. */
  totalCount: Scalars['Int']['output'];
};

export type LabelType = {
  __typename?: 'LabelType';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  org: OrganizationType;
  projects: Array<ProjectType>;
  slug: Scalars['String']['output'];
};

export type LinkExternalUserInputType = {
  externalUserIds: Array<Scalars['ID']['input']>;
  userId: Scalars['ID']['input'];
};

export type LinkExternalUserMutation = {
  __typename?: 'LinkExternalUserMutation';
  errors?: Maybe<Array<ErrorType>>;
  success: Scalars['Boolean']['output'];
};

export type LinkType = {
  __typename?: 'LinkType';
  category: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type LinkedImpactType = {
  __typename?: 'LinkedImpactType';
  health: DeployHealthType;
  measuredOn: Scalars['DateTime']['output'];
  title: Scalars['String']['output'];
  uid?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type LinkedIssueType = {
  __typename?: 'LinkedIssueType';
  key: Scalars['String']['output'];
  state: Scalars['String']['output'];
  title: Scalars['String']['output'];
  type: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type LinkedIssueTypeEdge = {
  __typename?: 'LinkedIssueTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: LinkedIssueType;
};

export type LinkedPullRequestHistoryType = {
  __typename?: 'LinkedPullRequestHistoryType';
  event: DeployLinkedPullRequestHistoryEventChoices;
  on: Scalars['DateTime']['output'];
};

export type LinkedPullRequestType = {
  __typename?: 'LinkedPullRequestType';
  author?: Maybe<ExternalUserType>;
  authorGravatar: Scalars['String']['output'];
  authorName: Scalars['String']['output'];
  baseBranchName?: Maybe<Scalars['String']['output']>;
  changeLinks: Array<ChangeLinkType>;
  commitCount: Scalars['Int']['output'];
  commits: CommitsConnection;
  description: Scalars['String']['output'];
  durations: DurationsType;
  history: Array<LinkedPullRequestHistoryType>;
  id: Scalars['ID']['output'];
  ignored: Scalars['Boolean']['output'];
  isDraft: Scalars['Boolean']['output'];
  issueCount: Scalars['Int']['output'];
  on: Scalars['DateTime']['output'];
  prId: Scalars['String']['output'];
  repository: RepositoryType;
  sourceBranchName?: Maybe<Scalars['String']['output']>;
  state: PullRequestStateType;
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
  workingHoursUsedForDurations: Array<WorkingHoursUsedForPrcltCalculationType>;
};


export type LinkedPullRequestTypeChangeLinksArgs = {
  environmentSlug: Scalars['ID']['input'];
};


export type LinkedPullRequestTypeCommitsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type LinkedPullRequestTypeDurationsArgs = {
  change?: InputMaybe<Scalars['ID']['input']>;
  environment: Scalars['ID']['input'];
};

export type LinkedPullRequestTypeEdge = {
  __typename?: 'LinkedPullRequestTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: LinkedPullRequestType;
};

export type LoginMethodInputType = {
  provider?: InputMaybe<SocialLoginProviderType>;
  type: AllowedLoginMethodType;
};

export type ManualChangeSource = ChangeSource & {
  __typename?: 'ManualChangeSource';
  changes: ChangePaginatedType;
  collectImpact: Scalars['Boolean']['output'];
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isBroken: Scalars['Boolean']['output'];
  isCicdBroken: Scalars['Boolean']['output'];
  latestChange?: Maybe<ChangeType>;
  lock: CodeDeploymentLockStatus;
  name: Scalars['String']['output'];
  nextDeploy?: Maybe<ChangeType>;
  noDeploys: Scalars['Boolean']['output'];
  project: ProjectType;
  slug: Scalars['String']['output'];
  type: ChangeSourceType;
};


export type ManualChangeSourceChangesArgs = {
  endDate?: InputMaybe<Scalars['String']['input']>;
  environmentSlug: Scalars['ID']['input'];
  health?: InputMaybe<Scalars['String']['input']>;
  omitLatestChange?: InputMaybe<Scalars['Boolean']['input']>;
  page: Scalars['Int']['input'];
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
};


export type ManualChangeSourceLatestChangeArgs = {
  environmentSlug: Scalars['ID']['input'];
};


export type ManualChangeSourceNextDeployArgs = {
  environmentSlug?: InputMaybe<Scalars['ID']['input']>;
};


export type ManualChangeSourceNoDeploysArgs = {
  environmentSlug: Scalars['ID']['input'];
};

export type MessageType = {
  __typename?: 'MessageType';
  message: Scalars['String']['output'];
  type: MessageTypeEnum;
};

export enum MessageTypeEnum {
  Error = 'ERROR',
  Info = 'INFO',
  Warning = 'WARNING'
}

export type MetricChartType = {
  datapoints: Array<Scalars['Float']['output']>;
  labels: Array<Scalars['String']['output']>;
};

export type MetricFailureRateChartType = MetricChartType & {
  __typename?: 'MetricFailureRateChartType';
  datapoints: Array<Scalars['Float']['output']>;
  labels: Array<Scalars['String']['output']>;
  numOfAilingDeploys: Scalars['Int']['output'];
  numOfHealthyDeploys: Scalars['Int']['output'];
  numOfIncidentDeploys: Scalars['Int']['output'];
  numOfRolledBackDeploys: Scalars['Int']['output'];
  numOfUnhealthyDeploys: Scalars['Int']['output'];
};

export type MetricFrequencyChartType = MetricChartType & {
  __typename?: 'MetricFrequencyChartType';
  datapoints: Array<Scalars['Float']['output']>;
  ffDeploys: Array<Scalars['Int']['output']>;
  labels: Array<Scalars['String']['output']>;
  sizesByDay: Array<DeploySizesType>;
};

export enum MetricImpactProvider {
  /** Appdynamics */
  Appdynamics = 'APPDYNAMICS',
  /** Cloudwatch */
  Cloudwatch = 'CLOUDWATCH',
  /** Custom Metric */
  CustomMetric = 'CUSTOM_METRIC',
  /** Datadog */
  Datadog = 'DATADOG',
  /** Jira */
  Jira = 'JIRA',
  /** Jira Datacenter */
  JiraDatacenter = 'JIRA_DATACENTER',
  /** Newrelic */
  Newrelic = 'NEWRELIC',
  /** Signalfx */
  Signalfx = 'SIGNALFX'
}

export type MetricImpactSource = ImpactSourceInterfaceType & {
  __typename?: 'MetricImpactSource';
  baselineFrom: Scalars['DateTime']['output'];
  earliestNonhealthyThreshold?: Maybe<Scalars['Float']['output']>;
  editLink: Scalars['String']['output'];
  environment: Environment;
  healthAnalysis?: Maybe<ImpactSourceHealthResultType>;
  indisputableThreshold?: Maybe<Scalars['Float']['output']>;
  integrationAuthSlug?: Maybe<Scalars['String']['output']>;
  lessIsBetter: Scalars['Boolean']['output'];
  /** DEPRECATED Use earliestNonhealthyThreshold instead. */
  manuallySetHealthThreshold?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  ostracizedOn?: Maybe<Scalars['DateTime']['output']>;
  provider: Scalars['String']['output'];
  query?: Maybe<Scalars['String']['output']>;
  reBaselineLink: Scalars['String']['output'];
  registerImpactLink: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  type: ImpactType;
};


export type MetricImpactSourceHealthAnalysisArgs = {
  endDate?: InputMaybe<Scalars['String']['input']>;
  recalculateBorders?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MetricLeadTimeChartType = MetricChartType & {
  __typename?: 'MetricLeadTimeChartType';
  /** Time spent between first commit or first issue transition and a pull request being opened */
  codingTime: Scalars['Int']['output'];
  datapoints: Array<Scalars['Float']['output']>;
  /** Time spent between PR merge and deploy */
  deployingTime: Scalars['Int']['output'];
  labels: Array<Scalars['String']['output']>;
  /** Time spent between a pull request being opened and the first review */
  reviewLagTime: Scalars['Int']['output'];
  /** Time spent between first review and merge */
  reviewTime: Scalars['Int']['output'];
};

export type MetricMttrChartType = MetricChartType & {
  __typename?: 'MetricMTTRChartType';
  ailingDurationInSec: Scalars['Int']['output'];
  datapoints: Array<Scalars['Float']['output']>;
  incidentsDurationInSec: Scalars['Int']['output'];
  labels: Array<Scalars['String']['output']>;
  rolledBackDurationInSec: Scalars['Int']['output'];
  unhealthyDurationInSec: Scalars['Int']['output'];
};

export type MetricOffsetFromAverageInsightType = {
  __typename?: 'MetricOffsetFromAverageInsightType';
  icon: Scalars['String']['output'];
  insightLink: Scalars['String']['output'];
  insightMetricType: InsightMetricType;
  isPositive: Scalars['Boolean']['output'];
  message: Scalars['String']['output'];
  offsetPercentage: Scalars['Float']['output'];
};

export type MetricRecapFilterArguments = {
  /** If no environment name is provided, we default to the default environment of each project. The value is case insensitive. */
  environmentName?: InputMaybe<Scalars['String']['input']>;
  labelSlugs?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** If no project slug is provided, we default to all projects from this org */
  projectSlugs?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  teamSlugs?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export enum MetricType {
  /** failure rate */
  FailureRate = 'FAILURE_RATE',
  /** frequency */
  Frequency = 'FREQUENCY',
  /** lead time */
  LeadTime = 'LEAD_TIME',
  /** MTTR */
  Mttr = 'MTTR'
}

export type MoveDeploymentMutationInput = {
  deploymentSlug: Scalars['ID']['input'];
  environmentMappings?: InputMaybe<Array<EnvironmentMapping>>;
  targetProjectSlug: Scalars['ID']['input'];
};

export type MoveDeploymentMutationPayload = {
  __typename?: 'MoveDeploymentMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  success: Scalars['Boolean']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addContributors?: Maybe<AddContributorsMutationPayload>;
  addOrgMetricsNotification?: Maybe<AddOrgMetricsNotification>;
  addTeamMembers?: Maybe<AddTeamMembersMutationPayload>;
  addTeamMetricsNotification?: Maybe<AddTeamMetricsNotification>;
  addWipNotification?: Maybe<AddWipNotification>;
  changeMemberRole?: Maybe<ChangeRoleMutationPayload>;
  claimEmailDomain?: Maybe<ClaimEmailDomainMutation>;
  createAccessToken?: Maybe<CreateAccessTokenMutation>;
  createAppdynamicsIntegration?: Maybe<CreateAppDynamicsIntegrationMutationPayload>;
  createAwsIntegration?: Maybe<CreateAwsIntegrationMutationPayload>;
  createAzureIntegration?: Maybe<CreateAzureIntegrationMutationPayload>;
  createBitbucketIntegration?: Maybe<CreateBitbucketIntegrationMutationPayload>;
  createBitbucketPipelinesIntegration?: Maybe<CreateBitbucketPipelinesIntegrationMutationPayload>;
  createBlamelessIntegration?: Maybe<CreateBlamelessIntegrationMutationPayload>;
  createBugsnagIntegration?: Maybe<CreateBugsnagIntegrationMutationPayload>;
  createBuildImpactSource?: Maybe<CreateBuildImpactSourceMutationPayload>;
  createBuildkiteIntegration?: Maybe<CreateBuildkiteIntegrationMutationPayload>;
  createCircleciIntegration?: Maybe<CreateCircleCiIntegrationMutationPayload>;
  createCloudwatchIntegration?: Maybe<CreateCloudWatchIntegrationMutationPayload>;
  createClubhouseIntegration?: Maybe<CreateClubhouseIntegrationMutationPayload>;
  createCodeChangeSource?: Maybe<CreateCodeChangeSourceMutationPayload>;
  createDatadogIntegration?: Maybe<CreateDdIntegrationMutationPayload>;
  createEnvironment?: Maybe<CreateEnvironmentMutationPayload>;
  createErrorImpactSource?: Maybe<CreateErrorImpactSourceMutationPayload>;
  createFeatureFlagChangeSource?: Maybe<CreateFeatureFlagChangeSourceMutationPayload>;
  createFirehydrantIntegration?: Maybe<CreateFireHydrantIntegrationMutationPayload>;
  createGithubEnterpriseIntegration?: Maybe<CreateGithubEnterpriseIntegrationMutationPayload>;
  createGitlabIntegration?: Maybe<CreateGitlabIntegrationMutationPayload>;
  createGoal?: Maybe<GoalCreateMutation>;
  createGoalNotifications?: Maybe<GoalNotificationsCreateMutation>;
  createHoneybadgerIntegration?: Maybe<CreateHoneybadgerIntegrationMutationPayload>;
  createIncidentImpactSource?: Maybe<IncidentImpactSourceCreateMutation>;
  createIntegration?: Maybe<CreateIntegrationMutationPayload>;
  createJenkinsIntegration?: Maybe<CreateJenkinsIntegrationMutationPayload>;
  createJiraCloudIntegration?: Maybe<CreateJiraCloudIntegrationMutationPayload>;
  createJiraDatacenterIntegration?: Maybe<CreateJiraDatacenterIntegrationMutationPayload>;
  createLabel?: Maybe<CreateLabelMutationPayload>;
  createLinearIntegration?: Maybe<CreateLinearIntegrationMutationPayload>;
  createMetricImpactSource?: Maybe<CreateMetricImpactSourceMutationPayload>;
  createNewrelicIntegration?: Maybe<CreateNrIntegrationMutationPayload>;
  createOpsgenieIntegration?: Maybe<CreateOpsgenieIntegrationMutationPayload>;
  createPagerdutyImpactSource?: Maybe<CreatePagerDutyImpactSourceMutationPayload>;
  createPersonalToken?: Maybe<CreatePersonalTokenMutation>;
  createProject?: Maybe<CreateProjectMutationPayload>;
  createRollbarIntegration?: Maybe<CreateRollbarIntegrationMutationPayload>;
  createRootlyIntegration?: Maybe<CreateRootlyIntegrationMutationPayload>;
  createSentryIntegration?: Maybe<CreateSentryIntegrationMutationPayload>;
  createSentrySelfHostedIntegration?: Maybe<CreateSentrySelfHostedIntegrationMutationPayload>;
  createServicenowIntegration?: Maybe<CreateServiceNowIntegrationMutationPayload>;
  createSignalfxIntegration?: Maybe<CreateSignalFxIntegrationMutationPayload>;
  createStatuspageIntegration?: Maybe<CreateStatuspageIntegrationMutationPayload>;
  createTeam?: Maybe<CreateTeamMutationPayload>;
  deleteAccessToken?: Maybe<DeleteAccessTokenMutation>;
  deleteBulkUserWorkingHours?: Maybe<DeleteBulkUserWorkingHoursMutation>;
  deleteChangeSource?: Maybe<DeleteChangeSourceMutationPayload>;
  deleteCurrentUserWorkingHours?: Maybe<DeleteCurrentUserWorkingHoursMutation>;
  deleteEnvironment?: Maybe<DeleteEnvironmentMutationPayload>;
  deleteGoal?: Maybe<GoalDeleteMutation>;
  deleteImpactSource?: Maybe<DeleteImpactSourceMutationPayload>;
  deleteIncidentImpactSource?: Maybe<IncidentImpactSourceDeleteMutation>;
  deleteIntegrationAuthentication?: Maybe<DeleteIntegrationAuthenticationMutationPayload>;
  deleteLabel?: Maybe<DeleteLabelMutationPayload>;
  deleteMember?: Maybe<DeleteMemberMutationPayload>;
  deleteOrganizationWorkingHours?: Maybe<DeleteOrganizationWorkingHoursMutation>;
  deletePersonalToken?: Maybe<DeletePersonalTokenMutation>;
  deleteProject?: Maybe<DeleteProjectMutationPayload>;
  deleteTeam?: Maybe<DeleteTeamMutationPayload>;
  environment?: Maybe<EnvironmentMutation>;
  ignorePrCheck?: Maybe<IgnorePullRequestCheckMutation>;
  inviteMember?: Maybe<InviteMemberMutationPayload>;
  linkExternalUsers?: Maybe<LinkExternalUserMutation>;
  markSeen?: Maybe<SeenEventType>;
  moveDeployment?: Maybe<MoveDeploymentMutationPayload>;
  removeTeamMembers?: Maybe<RemoveTeamMembersMutationPayload>;
  resendEmailConfirmation?: Maybe<ResendEmailConfirmationMutation>;
  resendInvitation?: Maybe<ResendInvitationMutationPayload>;
  revokeInvitation?: Maybe<RevokeInvitationMutationPayload>;
  setBulkUserWorkingHours?: Maybe<SetBulkUserWorkingHoursMutation>;
  setChannelEnvironmentMappings?: Maybe<SetChannelEnvironmentMappingMutation>;
  setCurrentUserWorkingHours?: Maybe<SetCurrentUserWorkingHoursMutation>;
  setDefaultEnvironment?: Maybe<SetDefaultEnvironmentMutationPayload>;
  setHealth?: Maybe<SetHealthMutation>;
  setOrganizationWorkingHours?: Maybe<SetOrganizationWorkingHoursMutation>;
  setProjectInSession?: Maybe<SetProjectInSessionMutation>;
  setTeamInSession?: Maybe<SetTeamInSessionMutation>;
  setTeamsSyncStatus?: Maybe<TeamsSyncStatusMutation>;
  setToolInstallation?: Maybe<SetToolInstallationMutationPayload>;
  testAndFixDeploymentConnection?: Maybe<TestAndFixDeploymentConnectionMutation>;
  testAndFixImpactSourceConnection?: Maybe<TestAndFixImpactSourceConnectionMutation>;
  toggleDeploymentLock?: Maybe<ToggleDeploymentLockMutation>;
  toggleProjectLock?: Maybe<ToggleProjectLockMutation>;
  togglePullRequestIgnored?: Maybe<ToggleLinkedPullRequestIgnoredMutation>;
  /** Toggle email and Slack reports. If the notification exists, it is deleted, otherwise it is created. */
  toggleReportNotifications?: Maybe<ToggleReportNotifications>;
  triggerCodeChangeSourceReInitialization?: Maybe<TriggerCodeChangeSourceReInitializationMutation>;
  /** @deprecated Use `triggerRemoteObjectSync` mutation instead. */
  triggerIssueStatusesSync?: Maybe<TriggerIssueStatusesSyncMutation>;
  triggerRemoteObjectSync?: Maybe<TriggerRemoteObjectSyncMutation>;
  triggerTeamsSync?: Maybe<TriggerTeamsSyncMutation>;
  unlinkExternalUsers?: Maybe<UnlinkExternalUserMutation>;
  unsubscribe?: Maybe<UnsubscribeFromEmailReports>;
  updateBuildImpactSource?: Maybe<UpdateBuildImpactSourceMutationPayload>;
  updateCodeChangeSource?: Maybe<UpdateCodeChangeSourceMutationPayload>;
  updateEnvironment?: Maybe<UpdateEnvironmentMutationPayload>;
  updateErrorImpactSource?: Maybe<UpdateErrorImpactSourceMutationPayload>;
  updateFeatureFlagChangeSource?: Maybe<UpdateFeatureFlagChangeSourceMutationPayload>;
  updateGitlabIntegration?: Maybe<UpdateGitlabIntegrationMutationPayload>;
  updateGoal?: Maybe<GoalUpdateMutation>;
  updateIncidentImpactSource?: Maybe<IncidentImpactSourceUpdateMutation>;
  updateLabel?: Maybe<UpdateLabelMutationPayload>;
  updateMetricImpactSource?: Maybe<UpdateMetricImpactSourceMutationPayload>;
  updateOrganization?: Maybe<UpdateOrganizationMutation>;
  updatePagerdutyImpactSource?: Maybe<UpdatePagerDutyImpactSourceMutationPayload>;
  updateProject?: Maybe<UpdateProjectMutationPayload>;
  updateTeam?: Maybe<UpdateTeamMutationPayload>;
  updateUser?: Maybe<UpdateUserMutation>;
  validateRules?: Maybe<ValidateRulesMutation>;
};


export type MutationAddContributorsArgs = {
  input: AddContributorsMutationInput;
};


export type MutationAddOrgMetricsNotificationArgs = {
  input: OrgMetricsNotificationInputType;
};


export type MutationAddTeamMembersArgs = {
  input: AddTeamMembersMutationInput;
};


export type MutationAddTeamMetricsNotificationArgs = {
  input: TeamMetricsNotificationInputType;
};


export type MutationAddWipNotificationArgs = {
  input: WipNotificationInputType;
};


export type MutationChangeMemberRoleArgs = {
  input: ChangeRoleMutationInput;
};


export type MutationClaimEmailDomainArgs = {
  enable: Scalars['Boolean']['input'];
  orgSlug: Scalars['ID']['input'];
};


export type MutationCreateAccessTokenArgs = {
  input: CreateAccessTokenInputType;
};


export type MutationCreateAppdynamicsIntegrationArgs = {
  input: CreateAppDynamicsIntegrationMutationInput;
};


export type MutationCreateAwsIntegrationArgs = {
  input: CreateAwsIntegrationMutationInput;
};


export type MutationCreateAzureIntegrationArgs = {
  input: CreateAzureIntegrationMutationInput;
};


export type MutationCreateBitbucketIntegrationArgs = {
  input: CreateBitbucketIntegrationMutationInput;
};


export type MutationCreateBitbucketPipelinesIntegrationArgs = {
  input: CreateBitbucketPipelinesIntegrationMutationInput;
};


export type MutationCreateBlamelessIntegrationArgs = {
  input: CreateBlamelessIntegrationMutationInput;
};


export type MutationCreateBugsnagIntegrationArgs = {
  input: CreateBugsnagIntegrationMutationInput;
};


export type MutationCreateBuildImpactSourceArgs = {
  input: CreateBuildImpactSourceMutationInput;
};


export type MutationCreateBuildkiteIntegrationArgs = {
  input: CreateBuildkiteIntegrationMutationInput;
};


export type MutationCreateCircleciIntegrationArgs = {
  input: CreateCircleCiIntegrationMutationInput;
};


export type MutationCreateCloudwatchIntegrationArgs = {
  input: CreateCloudWatchIntegrationMutationInput;
};


export type MutationCreateClubhouseIntegrationArgs = {
  input: CreateClubhouseIntegrationMutationInput;
};


export type MutationCreateCodeChangeSourceArgs = {
  input: CreateCodeChangeSourceMutationInput;
};


export type MutationCreateDatadogIntegrationArgs = {
  input: CreateDdIntegrationMutationInput;
};


export type MutationCreateEnvironmentArgs = {
  input: CreateEnvironmentMutationInput;
};


export type MutationCreateErrorImpactSourceArgs = {
  input: CreateErrorImpactSourceMutationInput;
};


export type MutationCreateFeatureFlagChangeSourceArgs = {
  input: CreateFeatureFlagChangeSourceMutationInput;
};


export type MutationCreateFirehydrantIntegrationArgs = {
  input: CreateFireHydrantIntegrationMutationInput;
};


export type MutationCreateGithubEnterpriseIntegrationArgs = {
  input: CreateGithubEnterpriseIntegrationMutationInput;
};


export type MutationCreateGitlabIntegrationArgs = {
  input: CreateGitlabIntegrationMutationInput;
};


export type MutationCreateGoalArgs = {
  input: GoalInputType;
};


export type MutationCreateGoalNotificationsArgs = {
  goalSlug: Scalars['ID']['input'];
  notificationsInput: Array<GoalNotificationInputType>;
  orgSlug: Scalars['ID']['input'];
};


export type MutationCreateHoneybadgerIntegrationArgs = {
  input: CreateHoneybadgerIntegrationMutationInput;
};


export type MutationCreateIncidentImpactSourceArgs = {
  input: IncidentImpactSourceInputType;
};


export type MutationCreateIntegrationArgs = {
  input: CreateIntegrationMutationInput;
};


export type MutationCreateJenkinsIntegrationArgs = {
  input: CreateJenkinsIntegrationMutationInput;
};


export type MutationCreateJiraCloudIntegrationArgs = {
  input: CreateJiraCloudIntegrationMutationInput;
};


export type MutationCreateJiraDatacenterIntegrationArgs = {
  input: CreateJiraDatacenterIntegrationMutationInput;
};


export type MutationCreateLabelArgs = {
  input: CreateLabelMutationInput;
};


export type MutationCreateLinearIntegrationArgs = {
  input: CreateLinearIntegrationMutationInput;
};


export type MutationCreateMetricImpactSourceArgs = {
  input: CreateMetricImpactSourceMutationInput;
};


export type MutationCreateNewrelicIntegrationArgs = {
  input: CreateNrIntegrationMutationInput;
};


export type MutationCreateOpsgenieIntegrationArgs = {
  input: CreateOpsgenieIntegrationMutationInput;
};


export type MutationCreatePagerdutyImpactSourceArgs = {
  input: CreatePagerDutyImpactSourceMutationInput;
};


export type MutationCreatePersonalTokenArgs = {
  label: Scalars['String']['input'];
};


export type MutationCreateProjectArgs = {
  input: CreateProjectMutationInput;
};


export type MutationCreateRollbarIntegrationArgs = {
  input: CreateRollbarIntegrationMutationInput;
};


export type MutationCreateRootlyIntegrationArgs = {
  input: CreateRootlyIntegrationMutationInput;
};


export type MutationCreateSentryIntegrationArgs = {
  input: CreateSentryIntegrationMutationInput;
};


export type MutationCreateSentrySelfHostedIntegrationArgs = {
  input: CreateSentrySelfHostedIntegrationMutationInput;
};


export type MutationCreateServicenowIntegrationArgs = {
  input: CreateServiceNowIntegrationMutationInput;
};


export type MutationCreateSignalfxIntegrationArgs = {
  input: CreateSignalFxIntegrationMutationInput;
};


export type MutationCreateStatuspageIntegrationArgs = {
  input: CreateStatuspageIntegrationMutationInput;
};


export type MutationCreateTeamArgs = {
  input: CreateTeamMutationInput;
};


export type MutationDeleteAccessTokenArgs = {
  input: DeleteAccessTokenInputType;
};


export type MutationDeleteBulkUserWorkingHoursArgs = {
  input: BatchWorkingHoursDeleteInputType;
};


export type MutationDeleteChangeSourceArgs = {
  input: DeleteChangeSourceMutationInput;
};


export type MutationDeleteEnvironmentArgs = {
  input: DeleteEnvironmentMutationInput;
};


export type MutationDeleteGoalArgs = {
  input: GoalDeleteInputType;
};


export type MutationDeleteImpactSourceArgs = {
  input: DeleteImpactSourceMutationInput;
};


export type MutationDeleteIncidentImpactSourceArgs = {
  input: IncidentImpactSourceDeleteInputType;
};


export type MutationDeleteIntegrationAuthenticationArgs = {
  input: DeleteIntegrationAuthenticationMutationInput;
};


export type MutationDeleteLabelArgs = {
  input: DeleteLabelMutationInput;
};


export type MutationDeleteMemberArgs = {
  input: DeleteMemberMutationInput;
};


export type MutationDeletePersonalTokenArgs = {
  tokenId: Scalars['ID']['input'];
};


export type MutationDeleteProjectArgs = {
  input: DeleteProjectMutationInput;
};


export type MutationDeleteTeamArgs = {
  input: DeleteTeamMutationInput;
};


export type MutationEnvironmentArgs = {
  environmentSlug: Scalars['ID']['input'];
  orgSlug: Scalars['ID']['input'];
  projectSlug: Scalars['ID']['input'];
};


export type MutationIgnorePrCheckArgs = {
  input: IgnorePullRequestCheckType;
};


export type MutationInviteMemberArgs = {
  input: InviteMemberMutationInput;
};


export type MutationLinkExternalUsersArgs = {
  input: LinkExternalUserInputType;
};


export type MutationMarkSeenArgs = {
  deploymentSlug?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  orgSlug?: InputMaybe<Scalars['ID']['input']>;
  projectSlug?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationMoveDeploymentArgs = {
  input: MoveDeploymentMutationInput;
};


export type MutationRemoveTeamMembersArgs = {
  input: RemoveTeamMembersMutationInput;
};


export type MutationResendInvitationArgs = {
  input: ResendInvitationMutationInput;
};


export type MutationRevokeInvitationArgs = {
  input: RevokeInvitationMutationInput;
};


export type MutationSetBulkUserWorkingHoursArgs = {
  input: BatchWorkingHoursInputType;
};


export type MutationSetChannelEnvironmentMappingsArgs = {
  input: ChannelEnvironmentMappingInputType;
};


export type MutationSetCurrentUserWorkingHoursArgs = {
  input: WorkingHoursInputType;
};


export type MutationSetDefaultEnvironmentArgs = {
  input: SetDefaultEnvironmentMutationInput;
};


export type MutationSetHealthArgs = {
  deploySlug: Scalars['ID']['input'];
  deploymentSlug: Scalars['ID']['input'];
  health: Scalars['String']['input'];
  orgSlug: Scalars['ID']['input'];
};


export type MutationSetOrganizationWorkingHoursArgs = {
  input: WorkingHoursInputType;
};


export type MutationSetProjectInSessionArgs = {
  orgSlug: Scalars['ID']['input'];
  projectSlug: Scalars['ID']['input'];
};


export type MutationSetTeamInSessionArgs = {
  orgSlug: Scalars['ID']['input'];
  teamSlug: Scalars['ID']['input'];
};


export type MutationSetTeamsSyncStatusArgs = {
  input: TeamsSyncInputType;
};


export type MutationSetToolInstallationArgs = {
  input: SetToolInstallationMutationInput;
};


export type MutationTestAndFixDeploymentConnectionArgs = {
  deploymentSlug: Scalars['ID']['input'];
  orgSlug: Scalars['ID']['input'];
  projectSlug: Scalars['ID']['input'];
};


export type MutationTestAndFixImpactSourceConnectionArgs = {
  impactSourceSlug: Scalars['ID']['input'];
  orgSlug: Scalars['ID']['input'];
  projectSlug: Scalars['ID']['input'];
};


export type MutationToggleDeploymentLockArgs = {
  deploymentSlug: Scalars['ID']['input'];
  lock: Scalars['Boolean']['input'];
  orgSlug: Scalars['ID']['input'];
  reason: Scalars['String']['input'];
};


export type MutationToggleProjectLockArgs = {
  lock: Scalars['Boolean']['input'];
  orgSlug: Scalars['ID']['input'];
  projectSlug: Scalars['ID']['input'];
  reason: Scalars['String']['input'];
};


export type MutationTogglePullRequestIgnoredArgs = {
  input: ToggleLinkedPullRequestIgnoredInputType;
};


export type MutationToggleReportNotificationsArgs = {
  input: ToggleNotificationInputType;
};


export type MutationTriggerCodeChangeSourceReInitializationArgs = {
  deploymentSlug: Scalars['ID']['input'];
  environmentSlug?: InputMaybe<Scalars['ID']['input']>;
  orgSlug: Scalars['ID']['input'];
  projectSlug: Scalars['ID']['input'];
};


export type MutationTriggerIssueStatusesSyncArgs = {
  input: TriggerIssueStatusesSyncInputType;
};


export type MutationTriggerRemoteObjectSyncArgs = {
  input: TriggerRemoteObjectSyncInputType;
};


export type MutationTriggerTeamsSyncArgs = {
  input: TriggerTeamsSyncInputType;
};


export type MutationUnlinkExternalUsersArgs = {
  input: LinkExternalUserInputType;
};


export type MutationUnsubscribeArgs = {
  notificationIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  orgSlug: Scalars['ID']['input'];
};


export type MutationUpdateBuildImpactSourceArgs = {
  input: UpdateBuildImpactSourceMutationInput;
};


export type MutationUpdateCodeChangeSourceArgs = {
  input: UpdateCodeChangeSourceMutationInput;
};


export type MutationUpdateEnvironmentArgs = {
  input: UpdateEnvironmentMutationInput;
};


export type MutationUpdateErrorImpactSourceArgs = {
  input: UpdateErrorImpactSourceMutationInput;
};


export type MutationUpdateFeatureFlagChangeSourceArgs = {
  input: UpdateFeatureFlagChangeSourceMutationInput;
};


export type MutationUpdateGitlabIntegrationArgs = {
  input: UpdateGitlabIntegrationMutationInput;
};


export type MutationUpdateGoalArgs = {
  input: GoalUpdateInputType;
};


export type MutationUpdateIncidentImpactSourceArgs = {
  input: IncidentImpactSourceInputUpdateType;
};


export type MutationUpdateLabelArgs = {
  input: UpdateLabelMutationInput;
};


export type MutationUpdateMetricImpactSourceArgs = {
  input: UpdateMetricImpactSourceMutationInput;
};


export type MutationUpdateOrganizationArgs = {
  input: UpdateOrganizationInputType;
  orgSlug: Scalars['ID']['input'];
};


export type MutationUpdatePagerdutyImpactSourceArgs = {
  input: UpdatePagerDutyImpactSourceMutationInput;
};


export type MutationUpdateProjectArgs = {
  input: UpdateProjectMutationInput;
};


export type MutationUpdateTeamArgs = {
  input: UpdateTeamMutationInput;
};


export type MutationUpdateUserArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
};


export type MutationValidateRulesArgs = {
  deploymentSlug?: InputMaybe<Scalars['ID']['input']>;
  file?: InputMaybe<Scalars['Upload']['input']>;
  orgSlug: Scalars['ID']['input'];
  projectSlug?: InputMaybe<Scalars['ID']['input']>;
  rules?: InputMaybe<Scalars['String']['input']>;
  teamSlug?: InputMaybe<Scalars['ID']['input']>;
};

export type NameAndSlugSearchOptionType = {
  __typename?: 'NameAndSlugSearchOptionType';
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
};

/** An object with an ID */
export type Node = {
  /** The ID of the object */
  id: Scalars['ID']['output'];
};

export type NotificationModelType = {
  __typename?: 'NotificationModelType';
  deployment?: Maybe<DeploymentFieldType>;
  environment?: Maybe<Environment>;
  extraData?: Maybe<Scalars['JSONString']['output']>;
  id: Scalars['ID']['output'];
  name: NotificationName;
  project?: Maybe<ProjectType>;
  reportDays: Scalars['Int']['output'];
  team?: Maybe<TeamType>;
  type: NotificationType;
};

export enum NotificationName {
  /** Slack channel deploys */
  ChannelDeploy = 'CHANNEL_DEPLOY',
  /** Slack personal deploys */
  PersonalDeploy = 'PERSONAL_DEPLOY',
  /** Slack personal impacts */
  PersonalImpact = 'PERSONAL_IMPACT',
  /** Deploys report */
  ReportDeploys = 'REPORT_DEPLOYS',
  /** Metrics report */
  ReportMetrics = 'REPORT_METRICS',
  /** Organization metrics report */
  ReportOrgMetrics = 'REPORT_ORG_METRICS',
  /** Team metrics report */
  ReportTeamMetrics = 'REPORT_TEAM_METRICS',
  /** Work In Progress report */
  ReportWip = 'REPORT_WIP'
}

export enum NotificationType {
  /** Email */
  Email = 'EMAIL',
  /** Slack */
  Slack = 'SLACK'
}

export type NotificationsConfigType = {
  __typename?: 'NotificationsConfigType';
  emailConfirmedForUser: Scalars['Boolean']['output'];
  notifications: Array<NotificationModelType>;
  slackEnabledForUser: Scalars['Boolean']['output'];
};

export enum OAuthScopeType {
  All = 'ALL',
  /** Register deploy */
  RegisterDeploy = 'REGISTER_DEPLOY'
}

export type OpsgenieIncidentImpactSourceInputType = {
  /** The slug for the integration */
  integrationSlug?: InputMaybe<Scalars['String']['input']>;
  /** Optionally filter by alert tags */
  remoteAlertTags?: InputMaybe<Scalars['String']['input']>;
  /** Optionally filter by incident tags */
  remoteIncidentTags?: InputMaybe<Scalars['String']['input']>;
  /** Monitor states with matching or higher priorities will be considered a failure in Sleuth */
  remotePriorityThreshold?: InputMaybe<PriorityThresholdChoices>;
  /** Only taken into consideration when using OpsGenie Incidents */
  remoteService?: InputMaybe<Scalars['String']['input']>;
  /** Use OpsGenie Alerts instead of Incidents */
  remoteUseAlerts?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OpsgenieProviderData = {
  __typename?: 'OpsgenieProviderData';
  remoteAlertTags?: Maybe<Scalars['String']['output']>;
  remoteIncidentTags?: Maybe<Scalars['String']['output']>;
  remotePriorityThreshold?: Maybe<PriorityThresholdChoices>;
  remoteService?: Maybe<Scalars['String']['output']>;
  remoteUseAlerts?: Maybe<Scalars['Boolean']['output']>;
};

export type OpsgenieRemoteService = {
  __typename?: 'OpsgenieRemoteService';
  label: Scalars['String']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type OpsgenieRemoteTag = {
  __typename?: 'OpsgenieRemoteTag';
  label: Scalars['String']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export enum OpsgenieRemoteTagSourceType {
  Alerts = 'ALERTS',
  Incidents = 'INCIDENTS'
}

export type OrderByType = {
  field: OrderField;
  sort?: InputMaybe<OrderDir>;
};

export enum OrderDir {
  Asc = 'asc',
  Desc = 'desc'
}

export enum OrderField {
  ChangeLeadTime = 'change_lead_time',
  CodingTime = 'coding_time',
  DeployingTime = 'deploying_time',
  HealthTime = 'health_time',
  ReviewLagTime = 'review_lag_time',
  ReviewTime = 'review_time'
}

export type OrgAllowedLoginMethodType = {
  __typename?: 'OrgAllowedLoginMethodType';
  enabled: Scalars['Boolean']['output'];
  provider?: Maybe<SocialLoginProviderType>;
  type: AllowedLoginMethodType;
};

export type OrgMetricFailureRateType = {
  __typename?: 'OrgMetricFailureRateType';
  numOfAilingDeploys: Scalars['Int']['output'];
  numOfHealthyDeploys: Scalars['Int']['output'];
  numOfIncidentDeploys: Scalars['Int']['output'];
  numOfRolledBackDeploys: Scalars['Int']['output'];
  numOfUnhealthyDeploys: Scalars['Int']['output'];
};

export type OrgMetricLeadTimeType = {
  __typename?: 'OrgMetricLeadTimeType';
  cltTotal: Scalars['Int']['output'];
  /** Time spent between first commit or first issue transition and a pull request being opened */
  codingTime: Scalars['Int']['output'];
  /** Time spent between PR merge and deploy */
  deployingTime: Scalars['Int']['output'];
  /** Time spent between a pull request being opened and the first review */
  reviewLagTime: Scalars['Int']['output'];
  /** Time spent between first review and merge */
  reviewTime: Scalars['Int']['output'];
};

export type OrgMetricMttrType = {
  __typename?: 'OrgMetricMTTRType';
  ailingDurationInSec: Scalars['Int']['output'];
  incidentsDurationInSec: Scalars['Int']['output'];
  rolledBackDurationInSec: Scalars['Int']['output'];
  unhealthyDurationInSec: Scalars['Int']['output'];
};

export type OrgMetricsNotificationInputType = {
  /** Only the following cadences are supported: BI_WEEKLY, MONTHLY, QUARTERLY, WEEKLY */
  cadence: ReportCadence;
  environmentName?: InputMaybe<Scalars['String']['input']>;
  labelSlugs?: InputMaybe<Array<Scalars['ID']['input']>>;
  orgSlug: Scalars['ID']['input'];
  /** If no projects and no labels are provided, we will report about all projects in this organization */
  projectSlugs?: InputMaybe<Array<Scalars['ID']['input']>>;
  teamSlugs?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type OrganizationType = {
  __typename?: 'OrganizationType';
  accessTokens: AccessTokenConnection;
  addressCity?: Maybe<Scalars['String']['output']>;
  addressCountry?: Maybe<Scalars['String']['output']>;
  addressLine1?: Maybe<Scalars['String']['output']>;
  addressLine2?: Maybe<Scalars['String']['output']>;
  addressPostalCode?: Maybe<Scalars['String']['output']>;
  addressState?: Maybe<Scalars['String']['output']>;
  allowedLoginMethods: Array<OrgAllowedLoginMethodType>;
  /** @deprecated Use `accessTokens` field instead. */
  apiKey: Scalars['String']['output'];
  /** EXPERIMENTAL FIELD, can change at any time. */
  brokenConfigurations: BrokenConfigurationConnection;
  buildDefinitions: SyncedBuildDefinitionsConnection;
  contributorCount: Scalars['Int']['output'];
  /**
   * Returns a number of free contributor seats left.
   *             Returns -1 when plan has no contributor seats limit.
   */
  contributorSeatsLeft: Scalars['Int']['output'];
  /** Default role to assign to users signing up with SSO. Only supports MEMBER, DEV and ADMIN. */
  defaultRole: Role;
  description: Scalars['String']['output'];
  emailDomain?: Maybe<Scalars['String']['output']>;
  enterprise: Scalars['Boolean']['output'];
  externalIssueProjects: ExternalIssueProjectsConnection;
  externalUsers: ExternalUsersConnection;
  flagEnabled: Scalars['Boolean']['output'];
  free: Scalars['Boolean']['output'];
  goals: GoalsConnection;
  hasFeature: Scalars['Boolean']['output'];
  hasImpactHistory: Scalars['Boolean']['output'];
  isActive: Scalars['Boolean']['output'];
  issueBreakdown: IssueBreakdownConnection;
  issueBreakdownIssues: IssueBreakdownIssuesConnection;
  issueStates: IssueStatesConnection;
  metricFailureRate: OrgMetricFailureRateType;
  metricFrequency: DeploySizesType;
  metricLeadTime: OrgMetricLeadTimeType;
  metricMttr: OrgMetricMttrType;
  metricsRecap: RecapType;
  metricsRecapList: Array<RecapType>;
  metricsRecapWithQuarters: RecapWithQuartersType;
  name: Scalars['String']['output'];
  owner: UserType;
  pendingInvites: InvitationConnection;
  projects: ProjectsConnection;
  remoteFeatureFlagProjects: Array<RemoteFeatureFlagProject>;
  slug: Scalars['String']['output'];
  syncStatuses: SyncStatusesConnection;
  teams: TeamsConnection;
  teamsSyncStatuses: Array<TeamsSyncStatusType>;
  trial: Scalars['Boolean']['output'];
  trialDaysRemaining: Scalars['Int']['output'];
  uniqueEnvironments: EnvironmentsNamesConnection;
  url: Scalars['String']['output'];
  user?: Maybe<UserType>;
  users: UserPaginatedType;
  wipItems: WorkInProgressItemsConnection;
  wipRecap: WipRecapType;
  wipSummary: WipSummaryType;
  /** EXPERIMENTAL FIELD, can change at any time. */
  workingHours?: Maybe<WorkingHoursType>;
};


export type OrganizationTypeAccessTokensArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type OrganizationTypeBrokenConfigurationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type OrganizationTypeBuildDefinitionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  branch?: InputMaybe<Scalars['String']['input']>;
  deploymentSlug?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  integrationAuthSlug?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  provider: BuildProviderType;
  term?: InputMaybe<Scalars['String']['input']>;
};


export type OrganizationTypeExternalIssueProjectsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  integrationAuthSlug?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  provider: Scalars['String']['input'];
  term?: InputMaybe<Scalars['String']['input']>;
};


export type OrganizationTypeExternalUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  excludeLinked?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  term?: InputMaybe<Scalars['String']['input']>;
};


export type OrganizationTypeFlagEnabledArgs = {
  flagName: Scalars['String']['input'];
};


export type OrganizationTypeGoalsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  codeChangeSourceSlug?: InputMaybe<Scalars['ID']['input']>;
  environmentName?: InputMaybe<Scalars['String']['input']>;
  environmentSlug?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  metric?: InputMaybe<GoalMetricType>;
  projectSlug?: InputMaybe<Scalars['ID']['input']>;
  teamSlug?: InputMaybe<Scalars['ID']['input']>;
};


export type OrganizationTypeHasFeatureArgs = {
  feature: Feature;
};


export type OrganizationTypeHasImpactHistoryArgs = {
  environmentName?: InputMaybe<Scalars['String']['input']>;
};


export type OrganizationTypeIssueBreakdownArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  breakdownPage: IssueBreakdownPage;
  breakdownType: IssueBreakdownType;
  end: Scalars['DateTime']['input'];
  filters: BreakdownFiltersInputType;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  start: Scalars['DateTime']['input'];
};


export type OrganizationTypeIssueBreakdownIssuesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  breakdownPage: IssueBreakdownPage;
  end: Scalars['DateTime']['input'];
  filters: BreakdownFiltersInputType;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  start: Scalars['DateTime']['input'];
};


export type OrganizationTypeIssueStatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  externalProjectIds?: InputMaybe<Array<Scalars['String']['input']>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  integrationAuthSlug?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  provider: Scalars['String']['input'];
  term?: InputMaybe<Scalars['String']['input']>;
};


export type OrganizationTypeMetricFailureRateArgs = {
  end: Scalars['DateTime']['input'];
  filters: MetricRecapFilterArguments;
  start: Scalars['DateTime']['input'];
};


export type OrganizationTypeMetricFrequencyArgs = {
  end: Scalars['DateTime']['input'];
  filters: MetricRecapFilterArguments;
  start: Scalars['DateTime']['input'];
};


export type OrganizationTypeMetricLeadTimeArgs = {
  end: Scalars['DateTime']['input'];
  filters: MetricRecapFilterArguments;
  start: Scalars['DateTime']['input'];
};


export type OrganizationTypeMetricMttrArgs = {
  end: Scalars['DateTime']['input'];
  filters: MetricRecapFilterArguments;
  start: Scalars['DateTime']['input'];
};


export type OrganizationTypeMetricsRecapArgs = {
  end: Scalars['DateTime']['input'];
  filters: MetricRecapFilterArguments;
  start: Scalars['DateTime']['input'];
};


export type OrganizationTypeMetricsRecapListArgs = {
  end: Scalars['DateTime']['input'];
  filtersList: Array<InputMaybe<MetricRecapFilterArguments>>;
  start: Scalars['DateTime']['input'];
};


export type OrganizationTypeMetricsRecapWithQuartersArgs = {
  end: Scalars['DateTime']['input'];
  filters: MetricRecapFilterArguments;
  start: Scalars['DateTime']['input'];
};


export type OrganizationTypePendingInvitesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type OrganizationTypeProjectsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  slug?: InputMaybe<Scalars['ID']['input']>;
  term?: InputMaybe<Scalars['String']['input']>;
};


export type OrganizationTypeRemoteFeatureFlagProjectsArgs = {
  provider: FlagProvider;
};


export type OrganizationTypeSyncStatusesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  integrationAuthSlug?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  provider?: InputMaybe<Provider>;
  remoteObjectType: RemoteObjectSyncType;
};


export type OrganizationTypeTeamsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  childless?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  parent?: InputMaybe<TeamParentInputType>;
  slug?: InputMaybe<Scalars['String']['input']>;
  term?: InputMaybe<Scalars['String']['input']>;
};


export type OrganizationTypeUniqueEnvironmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type OrganizationTypeUserArgs = {
  userId: Scalars['ID']['input'];
};


export type OrganizationTypeUsersArgs = {
  emails?: InputMaybe<Array<Scalars['String']['input']>>;
  excludeTeamSlug?: InputMaybe<Scalars['ID']['input']>;
  page: Scalars['Int']['input'];
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  term: Scalars['String']['input'];
  userRole?: InputMaybe<Role>;
  userType?: InputMaybe<UsersType>;
};


export type OrganizationTypeWipItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  ctxFilters?: InputMaybe<WipContextFilterArguments>;
  filterBy?: InputMaybe<WipItemsFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<WipItemsSort>;
  start: Scalars['DateTime']['input'];
};


export type OrganizationTypeWipRecapArgs = {
  ctxFilters?: InputMaybe<WipContextFilterArguments>;
  start: Scalars['DateTime']['input'];
};


export type OrganizationTypeWipSummaryArgs = {
  ctxFilters?: InputMaybe<WipContextFilterArguments>;
  start: Scalars['DateTime']['input'];
};

export type OriginalHealthSpanConnection = {
  __typename?: 'OriginalHealthSpanConnection';
  edges: Array<OriginalHealthSpanTypeEdge>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of edges. */
  totalCount: Scalars['Int']['output'];
};

export type OriginalHealthSpanType = Node & {
  __typename?: 'OriginalHealthSpanType';
  /** The non-inclusive end datetime */
  end?: Maybe<Scalars['DateTime']['output']>;
  health: DeployHealthType;
  historyType: HealthSpanType;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  identificationData?: Maybe<Scalars['String']['output']>;
  impactMeasuredOn?: Maybe<Scalars['DateTime']['output']>;
  impactUrl?: Maybe<Scalars['String']['output']>;
  impactValue?: Maybe<Scalars['Float']['output']>;
  impactValueChange?: Maybe<Scalars['Float']['output']>;
  sourceName?: Maybe<Scalars['String']['output']>;
  sourceProvider?: Maybe<ImpactProvider>;
  sourceType?: Maybe<ImpactType>;
  /** The inclusive start datetime */
  start: Scalars['DateTime']['output'];
};

export type OriginalHealthSpanTypeEdge = {
  __typename?: 'OriginalHealthSpanTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: OriginalHealthSpanType;
};

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type PagerDutyIncidentImpactSource = {
  __typename?: 'PagerDutyIncidentImpactSource';
  environment: Environment;
  incidentSpans: OriginalHealthSpanConnection;
  latestImpacts: Array<LinkedImpactType>;
  name: Scalars['String']['output'];
  ostracizedOn?: Maybe<Scalars['DateTime']['output']>;
  provider: Scalars['String']['output'];
  providerData?: Maybe<ProviderDataFieldType>;
  /** PagerDuty service ID, empty means all */
  remoteServices?: Maybe<Scalars['String']['output']>;
  /** PagerDuty incident urgency */
  remoteUrgency?: Maybe<PagerDutyUrgencyOptions>;
  slug: Scalars['String']['output'];
  type: ImpactType;
};


export type PagerDutyIncidentImpactSourceIncidentSpansArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  end: Scalars['DateTime']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  start: Scalars['DateTime']['input'];
};

export type PagerDutyInputType = {
  /** Populate with data from the last 30 days */
  historicInit?: InputMaybe<Scalars['Boolean']['input']>;
  integrationSlug?: InputMaybe<Scalars['String']['input']>;
  /** PagerDuty service ID, empty means all */
  remoteServices?: InputMaybe<Scalars['String']['input']>;
  /** PagerDuty incident urgency */
  remoteUrgency?: InputMaybe<PagerDutyUrgencyOptions>;
};

export type PagerDutyProviderData = {
  __typename?: 'PagerDutyProviderData';
  remoteServices?: Maybe<Scalars['String']['output']>;
  remoteUrgency?: Maybe<PagerDutyUrgencyOptions>;
};

export enum PagerDutyUrgencyOptions {
  Any = 'ANY',
  /** High */
  High = 'HIGH',
  /** Low */
  Low = 'LOW'
}

export type PagerdutyRemoteService = {
  __typename?: 'PagerdutyRemoteService';
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type Pip = {
  __typename?: 'Pip';
  closingDeploySlug?: Maybe<Scalars['String']['output']>;
  closingDeploymentName?: Maybe<Scalars['String']['output']>;
  closingDeploymentSlug?: Maybe<Scalars['String']['output']>;
  closingDeploymentType?: Maybe<ChangeSourceType>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  health: DeployHealthType;
  measurements: Array<PipImpactMmt>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
};

export type PipImpactMmt = {
  __typename?: 'PipImpactMmt';
  health: DeployHealthType;
  sourceName: Scalars['String']['output'];
  sourceProvider: ImpactProvider;
  sourceSlug?: Maybe<Scalars['String']['output']>;
  sourceType: ImpactType;
  value: Scalars['Float']['output'];
  valueLabel: Scalars['String']['output'];
  valueMeasuredOn: Scalars['DateTime']['output'];
};

export type PrInProgress = WorkInProgressItem & {
  __typename?: 'PrInProgress';
  authors: DisplayAuthorConnection;
  batchSize: BatchSize;
  batchSizeIsRisk?: Maybe<Scalars['Boolean']['output']>;
  breadcrumbs?: Maybe<Array<WorkInProgressItemBreadcrumb>>;
  cltTotal?: Maybe<Scalars['Int']['output']>;
  cltTotalIsRisk?: Maybe<Scalars['Boolean']['output']>;
  codingTime: Scalars['Int']['output'];
  codingTimeIsRisk?: Maybe<Scalars['Boolean']['output']>;
  deployingTime?: Maybe<Scalars['Int']['output']>;
  deployingTimeIsRisk?: Maybe<Scalars['Boolean']['output']>;
  itemUpdatedOn: Scalars['DateTime']['output'];
  pullRequest: LinkedPullRequestType;
  reviewLagTime?: Maybe<Scalars['Int']['output']>;
  reviewLagTimeIsRisk?: Maybe<Scalars['Boolean']['output']>;
  reviewTime?: Maybe<Scalars['Int']['output']>;
  reviewTimeIsRisk?: Maybe<Scalars['Boolean']['output']>;
  riskCount: Scalars['Int']['output'];
};


export type PrInProgressAuthorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** An enumeration. */
export enum PriorityThresholdChoices {
  All = 'ALL',
  P1 = 'P1',
  P2 = 'P2',
  P3 = 'P3',
  P4 = 'P4',
  P5 = 'P5'
}

/** Where to find issues linked to by changes */
export enum ProjectIssueTrackerProvider {
  /** Issues tracked by a Shortcut project */
  Clubhouse = 'CLUBHOUSE',
  /** Issues tracked by a Jira Cloud project */
  Jira = 'JIRA',
  /** Issues tracked by a Jira Data Center project */
  JiraDatacenter = 'JIRA_DATACENTER',
  /** Issues tracked by a Linear workspace */
  Linear = 'LINEAR',
  /** Don't track issues for each deploy */
  None = 'NONE',
  /** Issues tracked by your source code repository provider */
  SourceProvider = 'SOURCE_PROVIDER'
}

export type ProjectLabelType = {
  __typename?: 'ProjectLabelType';
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
};

export type ProjectLabelTypeEdge = {
  __typename?: 'ProjectLabelTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: ProjectLabelType;
};

/** A Project in Sleuth. */
export type ProjectRelayType = {
  __typename?: 'ProjectRelayType';
  buildIntegrationSlug?: Maybe<Scalars['String']['output']>;
  buildProvider?: Maybe<TrackBuildProviderType>;
  changeFailureRateBoundary: DeployHealthType;
  changeSources: ChangeSourceConnection;
  channelEnvironmentMappings: Array<ChannelEnvironmentMappingType>;
  cltStartDefinition: CltStartDefinitionType;
  cltStartStates: Array<IssueStateType>;
  defaultEnvironment: Environment;
  description?: Maybe<Scalars['String']['output']>;
  detectRollbacks: Scalars['Boolean']['output'];
  environment?: Maybe<Environment>;
  environments: EnvironmentConnection;
  failureSensitivity?: Maybe<Scalars['Int']['output']>;
  hasImpactHistory: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  /** EXPERIMENTAL FIELD, can change at any time. */
  impactErrorProjects: ImpactErrorProjectConnection;
  impactSensitivity: ImpactSensitivity;
  impactSources: ImpactSourceConnection;
  insights: Array<InsightUnionType>;
  installedTools: InstalledToolsConnection;
  issueIntegrationSlug?: Maybe<Scalars['String']['output']>;
  issueTrackerProvider: ProjectIssueTrackerProvider;
  labels: LabelConnection;
  latestDeploySlugs: Array<Scalars['String']['output']>;
  lockable: Scalars['Boolean']['output'];
  locked: Scalars['Boolean']['output'];
  metricBiggestDeploys: Array<ChangeType>;
  metricFailureRate: MetricFailureRateChartType;
  metricFrequency: MetricFrequencyChartType;
  metricLeadTime: MetricLeadTimeChartType;
  metricMttr: MetricMttrChartType;
  metricRecap: RecapWithContributingTeams;
  metricSlowestPrs: Array<LinkedPullRequestType>;
  metricTopUnhealthyDeploys: Array<ChangeType>;
  name: Scalars['String']['output'];
  org: OrganizationType;
  /** List of health pips, each one 3 minutes long. */
  pips: Array<Pip>;
  slug: Scalars['String']['output'];
  tour: Scalars['Boolean']['output'];
  tourEnvironmentSlug: Scalars['String']['output'];
  tourOrgSlug: Scalars['String']['output'];
  tourProjectSlug: Scalars['String']['output'];
};


/** A Project in Sleuth. */
export type ProjectRelayTypeChangeSourcesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  changeSourceType?: InputMaybe<ChangeSourceType>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeInDashboard?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locked?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  term?: InputMaybe<Scalars['String']['input']>;
};


/** A Project in Sleuth. */
export type ProjectRelayTypeEnvironmentArgs = {
  environmentSlug: Scalars['ID']['input'];
};


/** A Project in Sleuth. */
export type ProjectRelayTypeEnvironmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  term?: InputMaybe<Scalars['String']['input']>;
};


/** A Project in Sleuth. */
export type ProjectRelayTypeHasImpactHistoryArgs = {
  environmentSlug?: InputMaybe<Scalars['ID']['input']>;
};


/** A Project in Sleuth. */
export type ProjectRelayTypeImpactErrorProjectsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  integrationAuthSlug?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  provider: ErrorImpactSourceProvider;
};


/** A Project in Sleuth. */
export type ProjectRelayTypeImpactSourcesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  environmentSlug?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  impactSourceType?: InputMaybe<ImpactType>;
  last?: InputMaybe<Scalars['Int']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  term?: InputMaybe<Scalars['String']['input']>;
};


/** A Project in Sleuth. */
export type ProjectRelayTypeInsightsArgs = {
  deploymentSlugs?: InputMaybe<Array<Scalars['ID']['input']>>;
  end?: InputMaybe<Scalars['DateTime']['input']>;
  environmentSlug: Scalars['ID']['input'];
  start?: InputMaybe<Scalars['DateTime']['input']>;
  teamSlug?: InputMaybe<Scalars['ID']['input']>;
};


/** A Project in Sleuth. */
export type ProjectRelayTypeInstalledToolsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  toolSlug?: InputMaybe<Scalars['ID']['input']>;
};


/** A Project in Sleuth. */
export type ProjectRelayTypeLabelsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** A Project in Sleuth. */
export type ProjectRelayTypeLatestDeploySlugsArgs = {
  envSlug?: InputMaybe<Scalars['ID']['input']>;
};


/** A Project in Sleuth. */
export type ProjectRelayTypeMetricBiggestDeploysArgs = {
  deploymentSlugs?: InputMaybe<Array<Scalars['ID']['input']>>;
  end?: InputMaybe<Scalars['DateTime']['input']>;
  environmentSlug: Scalars['ID']['input'];
  start?: InputMaybe<Scalars['DateTime']['input']>;
  teamSlug?: InputMaybe<Scalars['ID']['input']>;
};


/** A Project in Sleuth. */
export type ProjectRelayTypeMetricFailureRateArgs = {
  deploymentSlugs?: InputMaybe<Array<Scalars['ID']['input']>>;
  end?: InputMaybe<Scalars['DateTime']['input']>;
  environmentSlug: Scalars['ID']['input'];
  start?: InputMaybe<Scalars['DateTime']['input']>;
  teamSlug?: InputMaybe<Scalars['ID']['input']>;
};


/** A Project in Sleuth. */
export type ProjectRelayTypeMetricFrequencyArgs = {
  deploymentSlugs?: InputMaybe<Array<Scalars['ID']['input']>>;
  end?: InputMaybe<Scalars['DateTime']['input']>;
  environmentSlug: Scalars['ID']['input'];
  start?: InputMaybe<Scalars['DateTime']['input']>;
  teamSlug?: InputMaybe<Scalars['ID']['input']>;
};


/** A Project in Sleuth. */
export type ProjectRelayTypeMetricLeadTimeArgs = {
  deploymentSlugs?: InputMaybe<Array<Scalars['ID']['input']>>;
  end?: InputMaybe<Scalars['DateTime']['input']>;
  environmentSlug: Scalars['ID']['input'];
  leadTimeBucket?: InputMaybe<CycleBucketType>;
  start?: InputMaybe<Scalars['DateTime']['input']>;
  teamSlug?: InputMaybe<Scalars['ID']['input']>;
};


/** A Project in Sleuth. */
export type ProjectRelayTypeMetricMttrArgs = {
  end?: InputMaybe<Scalars['DateTime']['input']>;
  environmentSlug: Scalars['ID']['input'];
  start?: InputMaybe<Scalars['DateTime']['input']>;
  teamSlug?: InputMaybe<Scalars['ID']['input']>;
};


/** A Project in Sleuth. */
export type ProjectRelayTypeMetricRecapArgs = {
  deploymentSlugs?: InputMaybe<Array<Scalars['ID']['input']>>;
  end?: InputMaybe<Scalars['DateTime']['input']>;
  environmentSlug: Scalars['ID']['input'];
  start?: InputMaybe<Scalars['DateTime']['input']>;
  teamSlug?: InputMaybe<Scalars['ID']['input']>;
  teamsParent?: InputMaybe<TeamParentInputType>;
};


/** A Project in Sleuth. */
export type ProjectRelayTypeMetricSlowestPrsArgs = {
  deploymentSlugs?: InputMaybe<Array<Scalars['ID']['input']>>;
  end?: InputMaybe<Scalars['DateTime']['input']>;
  environmentSlug: Scalars['ID']['input'];
  metricType: CycleBucketType;
  start?: InputMaybe<Scalars['DateTime']['input']>;
  teamSlug?: InputMaybe<Scalars['ID']['input']>;
};


/** A Project in Sleuth. */
export type ProjectRelayTypeMetricTopUnhealthyDeploysArgs = {
  deploymentSlugs?: InputMaybe<Array<Scalars['ID']['input']>>;
  end?: InputMaybe<Scalars['DateTime']['input']>;
  environmentSlug: Scalars['ID']['input'];
  start?: InputMaybe<Scalars['DateTime']['input']>;
  teamSlug?: InputMaybe<Scalars['ID']['input']>;
};


/** A Project in Sleuth. */
export type ProjectRelayTypePipsArgs = {
  environmentSlug: Scalars['ID']['input'];
  limit: Scalars['Int']['input'];
};

export type ProjectRelayTypeEdge = {
  __typename?: 'ProjectRelayTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: ProjectRelayType;
};

export type ProjectType = {
  __typename?: 'ProjectType';
  buildIntegrationSlug?: Maybe<Scalars['String']['output']>;
  buildProvider?: Maybe<TrackBuildProviderType>;
  changeFailureRateBoundary: DeployHealthType;
  changeSources: Array<ChangeSource>;
  channelEnvironmentMappings: Array<ChannelEnvironmentMappingType>;
  cltStartDefinition: CltStartDefinitionType;
  cltStartStates: Array<IssueStateType>;
  defaultEnvironment: Environment;
  description?: Maybe<Scalars['String']['output']>;
  detectRollbacks: Scalars['Boolean']['output'];
  environment?: Maybe<Environment>;
  environments: Array<Environment>;
  failureSensitivity?: Maybe<Scalars['Int']['output']>;
  hasImpactHistory: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  impactSensitivity: ImpactSensitivity;
  impactSources: Array<ImpactSourceInterfaceType>;
  insights: Array<InsightUnionType>;
  installedTools: InstalledToolsConnection;
  issueIntegrationSlug?: Maybe<Scalars['String']['output']>;
  issueTrackerProvider: ProjectIssueTrackerProvider;
  labels: LabelConnection;
  latestDeploySlugs: Array<Scalars['String']['output']>;
  lockable: Scalars['Boolean']['output'];
  locked: Scalars['Boolean']['output'];
  metricBiggestDeploys: Array<ChangeType>;
  metricFailureRate: MetricFailureRateChartType;
  metricFrequency: MetricFrequencyChartType;
  metricLeadTime: MetricLeadTimeChartType;
  metricMttr: MetricMttrChartType;
  metricRecap: RecapWithContributingTeams;
  metricSlowestPrs: Array<LinkedPullRequestType>;
  metricTopUnhealthyDeploys: Array<ChangeType>;
  name: Scalars['String']['output'];
  org: OrganizationType;
  /** List of health pips, each one 3 minutes long. */
  pips: Array<Pip>;
  slug: Scalars['String']['output'];
  strictIssueMatching: Scalars['Boolean']['output'];
  tour: Scalars['Boolean']['output'];
  tourEnvironmentSlug: Scalars['String']['output'];
  tourOrgSlug: Scalars['String']['output'];
  tourProjectSlug: Scalars['String']['output'];
};


export type ProjectTypeChangeSourcesArgs = {
  includeInDashboard?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<ChangeSourceType>;
};


export type ProjectTypeEnvironmentArgs = {
  environmentSlug: Scalars['ID']['input'];
};


export type ProjectTypeHasImpactHistoryArgs = {
  environmentSlug?: InputMaybe<Scalars['ID']['input']>;
};


export type ProjectTypeImpactSourcesArgs = {
  environmentSlug?: InputMaybe<Scalars['ID']['input']>;
  impactSourceSlug?: InputMaybe<Scalars['ID']['input']>;
};


export type ProjectTypeInsightsArgs = {
  deploymentSlugs?: InputMaybe<Array<Scalars['ID']['input']>>;
  end?: InputMaybe<Scalars['DateTime']['input']>;
  environmentSlug: Scalars['ID']['input'];
  start?: InputMaybe<Scalars['DateTime']['input']>;
  teamSlug?: InputMaybe<Scalars['ID']['input']>;
};


export type ProjectTypeInstalledToolsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  toolSlug?: InputMaybe<Scalars['ID']['input']>;
};


export type ProjectTypeLabelsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type ProjectTypeLatestDeploySlugsArgs = {
  envSlug?: InputMaybe<Scalars['ID']['input']>;
};


export type ProjectTypeMetricBiggestDeploysArgs = {
  deploymentSlugs?: InputMaybe<Array<Scalars['ID']['input']>>;
  end?: InputMaybe<Scalars['DateTime']['input']>;
  environmentSlug: Scalars['ID']['input'];
  start?: InputMaybe<Scalars['DateTime']['input']>;
  teamSlug?: InputMaybe<Scalars['ID']['input']>;
};


export type ProjectTypeMetricFailureRateArgs = {
  deploymentSlugs?: InputMaybe<Array<Scalars['ID']['input']>>;
  end?: InputMaybe<Scalars['DateTime']['input']>;
  environmentSlug: Scalars['ID']['input'];
  start?: InputMaybe<Scalars['DateTime']['input']>;
  teamSlug?: InputMaybe<Scalars['ID']['input']>;
};


export type ProjectTypeMetricFrequencyArgs = {
  deploymentSlugs?: InputMaybe<Array<Scalars['ID']['input']>>;
  end?: InputMaybe<Scalars['DateTime']['input']>;
  environmentSlug: Scalars['ID']['input'];
  start?: InputMaybe<Scalars['DateTime']['input']>;
  teamSlug?: InputMaybe<Scalars['ID']['input']>;
};


export type ProjectTypeMetricLeadTimeArgs = {
  deploymentSlugs?: InputMaybe<Array<Scalars['ID']['input']>>;
  end?: InputMaybe<Scalars['DateTime']['input']>;
  environmentSlug: Scalars['ID']['input'];
  leadTimeBucket?: InputMaybe<CycleBucketType>;
  start?: InputMaybe<Scalars['DateTime']['input']>;
  teamSlug?: InputMaybe<Scalars['ID']['input']>;
};


export type ProjectTypeMetricMttrArgs = {
  end?: InputMaybe<Scalars['DateTime']['input']>;
  environmentSlug: Scalars['ID']['input'];
  start?: InputMaybe<Scalars['DateTime']['input']>;
  teamSlug?: InputMaybe<Scalars['ID']['input']>;
};


export type ProjectTypeMetricRecapArgs = {
  deploymentSlugs?: InputMaybe<Array<Scalars['ID']['input']>>;
  end?: InputMaybe<Scalars['DateTime']['input']>;
  environmentSlug: Scalars['ID']['input'];
  start?: InputMaybe<Scalars['DateTime']['input']>;
  teamSlug?: InputMaybe<Scalars['ID']['input']>;
  teamsParent?: InputMaybe<TeamParentInputType>;
};


export type ProjectTypeMetricSlowestPrsArgs = {
  deploymentSlugs?: InputMaybe<Array<Scalars['ID']['input']>>;
  end?: InputMaybe<Scalars['DateTime']['input']>;
  environmentSlug: Scalars['ID']['input'];
  metricType: CycleBucketType;
  start?: InputMaybe<Scalars['DateTime']['input']>;
  teamSlug?: InputMaybe<Scalars['ID']['input']>;
};


export type ProjectTypeMetricTopUnhealthyDeploysArgs = {
  deploymentSlugs?: InputMaybe<Array<Scalars['ID']['input']>>;
  end?: InputMaybe<Scalars['DateTime']['input']>;
  environmentSlug: Scalars['ID']['input'];
  start?: InputMaybe<Scalars['DateTime']['input']>;
  teamSlug?: InputMaybe<Scalars['ID']['input']>;
};


export type ProjectTypePipsArgs = {
  environmentSlug: Scalars['ID']['input'];
  limit: Scalars['Int']['input'];
};

/** Paginated connection for Projects. */
export type ProjectsConnection = {
  __typename?: 'ProjectsConnection';
  edges: Array<ProjectRelayTypeEdge>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of edges. */
  totalCount: Scalars['Int']['output'];
};

export enum Provider {
  /** AppDynamics */
  Appdynamics = 'APPDYNAMICS',
  /** AWS */
  Aws = 'AWS',
  /** Azure DevOps */
  Azure = 'AZURE',
  /** Bitbucket */
  Bitbucket = 'BITBUCKET',
  /** Bitbucket Pipelines */
  BitbucketPipelines = 'BITBUCKET_PIPELINES',
  /** Blameless */
  Blameless = 'BLAMELESS',
  /** Bugsnag */
  Bugsnag = 'BUGSNAG',
  /** Buildkite */
  Buildkite = 'BUILDKITE',
  /** CircleCI */
  Circleci = 'CIRCLECI',
  /** CloudWatch */
  Cloudwatch = 'CLOUDWATCH',
  /** Shortcut */
  Clubhouse = 'CLUBHOUSE',
  /** CodeDeploy */
  Codedeploy = 'CODEDEPLOY',
  /** Custom - any git repository */
  CustomGit = 'CUSTOM_GIT',
  /** Custom Incident - user provided */
  CustomIncident = 'CUSTOM_INCIDENT',
  /** Custom Metric - user provided */
  CustomMetric = 'CUSTOM_METRIC',
  /** Datadog */
  Datadog = 'DATADOG',
  /** Fake Build */
  Fakebuild = 'FAKEBUILD',
  /** Fake Flag */
  Fakeflag = 'FAKEFLAG',
  /** Fake Git */
  Fakegit = 'FAKEGIT',
  /** FireHydrant */
  Firehydrant = 'FIREHYDRANT',
  /** GitHub */
  Github = 'GITHUB',
  /** Sleuth Automations for GitHub */
  GithubAutomations = 'GITHUB_AUTOMATIONS',
  /** GitHub Enterprise Server */
  GithubEnterprise = 'GITHUB_ENTERPRISE',
  /** GitLab */
  Gitlab = 'GITLAB',
  /** Google */
  Google = 'GOOGLE',
  /** Honeybadger */
  Honeybadger = 'HONEYBADGER',
  /** Jenkins */
  Jenkins = 'JENKINS',
  /** Jira Cloud */
  Jira = 'JIRA',
  /** Jira Data Center */
  JiraDatacenter = 'JIRA_DATACENTER',
  /** LaunchDarkly */
  Launchdarkly = 'LAUNCHDARKLY',
  /** Linear */
  Linear = 'LINEAR',
  /** Microsoft */
  Microsoft = 'MICROSOFT',
  /** Microsoft Teams */
  MicrosoftTeams = 'MICROSOFT_TEAMS',
  /** New Relic */
  Newrelic = 'NEWRELIC',
  /** Opsgenie */
  Opsgenie = 'OPSGENIE',
  /** PagerDuty */
  Pagerduty = 'PAGERDUTY',
  /** Rollbar */
  Rollbar = 'ROLLBAR',
  /** Rootly */
  Rootly = 'ROOTLY',
  /** SAML */
  Saml = 'SAML',
  /** Sentry Cloud */
  Sentry = 'SENTRY',
  /** Sentry Self-Hosted */
  SentrySelfHosted = 'SENTRY_SELF_HOSTED',
  /** ServiceNow */
  Servicenow = 'SERVICENOW',
  /** Splunk Observability Cloud */
  Signalfx = 'SIGNALFX',
  /** Slack */
  Slack = 'SLACK',
  /** Statuspage */
  Statuspage = 'STATUSPAGE'
}

export enum ProviderCategory {
  Automation = 'AUTOMATION',
  Chat = 'CHAT',
  CiCd = 'CI_CD',
  Code = 'CODE',
  Error = 'ERROR',
  Flag = 'FLAG',
  Incident = 'INCIDENT',
  Issue = 'ISSUE',
  Metric = 'METRIC'
}

export type ProviderDataFieldType = BlamelessProviderData | ClubhouseProviderData | DataDogProviderData | FireHydrantProviderData | JiraDatacenterProviderData | JiraProviderData | OpsgenieProviderData | PagerDutyProviderData | RootlyProviderData | ServiceNowProviderData | StatuspageProviderData;

export type ProviderExternalDependencyType = {
  __typename?: 'ProviderExternalDependencyType';
  blamelessRemoteSeverities: Array<BlamelessRemoteSeverity>;
  blamelessRemoteTypes: Array<BlamelessRemoteType>;
  firehydrantRemoteEnvironments: Array<FirehydrantRemoteEnvironment>;
  firehydrantRemoteServices: Array<FirehydrantRemoteService>;
  opsgenieRemoteServices: Array<OpsgenieRemoteService>;
  opsgenieRemoteTags: Array<OpsgenieRemoteTag>;
  pagerdutyRemoteServices: Array<PagerdutyRemoteService>;
  rootlyRemoteEnvironments: Array<RootlyRemoteObjectType>;
  rootlyRemoteIncidentTypes: Array<RootlyRemoteObjectType>;
  rootlyRemoteServices: Array<RootlyRemoteObjectType>;
  rootlyRemoteTeams: Array<RootlyRemoteObjectType>;
  servicenowAssignmentGroups: Array<ServicenowAssignmentGroup>;
  servicenowRemoteBusinessServices: Array<ServicenowRemoteBusinessService>;
  statuspageRemoteComponents: Array<StatuspageRemoteComponent>;
  statuspageRemotePages: Array<StatuspageRemotePage>;
};


export type ProviderExternalDependencyTypeBlamelessRemoteSeveritiesArgs = {
  integrationSlug?: InputMaybe<Scalars['ID']['input']>;
  orgSlug: Scalars['ID']['input'];
};


export type ProviderExternalDependencyTypeBlamelessRemoteTypesArgs = {
  integrationSlug?: InputMaybe<Scalars['ID']['input']>;
  orgSlug: Scalars['ID']['input'];
};


export type ProviderExternalDependencyTypeFirehydrantRemoteEnvironmentsArgs = {
  integrationSlug?: InputMaybe<Scalars['ID']['input']>;
  orgSlug: Scalars['ID']['input'];
};


export type ProviderExternalDependencyTypeFirehydrantRemoteServicesArgs = {
  integrationSlug?: InputMaybe<Scalars['ID']['input']>;
  orgSlug: Scalars['ID']['input'];
};


export type ProviderExternalDependencyTypeOpsgenieRemoteServicesArgs = {
  integrationSlug?: InputMaybe<Scalars['ID']['input']>;
  orgSlug: Scalars['ID']['input'];
};


export type ProviderExternalDependencyTypeOpsgenieRemoteTagsArgs = {
  integrationSlug?: InputMaybe<Scalars['ID']['input']>;
  orgSlug: Scalars['ID']['input'];
  sourceType: OpsgenieRemoteTagSourceType;
};


export type ProviderExternalDependencyTypePagerdutyRemoteServicesArgs = {
  integrationSlug?: InputMaybe<Scalars['ID']['input']>;
  orgSlug: Scalars['ID']['input'];
};


export type ProviderExternalDependencyTypeRootlyRemoteEnvironmentsArgs = {
  integrationSlug?: InputMaybe<Scalars['ID']['input']>;
  orgSlug: Scalars['ID']['input'];
};


export type ProviderExternalDependencyTypeRootlyRemoteIncidentTypesArgs = {
  integrationSlug?: InputMaybe<Scalars['ID']['input']>;
  orgSlug: Scalars['ID']['input'];
};


export type ProviderExternalDependencyTypeRootlyRemoteServicesArgs = {
  integrationSlug?: InputMaybe<Scalars['ID']['input']>;
  orgSlug: Scalars['ID']['input'];
};


export type ProviderExternalDependencyTypeRootlyRemoteTeamsArgs = {
  integrationSlug?: InputMaybe<Scalars['ID']['input']>;
  orgSlug: Scalars['ID']['input'];
};


export type ProviderExternalDependencyTypeServicenowAssignmentGroupsArgs = {
  integrationSlug?: InputMaybe<Scalars['ID']['input']>;
  orgSlug: Scalars['ID']['input'];
};


export type ProviderExternalDependencyTypeServicenowRemoteBusinessServicesArgs = {
  integrationSlug?: InputMaybe<Scalars['ID']['input']>;
  orgSlug: Scalars['ID']['input'];
};


export type ProviderExternalDependencyTypeStatuspageRemoteComponentsArgs = {
  integrationSlug?: InputMaybe<Scalars['ID']['input']>;
  orgSlug: Scalars['ID']['input'];
  pages: Array<StatuspageRemoteComponentPageArgument>;
};


export type ProviderExternalDependencyTypeStatuspageRemotePagesArgs = {
  integrationSlug?: InputMaybe<Scalars['ID']['input']>;
  orgSlug: Scalars['ID']['input'];
};

/** Paginated connection for PullRequestChecks. */
export type PullRequestCheckConnection = {
  __typename?: 'PullRequestCheckConnection';
  edges: Array<PullRequestCheckTypeEdge>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of edges. */
  totalCount: Scalars['Int']['output'];
};

export enum PullRequestCheckStatus {
  /** The commit check failed */
  Fail = 'FAIL',
  /** The commit check passed */
  Success = 'SUCCESS'
}

export type PullRequestCheckType = {
  __typename?: 'PullRequestCheckType';
  checkedCommitRevision?: Maybe<Scalars['String']['output']>;
  /** URL to the commit on code hosting provider. */
  checkedCommitUrl: Scalars['String']['output'];
  /** ID of pr check */
  id: Scalars['ID']['output'];
  ignore: Scalars['Boolean']['output'];
  /** Person who ignored the PR check. */
  ignoreAuthor?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  /** last updated on */
  on: Scalars['DateTime']['output'];
  pullRequest: LinkedPullRequestType;
  /** Status of the PR check. */
  status?: Maybe<PullRequestCheckStatus>;
  toolInstallation?: Maybe<ToolInstallationType>;
};

export type PullRequestCheckTypeEdge = {
  __typename?: 'PullRequestCheckTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: PullRequestCheckType;
};

/** Paginated connection for pull requests. */
export type PullRequestConnection = {
  __typename?: 'PullRequestConnection';
  edges: Array<LinkedPullRequestTypeEdge>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of edges. */
  totalCount: Scalars['Int']['output'];
};

export type PullRequestPaginatedType = {
  __typename?: 'PullRequestPaginatedType';
  count: Scalars['Int']['output'];
  hasNext: Scalars['Boolean']['output'];
  hasPrev: Scalars['Boolean']['output'];
  objects: Array<LinkedPullRequestType>;
  page: Scalars['Int']['output'];
  pages: Scalars['Int']['output'];
};

export enum PullRequestStateType {
  /** closed */
  Closed = 'closed',
  /** merged */
  Merged = 'merged',
  /** open */
  Open = 'open',
  /** unknown */
  Unknown = 'unknown'
}

export type PullRequestType = {
  __typename?: 'PullRequestType';
  /** EXPERIMENTAL FIELD, can change at any time. */
  checks: PullRequestCheckConnection;
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  on: Scalars['DateTime']['output'];
  prId: Scalars['String']['output'];
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
};


export type PullRequestTypeChecksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<PullRequestCheckStatus>;
};

export type Query = {
  __typename?: 'Query';
  allowedLoginMethods: Array<AllowedLoginMethodGqlType>;
  baseurl: Scalars['String']['output'];
  change?: Maybe<ChangeModelType>;
  chatChannelDetails?: Maybe<ChatChannelDetailsType>;
  deployment: DeploymentFieldType;
  drift?: Maybe<DriftType>;
  flashMessages: Array<MessageType>;
  integrationProviders: Array<IntegrationProviderDefinitionType>;
  invitation?: Maybe<InvitationGqlType>;
  labels: Array<LabelType>;
  orgChanges: ChangePaginatedType;
  orgMetricsNotifications: Array<NotificationModelType>;
  orgOauthProviders: Array<LabelAndValueType>;
  organization: OrganizationType;
  project: ProjectType;
  /** @deprecated Use `organization.projects` field instead. */
  projects: Array<ProjectType>;
  /** EXPERIMENTAL FIELD, can change at any time. */
  providerExternalDependency: ProviderExternalDependencyType;
  pullRequest?: Maybe<PullRequestType>;
  pullRequests: PullRequestConnection;
  releaseCommit: Scalars['String']['output'];
  searchOptions: SearchOptionsType;
  setupBanner: SetupBannerType;
  team: TeamType;
  teamMetricsNotifications: Array<NotificationModelType>;
  teamUsingSleuth: Scalars['Boolean']['output'];
  tool?: Maybe<ToolType>;
  tools: ToolsConnection;
  user?: Maybe<UserType>;
  userEmailNotifications: Array<NotificationModelType>;
  userNotificationsConfig?: Maybe<NotificationsConfigType>;
  wipNotifications: Array<NotificationModelType>;
};


export type QueryAllowedLoginMethodsArgs = {
  email: Scalars['String']['input'];
  orgSlug?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryChangeArgs = {
  deploySlug: Scalars['ID']['input'];
  deploymentSlug: Scalars['ID']['input'];
  orgSlug: Scalars['ID']['input'];
};


export type QueryChatChannelDetailsArgs = {
  channelId: Scalars['String']['input'];
  orgSlug: Scalars['ID']['input'];
  provider?: InputMaybe<Scalars['String']['input']>;
};


export type QueryDeploymentArgs = {
  deploymentSlug: Scalars['ID']['input'];
  orgSlug: Scalars['ID']['input'];
};


export type QueryDriftArgs = {
  changeSourceSlug: Scalars['ID']['input'];
  compareEnvironmentSlug: Scalars['ID']['input'];
  environmentSlug: Scalars['ID']['input'];
  orgSlug: Scalars['ID']['input'];
};


export type QueryIntegrationProvidersArgs = {
  orgSlug: Scalars['ID']['input'];
  projectSlug?: InputMaybe<Scalars['ID']['input']>;
  providerCategories: Array<InputMaybe<ProviderCategory>>;
  redirectUrl?: InputMaybe<Scalars['String']['input']>;
};


export type QueryInvitationArgs = {
  invitationCode: Scalars['String']['input'];
};


export type QueryLabelsArgs = {
  orgSlug: Scalars['ID']['input'];
};


export type QueryOrgChangesArgs = {
  author?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  commit?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  deploymentSlugs?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  deploymentType?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  end?: InputMaybe<Scalars['DateTime']['input']>;
  environmentName?: InputMaybe<Scalars['String']['input']>;
  environmentSlugs?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  healthTimes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  issueKey?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  labelSlugs?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  onlyDefaultEnvironments?: InputMaybe<Scalars['Boolean']['input']>;
  onlyFailedDeploys?: InputMaybe<Scalars['Boolean']['input']>;
  orderBy?: InputMaybe<OrderByType>;
  orgSlug: Scalars['ID']['input'];
  page: Scalars['Int']['input'];
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  projectSlugs?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  pullRequestKey?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  query?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  start?: InputMaybe<Scalars['DateTime']['input']>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  teamSlugs?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type QueryOrgMetricsNotificationsArgs = {
  cadence?: InputMaybe<ReportCadence>;
  environmentName?: InputMaybe<Scalars['ID']['input']>;
  labelSlugs?: InputMaybe<Array<Scalars['ID']['input']>>;
  orgSlug: Scalars['ID']['input'];
  projectSlugs?: InputMaybe<Array<Scalars['ID']['input']>>;
  teamSlugs?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type QueryOrganizationArgs = {
  orgSlug: Scalars['ID']['input'];
};


export type QueryProjectArgs = {
  orgSlug?: InputMaybe<Scalars['ID']['input']>;
  projectSlug: Scalars['ID']['input'];
};


export type QueryProjectsArgs = {
  orgSlug: Scalars['ID']['input'];
};


export type QueryPullRequestArgs = {
  changeSourceSlug?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  prId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPullRequestsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orgSlug?: InputMaybe<Scalars['ID']['input']>;
  prIds?: InputMaybe<Array<Scalars['String']['input']>>;
  since?: InputMaybe<Scalars['DateTime']['input']>;
  state?: InputMaybe<PullRequestStateType>;
};


export type QuerySearchOptionsArgs = {
  orgSlug: Scalars['ID']['input'];
};


export type QueryTeamArgs = {
  orgSlug?: InputMaybe<Scalars['ID']['input']>;
  teamSlug: Scalars['ID']['input'];
};


export type QueryTeamMetricsNotificationsArgs = {
  cadence?: InputMaybe<ReportCadence>;
  environmentName?: InputMaybe<Scalars['ID']['input']>;
  orgSlug: Scalars['ID']['input'];
  teamSlug: Scalars['ID']['input'];
};


export type QueryTeamUsingSleuthArgs = {
  email: Scalars['String']['input'];
};


export type QueryToolArgs = {
  slug: Scalars['ID']['input'];
  version?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryToolsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderByLatest?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  term?: InputMaybe<Scalars['String']['input']>;
};


export type QueryUserEmailNotificationsArgs = {
  orgSlug: Scalars['ID']['input'];
};


export type QueryUserNotificationsConfigArgs = {
  deploymentSlug?: InputMaybe<Scalars['ID']['input']>;
  environmentSlug: Scalars['ID']['input'];
  notificationName: NotificationName;
  orgSlug: Scalars['ID']['input'];
  projectSlug: Scalars['ID']['input'];
  teamSlug?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryWipNotificationsArgs = {
  environmentName?: InputMaybe<Scalars['String']['input']>;
  orgSlug: Scalars['ID']['input'];
  projectSlug?: InputMaybe<Scalars['ID']['input']>;
  teamSlug?: InputMaybe<Scalars['ID']['input']>;
};

export type RecapType = {
  __typename?: 'RecapType';
  avgCdSize?: Maybe<Scalars['Float']['output']>;
  avgLeadTimeInSec: Scalars['Int']['output'];
  avgMttrDurationInSec: Scalars['Int']['output'];
  failureRatePercent: Scalars['Float']['output'];
  numOfDeploys: Scalars['Int']['output'];
  numOfDeploysPerDay: Scalars['Float']['output'];
};

export type RecapWithContributingTeams = {
  __typename?: 'RecapWithContributingTeams';
  avgCdSize?: Maybe<Scalars['Float']['output']>;
  avgLeadTimeInSec: Scalars['Int']['output'];
  avgMttrDurationInSec: Scalars['Int']['output'];
  /** Teams that contributed to this project in the selected time frame. */
  contributingTeams: Array<ShortTeamType>;
  failureRatePercent: Scalars['Float']['output'];
  numOfDeploys: Scalars['Int']['output'];
  numOfDeploysPerDay: Scalars['Float']['output'];
};

export type RecapWithEnvBreakdownType = {
  __typename?: 'RecapWithEnvBreakdownType';
  avgCdSize?: Maybe<Scalars['Float']['output']>;
  avgLeadTimeInSec: Scalars['Int']['output'];
  avgMttrDurationInSec: Scalars['Int']['output'];
  breakdownByEnvs: Array<RecapWithEnvInfoType>;
  failureRatePercent: Scalars['Float']['output'];
  numOfDeploys: Scalars['Int']['output'];
  numOfDeploysPerDay: Scalars['Float']['output'];
};

export type RecapWithEnvInfoType = {
  __typename?: 'RecapWithEnvInfoType';
  avgCdSize?: Maybe<Scalars['Float']['output']>;
  avgLeadTimeInSec: Scalars['Int']['output'];
  avgMttrDurationInSec: Scalars['Int']['output'];
  environmentName: Scalars['String']['output'];
  environmentSlug: Scalars['String']['output'];
  failureRatePercent: Scalars['Float']['output'];
  numOfDeploys: Scalars['Int']['output'];
  numOfDeploysPerDay: Scalars['Float']['output'];
  projectName: Scalars['String']['output'];
  projectSlug: Scalars['String']['output'];
};

export type RecapWithQuartersType = {
  __typename?: 'RecapWithQuartersType';
  quarters: Array<DoraQuarterType>;
  totalRecap: RecapType;
};

export type RemoteFeatureFlagEnvironment = {
  __typename?: 'RemoteFeatureFlagEnvironment';
  key: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type RemoteFeatureFlagProject = {
  __typename?: 'RemoteFeatureFlagProject';
  environments: Array<RemoteFeatureFlagEnvironment>;
  key: Scalars['String']['output'];
  name: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export enum RemoteObjectSyncType {
  /** Build Definitions */
  BuildDefinitions = 'BUILD_DEFINITIONS',
  /** Issue State Definitions */
  IssueStateDefinitions = 'ISSUE_STATE_DEFINITIONS',
  /** Repositories */
  Repositories = 'REPOSITORIES'
}

export type RemoveTeamMembersMutationInput = {
  members: Array<InputMaybe<Scalars['String']['input']>>;
  slug: Scalars['ID']['input'];
};

export type RemoveTeamMembersMutationPayload = {
  __typename?: 'RemoveTeamMembersMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  success: Scalars['Boolean']['output'];
};

export enum ReportCadence {
  /** Biweekly */
  BiWeekly = 'BI_WEEKLY',
  /** Daily */
  Daily = 'DAILY',
  /** Every */
  Every = 'EVERY',
  /** Monthly */
  Monthly = 'MONTHLY',
  /** Quarterly */
  Quarterly = 'QUARTERLY',
  /** Weekly */
  Weekly = 'WEEKLY'
}

export type RepositoryInputType = {
  integrationSlug?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  owner: Scalars['String']['input'];
  /** Globally unique ID of the project this repository is part of. Required only for some providers. */
  projectUid?: InputMaybe<Scalars['String']['input']>;
  provider: CodeDeploymentProviderType;
  /** Globally unique ID of this repository. Required only for some providers. */
  repoUid?: InputMaybe<Scalars['String']['input']>;
  url: Scalars['String']['input'];
};

export type RepositoryType = {
  __typename?: 'RepositoryType';
  gitUrl: Scalars['String']['output'];
  integrationAuth?: Maybe<IntegrationAuthenticationType>;
  name: Scalars['String']['output'];
  owner: Scalars['String']['output'];
  projectUid?: Maybe<Scalars['String']['output']>;
  provider: Scalars['String']['output'];
  repoUid?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
  webhook?: Maybe<WebhookType>;
};

export type ResendEmailConfirmationMutation = {
  __typename?: 'ResendEmailConfirmationMutation';
  success: Scalars['Boolean']['output'];
};

export type ResendInvitationMutationInput = {
  invitationId: Scalars['ID']['input'];
};

export type ResendInvitationMutationPayload = {
  __typename?: 'ResendInvitationMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  success: Scalars['Boolean']['output'];
};

export type RevokeInvitationMutationInput = {
  invitationId: Scalars['ID']['input'];
};

export type RevokeInvitationMutationPayload = {
  __typename?: 'RevokeInvitationMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  success: Scalars['Boolean']['output'];
};

export enum Role {
  Admin = 'ADMIN',
  Billing = 'BILLING',
  Contributor = 'CONTRIBUTOR',
  Dev = 'DEV',
  Member = 'MEMBER'
}

export type RootlyIncidentImpactSourceInputType = {
  /** The slug for the integration */
  integrationSlug?: InputMaybe<Scalars['String']['input']>;
  /** Environment impacted */
  remoteEnvironment?: InputMaybe<Scalars['String']['input']>;
  /** Incident type */
  remoteIncidentType?: InputMaybe<Scalars['String']['input']>;
  /** Service impacted */
  remoteService?: InputMaybe<Scalars['String']['input']>;
  /** Incident severity */
  remoteSeverity?: InputMaybe<RootlySeverityOptions>;
  /** Team impacted */
  remoteTeam?: InputMaybe<Scalars['String']['input']>;
};

export type RootlyProviderData = {
  __typename?: 'RootlyProviderData';
  remoteEnvironment?: Maybe<Scalars['String']['output']>;
  remoteIncidentType?: Maybe<Scalars['String']['output']>;
  remoteService?: Maybe<Scalars['String']['output']>;
  remoteSeverity?: Maybe<RootlySeverityOptions>;
  remoteTeam?: Maybe<Scalars['String']['output']>;
};

export type RootlyRemoteObjectType = {
  __typename?: 'RootlyRemoteObjectType';
  label: Scalars['String']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export enum RootlySeverityOptions {
  All = 'ALL',
  /** Critical */
  Critical = 'CRITICAL',
  /** High */
  High = 'HIGH',
  /** Low */
  Low = 'LOW',
  /** Medium */
  Medium = 'MEDIUM'
}

export type RuleExecutionPaginatedType = {
  __typename?: 'RuleExecutionPaginatedType';
  count: Scalars['Int']['output'];
  hasNext: Scalars['Boolean']['output'];
  hasPrev: Scalars['Boolean']['output'];
  objects: Array<RuleExecutionType>;
  page: Scalars['Int']['output'];
  pages: Scalars['Int']['output'];
};

export enum RuleExecutionStatus {
  /** Condition failed */
  ConditionFailed = 'CONDITION_FAILED',
  /** Error */
  Error = 'ERROR',
  /** Failure */
  Failure = 'FAILURE',
  /** Pending */
  Pending = 'PENDING',
  /** Success */
  Success = 'SUCCESS'
}

export type RuleExecutionType = {
  __typename?: 'RuleExecutionType';
  deploy?: Maybe<ChangeModelType>;
  message: Scalars['String']['output'];
  on: Scalars['DateTime']['output'];
  results: Array<ActionResultType>;
  rule: RuleType;
  status: RuleExecutionStatus;
  triggerType: Scalars['String']['output'];
};

export type RuleType = {
  __typename?: 'RuleType';
  actions: Array<ActionType>;
  conditions: Array<ConditionType>;
  description: Scalars['String']['output'];
  lineNumber: Scalars['Int']['output'];
  on: Scalars['DateTime']['output'];
  order: Scalars['Int']['output'];
  title: Scalars['String']['output'];
  triggers: Array<TriggerType>;
};

export type SearchOptionsType = {
  __typename?: 'SearchOptionsType';
  authors: Array<Scalars['String']['output']>;
  deployments: Array<NameAndSlugSearchOptionType>;
  environments: Array<NameAndSlugSearchOptionType>;
  healthOptions: Array<Scalars['String']['output']>;
  labels: Array<NameAndSlugSearchOptionType>;
  projects: Array<NameAndSlugSearchOptionType>;
  sizeOptions: Array<Scalars['String']['output']>;
  tags: Array<Scalars['String']['output']>;
  teams: Array<NameAndSlugSearchOptionType>;
};

export type SeenEventType = {
  __typename?: 'SeenEventType';
  deployment?: Maybe<Scalars['ID']['output']>;
  name: Scalars['String']['output'];
  project?: Maybe<Scalars['ID']['output']>;
};

export type ServiceNowIncidentImpactSource = {
  __typename?: 'ServiceNowIncidentImpactSource';
  environment: Environment;
  incidentSpans: OriginalHealthSpanConnection;
  latestImpacts: Array<LinkedImpactType>;
  name: Scalars['String']['output'];
  ostracizedOn?: Maybe<Scalars['DateTime']['output']>;
  provider: Scalars['String']['output'];
  providerData?: Maybe<ProviderDataFieldType>;
  slug: Scalars['String']['output'];
  type: ImpactType;
};


export type ServiceNowIncidentImpactSourceIncidentSpansArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  end: Scalars['DateTime']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  start: Scalars['DateTime']['input'];
};

export type ServiceNowInputType = {
  /** Populate with data from the last 30 days */
  historicInit?: InputMaybe<Scalars['Boolean']['input']>;
  /** The slug for the integration */
  integrationSlug?: InputMaybe<Scalars['String']['input']>;
  /** Assignment group */
  remoteAssignmentGroup?: InputMaybe<Scalars['String']['input']>;
  /** Business service */
  remoteBusinessService?: InputMaybe<Scalars['String']['input']>;
  /** Custom filters */
  remoteCustomFilters?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Severity */
  remoteSeverity?: InputMaybe<ServiceNowRemoteSeverityChoices>;
};

export type ServiceNowProviderData = {
  __typename?: 'ServiceNowProviderData';
  remoteAssignmentGroup?: Maybe<Scalars['String']['output']>;
  remoteBusinessService?: Maybe<Scalars['String']['output']>;
  remoteCustomFilters?: Maybe<Array<Scalars['String']['output']>>;
  remoteSeverity?: Maybe<ServiceNowRemoteSeverityChoices>;
};

export enum ServiceNowRemoteSeverityChoices {
  /** 1 - High */
  High = 'HIGH',
  /** 3 - Low */
  Low = 'LOW',
  /** 2 - Medium */
  Medium = 'MEDIUM'
}

export type ServicenowAssignmentGroup = {
  __typename?: 'ServicenowAssignmentGroup';
  label: Scalars['String']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type ServicenowRemoteBusinessService = {
  __typename?: 'ServicenowRemoteBusinessService';
  label: Scalars['String']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type SetBulkUserWorkingHoursMutation = {
  __typename?: 'SetBulkUserWorkingHoursMutation';
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
};

export type SetChannelEnvironmentMappingMutation = {
  __typename?: 'SetChannelEnvironmentMappingMutation';
  success: Scalars['Boolean']['output'];
};

export type SetCurrentUserWorkingHoursMutation = {
  __typename?: 'SetCurrentUserWorkingHoursMutation';
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
};

export type SetDefaultEnvironmentMutationInput = {
  projectSlug: Scalars['ID']['input'];
  slug: Scalars['ID']['input'];
};

export type SetDefaultEnvironmentMutationPayload = {
  __typename?: 'SetDefaultEnvironmentMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  project?: Maybe<ProjectType>;
  success: Scalars['Boolean']['output'];
};

export type SetHealthMutation = {
  __typename?: 'SetHealthMutation';
  success: Scalars['Boolean']['output'];
};

export type SetOrganizationWorkingHoursMutation = {
  __typename?: 'SetOrganizationWorkingHoursMutation';
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
};

export type SetProjectInSessionMutation = {
  __typename?: 'SetProjectInSessionMutation';
  environmentSlug: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type SetTeamInSessionMutation = {
  __typename?: 'SetTeamInSessionMutation';
  success: Scalars['Boolean']['output'];
};

export type SetToolInstallationMutationInput = {
  /** Whether to install the tool or not */
  installed?: InputMaybe<Scalars['Boolean']['input']>;
  parameters?: InputMaybe<Scalars['String']['input']>;
  projectSlug?: InputMaybe<Scalars['ID']['input']>;
  teamSlug?: InputMaybe<Scalars['ID']['input']>;
  toolSlug: Scalars['String']['input'];
  toolVersion?: InputMaybe<Scalars['Int']['input']>;
};

export type SetToolInstallationMutationPayload = {
  __typename?: 'SetToolInstallationMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  instance?: Maybe<ToolInstallationType>;
};

export type SetupBannerBuildDeploymentNudge = {
  __typename?: 'SetupBannerBuildDeploymentNudge';
  buildProvider: BuildProviderType;
  codeDeploymentName: Scalars['String']['output'];
  codeDeploymentSlug: Scalars['String']['output'];
};

export type SetupBannerDeploymentNudge = {
  __typename?: 'SetupBannerDeploymentNudge';
  codeDeploymentName: Scalars['String']['output'];
  codeDeploymentSlug: Scalars['String']['output'];
};

export type SetupBannerType = {
  __typename?: 'SetupBannerType';
  canEnableDomainSignup: Scalars['Boolean']['output'];
  domainSignupNudge: Scalars['Boolean']['output'];
  environmentNotMappedNudges: Array<SetupBannerBuildDeploymentNudge>;
  environmentNudge: Scalars['Boolean']['output'];
  hasConfirmedEmail: Scalars['Boolean']['output'];
  healthImpactNudge: Scalars['Boolean']['output'];
  isAccessible: Scalars['Boolean']['output'];
  issueTrackerNudge: Scalars['Boolean']['output'];
  msTeamsNudge: Scalars['Boolean']['output'];
  slackNudge: Scalars['Boolean']['output'];
  webhookNudges: Array<SetupBannerDeploymentNudge>;
};

export type ShortTeamType = {
  __typename?: 'ShortTeamType';
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
};

export enum SocialLoginProviderType {
  /** Bitbucket */
  Bitbucket = 'BITBUCKET',
  /** Github */
  Github = 'GITHUB',
  /** Google */
  Google = 'GOOGLE',
  /** Microsoft */
  Microsoft = 'MICROSOFT',
  /** Saml */
  Saml = 'SAML'
}

export type StatuspageIncidentImpactSourceInputType = {
  /** Ignore maintenance incidents */
  ignoreMaintenanceIncidents?: InputMaybe<Scalars['Boolean']['input']>;
  integrationSlug?: InputMaybe<Scalars['String']['input']>;
  /** Statuspage component */
  remoteComponent?: InputMaybe<Scalars['String']['input']>;
  /** Statuspage impact */
  remoteImpact?: InputMaybe<Scalars['String']['input']>;
  /** Statuspage page */
  remotePage?: InputMaybe<Scalars['String']['input']>;
};

export type StatuspageProviderData = {
  __typename?: 'StatuspageProviderData';
  ignoreMaintenanceIncidents?: Maybe<Scalars['Boolean']['output']>;
  remoteComponent?: Maybe<Scalars['String']['output']>;
  remoteImpact?: Maybe<Scalars['String']['output']>;
  remotePage?: Maybe<Scalars['String']['output']>;
};

export type StatuspageRemoteComponent = {
  __typename?: 'StatuspageRemoteComponent';
  label: Scalars['String']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type StatuspageRemoteComponentPageArgument = {
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type StatuspageRemotePage = {
  __typename?: 'StatuspageRemotePage';
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type SteadyMetricsInsightType = {
  __typename?: 'SteadyMetricsInsightType';
  icon: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type SyncStatusType = {
  __typename?: 'SyncStatusType';
  integrationAuthSlug?: Maybe<Scalars['String']['output']>;
  lastSyncAt?: Maybe<Scalars['DateTime']['output']>;
  provider: Provider;
  remoteObjectType: RemoteObjectSyncType;
  syncInProgress: Scalars['Boolean']['output'];
};

export type SyncStatusTypeEdge = {
  __typename?: 'SyncStatusTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: SyncStatusType;
};

export type SyncStatusesConnection = {
  __typename?: 'SyncStatusesConnection';
  edges: Array<SyncStatusTypeEdge>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of edges. */
  totalCount: Scalars['Int']['output'];
};

export type SyncedBuildDefinitionType = {
  __typename?: 'SyncedBuildDefinitionType';
  groupKey: Scalars['String']['output'];
  jobDefinitions: SyncedJobDefinitionsConnection;
  name: Scalars['String']['output'];
  projectKey?: Maybe<Scalars['String']['output']>;
};


export type SyncedBuildDefinitionTypeJobDefinitionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type SyncedBuildDefinitionTypeEdge = {
  __typename?: 'SyncedBuildDefinitionTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: SyncedBuildDefinitionType;
};

export type SyncedBuildDefinitionsConnection = {
  __typename?: 'SyncedBuildDefinitionsConnection';
  edges: Array<SyncedBuildDefinitionTypeEdge>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of edges. */
  totalCount: Scalars['Int']['output'];
};

export type SyncedJobDefinitionType = {
  __typename?: 'SyncedJobDefinitionType';
  name: Scalars['String']['output'];
};

export type SyncedJobDefinitionTypeEdge = {
  __typename?: 'SyncedJobDefinitionTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: SyncedJobDefinitionType;
};

export type SyncedJobDefinitionsConnection = {
  __typename?: 'SyncedJobDefinitionsConnection';
  edges: Array<SyncedJobDefinitionTypeEdge>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of edges. */
  totalCount: Scalars['Int']['output'];
};

export type TeamMetricFilterArguments = {
  /** If no environment name is provided, we default to the default environment of each project. The value is case insensitive. */
  environmentName?: InputMaybe<Scalars['String']['input']>;
  /** Filter down team metrics to specified project.If no project slug is provided, we default to all projects */
  projectSlug?: InputMaybe<Scalars['ID']['input']>;
};

export type TeamMetricLowInsightType = {
  __typename?: 'TeamMetricLowInsightType';
  diffToNextLevel: Scalars['Float']['output'];
  icon: Scalars['String']['output'];
  message: Scalars['String']['output'];
  metricLevel: AccRanking;
  metricType: MetricType;
  nextMetricLevel: AccRanking;
};

export type TeamMetricsNotificationInputType = {
  /** Only the following cadences are supported: BI_WEEKLY, MONTHLY, QUARTERLY, WEEKLY */
  cadence: ReportCadence;
  environmentName?: InputMaybe<Scalars['String']['input']>;
  orgSlug: Scalars['ID']['input'];
  teamSlug: Scalars['ID']['input'];
};

export type TeamParentInputType = {
  any?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['ID']['input']>;
};

export type TeamType = Node & {
  __typename?: 'TeamType';
  /** The ID of the object */
  id: Scalars['ID']['output'];
  installedTools: InstalledToolsConnection;
  integrationDescription?: Maybe<Scalars['String']['output']>;
  integrationSlug?: Maybe<Scalars['String']['output']>;
  members: UserPaginatedType;
  metricFailureRate: MetricFailureRateChartType;
  metricFrequency: MetricFrequencyChartType;
  metricLeadTime: MetricLeadTimeChartType;
  metricMttr: MetricMttrChartType;
  metricRecap: RecapWithEnvBreakdownType;
  name: Scalars['String']['output'];
  parent?: Maybe<TeamType>;
  provider?: Maybe<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
  subteams: TeamsConnection;
  subteamsCount: Scalars['Int']['output'];
  url: Scalars['String']['output'];
};


export type TeamTypeInstalledToolsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  toolSlug?: InputMaybe<Scalars['ID']['input']>;
};


export type TeamTypeMembersArgs = {
  page: Scalars['Int']['input'];
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type TeamTypeMetricFailureRateArgs = {
  end: Scalars['DateTime']['input'];
  filters?: InputMaybe<TeamMetricFilterArguments>;
  start: Scalars['DateTime']['input'];
};


export type TeamTypeMetricFrequencyArgs = {
  end: Scalars['DateTime']['input'];
  filters?: InputMaybe<TeamMetricFilterArguments>;
  start: Scalars['DateTime']['input'];
};


export type TeamTypeMetricLeadTimeArgs = {
  end: Scalars['DateTime']['input'];
  filters?: InputMaybe<TeamMetricFilterArguments>;
  leadTimeBucket?: InputMaybe<CycleBucketType>;
  start: Scalars['DateTime']['input'];
};


export type TeamTypeMetricMttrArgs = {
  end: Scalars['DateTime']['input'];
  filters?: InputMaybe<TeamMetricFilterArguments>;
  start: Scalars['DateTime']['input'];
};


export type TeamTypeMetricRecapArgs = {
  end: Scalars['DateTime']['input'];
  filters?: InputMaybe<TeamMetricFilterArguments>;
  start: Scalars['DateTime']['input'];
};


export type TeamTypeSubteamsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  allDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  term?: InputMaybe<Scalars['String']['input']>;
};

export type TeamTypeEdge = {
  __typename?: 'TeamTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: TeamType;
};

export type TeamsConnection = {
  __typename?: 'TeamsConnection';
  edges: Array<TeamTypeEdge>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of edges. */
  totalCount: Scalars['Int']['output'];
};

export type TeamsSyncInputType = {
  /** Slug of the integration used for teams sync in case of multiple integrations configured. */
  integrationSlug?: InputMaybe<Scalars['String']['input']>;
  orgSlug: Scalars['ID']['input'];
  /** Desired Teams provider. Leave empty for manual control over Sleuth Teams */
  provider?: InputMaybe<TeamsSyncProviderType>;
};

export enum TeamsSyncProviderType {
  /** Github */
  Github = 'GITHUB'
}

export type TeamsSyncStatusMutation = {
  __typename?: 'TeamsSyncStatusMutation';
  success: Scalars['Boolean']['output'];
};

export type TeamsSyncStatusType = {
  __typename?: 'TeamsSyncStatusType';
  integrationSlug?: Maybe<Scalars['String']['output']>;
  lastSyncAt?: Maybe<Scalars['DateTime']['output']>;
  provider?: Maybe<Scalars['String']['output']>;
  syncInProgress: Scalars['Boolean']['output'];
};

export type TestAndFixDeploymentConnectionMutation = {
  __typename?: 'TestAndFixDeploymentConnectionMutation';
  connectionTestResult: ConnectionTestResultType;
};

export type TestAndFixImpactSourceConnectionMutation = {
  __typename?: 'TestAndFixImpactSourceConnectionMutation';
  connectionTestResult: ConnectionTestResultType;
};

export type TimelineEventType = {
  __typename?: 'TimelineEventType';
  date: Scalars['DateTime']['output'];
  identifier: Scalars['String']['output'];
  name: Scalars['String']['output'];
  type: TimelineEventTypeType;
  url: Scalars['String']['output'];
};

export enum TimelineEventTypeType {
  Build = 'BUILD',
  Commit = 'COMMIT',
  CommitHistorical = 'COMMIT_HISTORICAL',
  Deploy = 'DEPLOY',
  Issue = 'ISSUE',
  IssueCreated = 'ISSUE_CREATED',
  IssueStateTransition = 'ISSUE_STATE_TRANSITION',
  PullRequest = 'PULL_REQUEST',
  PullRequestFirstReviewed = 'PULL_REQUEST_FIRST_REVIEWED',
  PullRequestMerged = 'PULL_REQUEST_MERGED',
  Replaced = 'REPLACED',
  Success = 'SUCCESS'
}

export type TimelineType = {
  __typename?: 'TimelineType';
  deployedOn: Scalars['DateTime']['output'];
  end: Scalars['DateTime']['output'];
  events: Array<TimelineEventType>;
  showDays: Scalars['Boolean']['output'];
  start: Scalars['DateTime']['output'];
  workStartedOn: Scalars['DateTime']['output'];
};

export type ToggleDeploymentLockMutation = {
  __typename?: 'ToggleDeploymentLockMutation';
  success: Scalars['Boolean']['output'];
};

export type ToggleLinkedPullRequestIgnoredInputType = {
  /** Location where the mutation was triggered from, only used for analytics purposes. */
  eventSource?: IgnorePullRequestEventSourceType;
  id: Scalars['ID']['input'];
  ignored: Scalars['Boolean']['input'];
  orgSlug: Scalars['ID']['input'];
};

export type ToggleLinkedPullRequestIgnoredMutation = {
  __typename?: 'ToggleLinkedPullRequestIgnoredMutation';
  success: Scalars['Boolean']['output'];
};

export type ToggleNotificationInputType = {
  /** Supports only cadences: BI_WEEKLY, DAILY, EVERY, MONTHLY, WEEKLY */
  cadence: ReportCadence;
  deploymentSlug?: InputMaybe<Scalars['ID']['input']>;
  environmentSlug: Scalars['ID']['input'];
  /** Supports only notification names: REPORT_DEPLOYS, REPORT_METRICS, REPORT_WIP */
  notificationName: NotificationName;
  notificationType: NotificationType;
  orgSlug: Scalars['ID']['input'];
  projectSlug: Scalars['ID']['input'];
  teamSlug?: InputMaybe<Scalars['ID']['input']>;
};

export type ToggleProjectLockMutation = {
  __typename?: 'ToggleProjectLockMutation';
  success: Scalars['Boolean']['output'];
};

/** Toggle email and Slack reports. If the notification exists, it is deleted, otherwise it is created. */
export type ToggleReportNotifications = {
  __typename?: 'ToggleReportNotifications';
  /** The newly created notification, if one was created */
  notification?: Maybe<NotificationModelType>;
  success: Scalars['Boolean']['output'];
};

export type ToolImageType = {
  __typename?: 'ToolImageType';
  description: Scalars['String']['output'];
  isHero: Scalars['Boolean']['output'];
  url: Scalars['String']['output'];
};

export type ToolInstallationStatsType = {
  __typename?: 'ToolInstallationStatsType';
  /** How effective is this tool since being installed */
  effectiveness?: Maybe<Scalars['Int']['output']>;
  /** How the effectiveness percent was calculated */
  effectivenessHelpText?: Maybe<Scalars['String']['output']>;
  /** How much this tool has improved behavior since being installed */
  improvement?: Maybe<Scalars['Int']['output']>;
  /** How the improvement percent was calculated */
  improvementHelpText?: Maybe<Scalars['String']['output']>;
};

export type ToolInstallationType = {
  __typename?: 'ToolInstallationType';
  installedOn: Scalars['DateTime']['output'];
  installer: UserType;
  parameters: Scalars['JSONString']['output'];
  project?: Maybe<ProjectRelayType>;
  stats?: Maybe<ToolInstallationStatsType>;
  team?: Maybe<TeamType>;
  tool: ToolType;
};

export type ToolInstallationTypeEdge = {
  __typename?: 'ToolInstallationTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: ToolInstallationType;
};

export type ToolStatsType = {
  __typename?: 'ToolStatsType';
  /** How effective is this tool across all installations */
  effectiveness?: Maybe<Scalars['Int']['output']>;
  /** How much this tool has improved behavior across all installations */
  improvement?: Maybe<Scalars['Int']['output']>;
};

export type ToolType = {
  __typename?: 'ToolType';
  averageStats?: Maybe<ToolStatsType>;
  description: Scalars['String']['output'];
  images: Array<ToolImageType>;
  installationCount: Scalars['Int']['output'];
  /** The URL to the feature that this tool represents. */
  linkedFeatureUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  parameterSchema: Scalars['String']['output'];
  publishedOn: Scalars['DateTime']['output'];
  slug: Scalars['String']['output'];
  sourceYaml?: Maybe<Scalars['String']['output']>;
  tags: Array<Scalars['String']['output']>;
  thumbnailUrl?: Maybe<Scalars['String']['output']>;
  version: Scalars['Int']['output'];
};

export type ToolTypeEdge = {
  __typename?: 'ToolTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: ToolType;
};

/** Paginated connection for Tools. */
export type ToolsConnection = {
  __typename?: 'ToolsConnection';
  edges: Array<ToolTypeEdge>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of edges. */
  totalCount: Scalars['Int']['output'];
};

/** Where to find builds related to changes */
export enum TrackBuildProviderType {
  /** Azure pipelines */
  Azure = 'AZURE',
  /** Bitbucket Pipelines */
  BitbucketPipelines = 'BITBUCKET_PIPELINES',
  /** Builds by Buildkite */
  Buildkite = 'BUILDKITE',
  /** Builds by CircleCI */
  Circleci = 'CIRCLECI',
  /** CodeDeploy */
  Codedeploy = 'CODEDEPLOY',
  /** GitHub Actions */
  Github = 'GITHUB',
  /** GitHub Enterprise Cloud Actions */
  GithubEnterprise = 'GITHUB_ENTERPRISE',
  /** GitLab CI/CD */
  Gitlab = 'GITLAB',
  /** Jenkins jobs */
  Jenkins = 'JENKINS',
  /** Don't track builds for each deploy */
  None = 'NONE'
}

export type TriggerCodeChangeSourceReInitializationMutation = {
  __typename?: 'TriggerCodeChangeSourceReInitializationMutation';
  success: Scalars['Boolean']['output'];
};

export type TriggerIssueStatusesSyncInputType = {
  integrationSlug: Scalars['ID']['input'];
  orgSlug: Scalars['ID']['input'];
};

export type TriggerIssueStatusesSyncMutation = {
  __typename?: 'TriggerIssueStatusesSyncMutation';
  errors: Array<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type TriggerRemoteObjectSyncInputType = {
  integrationSlug: Scalars['ID']['input'];
  orgSlug: Scalars['ID']['input'];
  remoteObjectType: RemoteObjectSyncType;
};

export type TriggerRemoteObjectSyncMutation = {
  __typename?: 'TriggerRemoteObjectSyncMutation';
  errors?: Maybe<Array<ErrorType>>;
  success: Scalars['Boolean']['output'];
};

export type TriggerTeamsSyncInputType = {
  integrationSlugs?: InputMaybe<Array<Scalars['ID']['input']>>;
  orgSlug: Scalars['ID']['input'];
};

export type TriggerTeamsSyncMutation = {
  __typename?: 'TriggerTeamsSyncMutation';
  errors?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  success: Scalars['Boolean']['output'];
};

export type TriggerType = {
  __typename?: 'TriggerType';
  description: Scalars['String']['output'];
  lineNumber: Scalars['Int']['output'];
  type: Scalars['String']['output'];
};

export type UnlinkExternalUserMutation = {
  __typename?: 'UnlinkExternalUserMutation';
  errors?: Maybe<Array<ErrorType>>;
  success: Scalars['Boolean']['output'];
};

export type UnsubscribeFromEmailReports = {
  __typename?: 'UnsubscribeFromEmailReports';
  success: Scalars['Boolean']['output'];
};

export type UpdateBuildImpactSourceMutationInput = {
  /** Your organization has multiple integrations connected. Choose which one this impact source will use. */
  auth?: InputMaybe<Scalars['String']['input']>;
  /** Specify a branch name when you want to filter to builds running on that branch. */
  buildBranch?: InputMaybe<Scalars['String']['input']>;
  buildJobName?: InputMaybe<Scalars['String']['input']>;
  buildName?: InputMaybe<Scalars['String']['input']>;
  buildProjectKey?: InputMaybe<Scalars['String']['input']>;
  environment?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  projectSlug: Scalars['ID']['input'];
  provider?: InputMaybe<BuildImpactProvider>;
  slug: Scalars['ID']['input'];
};

export type UpdateBuildImpactSourceMutationPayload = {
  __typename?: 'UpdateBuildImpactSourceMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  impactSource?: Maybe<BuildImpactSource>;
};

export type UpdateCodeChangeSourceMutationInput = {
  /**
   * Tell us the average time it takes for your code merge to deploy.
   *                     We will register the deploy after the specified delay.
   *             <sleuth-alert variant="warning">
   *                 Impact tracking and Slack notifications may be inaccurate when using this setting.
   *                 We recommend using precise deploy registration instead.
   *             </sleuth-alert>
   */
  autoTrackingDelay?: InputMaybe<Scalars['Int']['input']>;
  /** Don't see the desired build integration here? Make sure you configured it in settings. */
  buildIntegrationSlug?: InputMaybe<Scalars['String']['input']>;
  buildMappings?: InputMaybe<Array<EnvironmentBuildMappingType>>;
  buildProvider?: InputMaybe<TrackBuildProviderType>;
  /** If unchecked Impact won't be collected upon deploy and thus won't affect your project's health. */
  collectImpact?: InputMaybe<Scalars['Boolean']['input']>;
  deployTrackingType?: InputMaybe<DeployTrackingType>;
  environmentMappings?: InputMaybe<Array<EnvironmentBranchMappingType>>;
  /** Filter this change source from appearing in the project dashboard, stats, and graphs */
  includeInDashboard?: InputMaybe<Scalars['Boolean']['input']>;
  /** Using the chosen tracking type, try to determine what possible deploys happened in the last 30 days. Deploys found this way are marked as 'Approximate'. */
  initializeChanges?: InputMaybe<Scalars['Boolean']['input']>;
  initializeChangesOnEnvCreation?: InputMaybe<Scalars['Boolean']['input']>;
  lockingType?: InputMaybe<CodeDeploymentLockingType>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** Receive Slack notifications on deploy events. */
  notifyInSlack?: InputMaybe<Scalars['Boolean']['input']>;
  /** Restricts deployment information to only files matching this prefix, primarily useful for monorepos. Use comma-separated list of prefixes to cover multiple paths. */
  pathPrefix?: InputMaybe<Scalars['String']['input']>;
  projectSlug: Scalars['ID']['input'];
  repository?: InputMaybe<RepositoryInputType>;
  slug: Scalars['ID']['input'];
};

export type UpdateCodeChangeSourceMutationPayload = {
  __typename?: 'UpdateCodeChangeSourceMutationPayload';
  changeSource?: Maybe<CodeChangeSource>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
};

export type UpdateEnvironmentMutationInput = {
  color?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  projectSlug: Scalars['ID']['input'];
  slug: Scalars['ID']['input'];
};

export type UpdateEnvironmentMutationPayload = {
  __typename?: 'UpdateEnvironmentMutationPayload';
  environment?: Maybe<Environment>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
};

export type UpdateErrorImpactSourceMutationInput = {
  /** Your organization has multiple integrations connected. Choose which one this impact source will use. */
  auth?: InputMaybe<Scalars['String']['input']>;
  /** All values below this value will be considered healthy. For values at or above this value we will perform a statistical analysis.  */
  earliestNonhealthyThreshold?: InputMaybe<Scalars['Float']['input']>;
  environment?: InputMaybe<Scalars['String']['input']>;
  /** The environment name for the error impact source, which may or may not be the same as the Sleuth environment. */
  errorEnvironment?: InputMaybe<Scalars['String']['input']>;
  errorOrgKey?: InputMaybe<Scalars['String']['input']>;
  errorProjectId?: InputMaybe<Scalars['String']['input']>;
  errorProjectKey?: InputMaybe<Scalars['String']['input']>;
  /** All values below this value will be considered healthy. All values at or above it unhealthy. We won't perform any statistical analysis. */
  indisputableThreshold?: InputMaybe<Scalars['Float']['input']>;
  /** DEPRECATED, Use earliestNonhealthyThreshold instead. */
  manuallySetHealthThreshold?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  projectSlug: Scalars['ID']['input'];
  provider?: InputMaybe<ErrorImpactSourceProvider>;
  slug: Scalars['ID']['input'];
};

export type UpdateErrorImpactSourceMutationPayload = {
  __typename?: 'UpdateErrorImpactSourceMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  impactSource?: Maybe<ErrorImpactSource>;
};

export type UpdateFeatureFlagChangeSourceMutationInput = {
  /** Includes this source’s impact within your project's health metrics. */
  collectImpact?: InputMaybe<Scalars['Boolean']['input']>;
  environmentMappings?: InputMaybe<Array<EnvironmentRemoteEnvironmentMappingType>>;
  /** Filter this change source from appearing in the project dashboard, stats, and graphs */
  includeInDashboard?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** Receive Slack notifications on deploy events. */
  notifyInSlack?: InputMaybe<Scalars['Boolean']['input']>;
  projectSlug: Scalars['ID']['input'];
  provider?: InputMaybe<FlagProvider>;
  remoteProjectKey?: InputMaybe<Scalars['String']['input']>;
  slug: Scalars['ID']['input'];
  usedByDeployments?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateFeatureFlagChangeSourceMutationPayload = {
  __typename?: 'UpdateFeatureFlagChangeSourceMutationPayload';
  changeSource?: Maybe<FeatureFlagChangeSource>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
};

export type UpdateGitlabIntegrationMutationInput = {
  apiSecondaryToken?: InputMaybe<Scalars['String']['input']>;
  apiToken?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  /** Coma separated list of custom headers Sleuth will send with every request. Specify in the format: my-header=my-value,my-other-header=value2 */
  extraHeaders?: InputMaybe<Scalars['String']['input']>;
  provider?: InputMaybe<Scalars['String']['input']>;
  /** Whether only read operations will be allowed or not. If enabled, some features may be unavailable. */
  readOnly?: InputMaybe<Scalars['Boolean']['input']>;
  slug: Scalars['ID']['input'];
  /** The fully qualified url of your instance. */
  url?: InputMaybe<Scalars['String']['input']>;
  /** The username of the user used to create the API token */
  username?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateGitlabIntegrationMutationPayload = {
  __typename?: 'UpdateGitlabIntegrationMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  integration?: Maybe<IntegrationAuthenticationType>;
};

export type UpdateLabelMutationInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  projects?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  slug: Scalars['ID']['input'];
};

export type UpdateLabelMutationPayload = {
  __typename?: 'UpdateLabelMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  label?: Maybe<LabelType>;
};

export type UpdateMetricImpactSourceMutationInput = {
  /** Your organization has multiple integrations connected. Choose which one this impact source will use. */
  auth?: InputMaybe<Scalars['String']['input']>;
  /** All values below this value will be considered healthy. For values at or above this value we will perform a statistical analysis. If higher values indicate improvement for this impact source, then the comparison is turned around and all values above this value will be considered healthy, while for values at or below this value we will perform the statistical analysis. */
  earliestNonhealthyThreshold?: InputMaybe<Scalars['Float']['input']>;
  environment?: InputMaybe<Scalars['String']['input']>;
  /** All values below this value will be considered healthy. All values at or above it unhealthy. We won't perform any statistical analysis. If higher values indicate improvement for this impact source, then the comparison is turned around and all values above this value will be considered healthy, while all values at or below this value will be considered unhealthy. */
  indisputableThreshold?: InputMaybe<Scalars['Float']['input']>;
  /** By default we treat a lower value as indicating an improvement of service. Changing this value changes the metrics vector such that lower values will indicate the service is degraded. */
  lessIsBetter?: InputMaybe<Scalars['Boolean']['input']>;
  /** DEPRECATED, Use earliestNonhealthyThreshold instead. */
  manuallySetHealthThreshold?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  projectSlug: Scalars['ID']['input'];
  provider?: InputMaybe<MetricImpactProvider>;
  query?: InputMaybe<Scalars['String']['input']>;
  slug: Scalars['ID']['input'];
};

export type UpdateMetricImpactSourceMutationPayload = {
  __typename?: 'UpdateMetricImpactSourceMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  impactSource?: Maybe<MetricImpactSource>;
};

export type UpdateOrganizationInputType = {
  addressCity?: InputMaybe<Scalars['String']['input']>;
  addressCountry?: InputMaybe<Scalars['String']['input']>;
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  addressPostalCode?: InputMaybe<Scalars['String']['input']>;
  addressState?: InputMaybe<Scalars['String']['input']>;
  defaultRole?: InputMaybe<Role>;
  description?: InputMaybe<Scalars['String']['input']>;
  emailDomain?: InputMaybe<Scalars['String']['input']>;
  loginMethods?: InputMaybe<Array<LoginMethodInputType>>;
  name?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOrganizationMutation = {
  __typename?: 'UpdateOrganizationMutation';
  success: Scalars['Boolean']['output'];
};

export type UpdatePagerDutyImpactSourceMutationInput = {
  environmentName?: InputMaybe<Scalars['String']['input']>;
  historicInit?: InputMaybe<Scalars['Boolean']['input']>;
  integrationSlug?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  projectSlug: Scalars['ID']['input'];
  remoteServices?: InputMaybe<Scalars['String']['input']>;
  remoteUrgency?: InputMaybe<PagerDutyUrgencyOptions>;
  slug: Scalars['ID']['input'];
};

export type UpdatePagerDutyImpactSourceMutationPayload = {
  __typename?: 'UpdatePagerDutyImpactSourceMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  impactSource?: Maybe<PagerDutyIncidentImpactSource>;
};

export type UpdateProjectMutationInput = {
  /** Don't see the desired build integration here? Make sure you configured it in settings. */
  buildIntegrationSlug?: InputMaybe<Scalars['String']['input']>;
  buildProvider?: InputMaybe<TrackBuildProviderType>;
  changeFailureRateBoundary?: InputMaybe<DeployHealthType>;
  cltStartDefinition?: InputMaybe<CltStartDefinitionType>;
  cltStartStates?: InputMaybe<Array<Scalars['ID']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  detectRollbacks?: InputMaybe<Scalars['Boolean']['input']>;
  /** The amount of time (in minutes) a deploy must spend in a failure status (Unhealthy, Incident, etc.) before it is determined a failure. Setting this value to a longer time means that less deploys will be classified. */
  failureSensitivity?: InputMaybe<Scalars['Int']['input']>;
  impactSensitivity?: InputMaybe<ImpactSensitivity>;
  issueIntegrationSlug?: InputMaybe<Scalars['String']['input']>;
  issueTrackerProvider?: InputMaybe<IssueTrackerChoices>;
  lockable?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  slug: Scalars['ID']['input'];
  strictIssueMatching?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateProjectMutationPayload = {
  __typename?: 'UpdateProjectMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  project?: Maybe<ProjectType>;
};

export type UpdateTeamMutationInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  parent?: InputMaybe<Scalars['String']['input']>;
  slug: Scalars['ID']['input'];
};

export type UpdateTeamMutationPayload = {
  __typename?: 'UpdateTeamMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  team?: Maybe<TeamType>;
};

export type UpdateUserMutation = {
  __typename?: 'UpdateUserMutation';
  user?: Maybe<UserType>;
};

export type UserPaginatedType = {
  __typename?: 'UserPaginatedType';
  count: Scalars['Int']['output'];
  hasNext: Scalars['Boolean']['output'];
  hasPrev: Scalars['Boolean']['output'];
  objects: Array<UserType>;
  page: Scalars['Int']['output'];
  pages: Scalars['Int']['output'];
};

export type UserPermissionsType = {
  __typename?: 'UserPermissionsType';
  canChangeBilling: Scalars['Boolean']['output'];
  canChangeOrg: Scalars['Boolean']['output'];
  canChangeOrgConfig: Scalars['Boolean']['output'];
};

export type UserSetupType = {
  __typename?: 'UserSetupType';
  confirmedEmail: Scalars['Boolean']['output'];
  noSlack: Scalars['Boolean']['output'];
  socialConnections: Array<Scalars['String']['output']>;
};

export type UserType = {
  __typename?: 'UserType';
  display: Scalars['String']['output'];
  email: Scalars['String']['output'];
  externalUsers: Array<ExternalUserType>;
  firstName: Scalars['String']['output'];
  gravatar: Scalars['String']['output'];
  hasUsablePassword: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  isActive: Scalars['Boolean']['output'];
  /** Designates whether the user can log into this admin site. */
  isStaff: Scalars['Boolean']['output'];
  /** Designates that this user has all permissions without explicitly assigning them. */
  isSuperuser: Scalars['Boolean']['output'];
  isUserManagedBySaml: Scalars['Boolean']['output'];
  isUserSetupComplete: Scalars['Boolean']['output'];
  lastName: Scalars['String']['output'];
  organizations: Array<OrganizationType>;
  permissions: UserPermissionsType;
  personalTokens: AccessTokenConnection;
  role?: Maybe<Role>;
  seen: Array<SeenEventType>;
  setup: UserSetupType;
  slackUserId?: Maybe<Scalars['String']['output']>;
  /** Team slugs user is member of. */
  teamSlugs: Array<Scalars['String']['output']>;
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username: Scalars['String']['output'];
  /** EXPERIMENTAL FIELD, can change at any time. */
  workingHours?: Maybe<WorkingHoursType>;
};


export type UserTypePersonalTokensArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export enum UsersType {
  All = 'ALL',
  Contributors = 'CONTRIBUTORS',
  Members = 'MEMBERS'
}

export type ValidateRulesMutation = {
  __typename?: 'ValidateRulesMutation';
  rules: Array<Maybe<RuleType>>;
};

export type WipContextFilterArguments = {
  /** If no deployment slug is provided, we default to all deployments from this org */
  deploymentSlugs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** If no environment name is provided, we default to the default environment of each project. The value is case insensitive. */
  environmentName?: InputMaybe<Scalars['String']['input']>;
  /** If no project slugs are provided, we default to all projects from this org */
  projectSlugs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** If no team slugs are provided, we default to all teams from this org */
  teamSlugs?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type WebhookType = {
  __typename?: 'WebhookType';
  events?: Maybe<Array<Scalars['String']['output']>>;
  secret?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
};

export enum WipItemsFilter {
  /** All items */
  All = 'ALL',
  /** Any associated risk */
  AnyRisk = 'ANY_RISK',
  /** Batch size risk */
  BatchSizeRisk = 'BATCH_SIZE_RISK',
  /** CLT total risk */
  CltTotalRisk = 'CLT_TOTAL_RISK',
  /** Coding risk */
  CodingRisk = 'CODING_RISK',
  /** Deploying risk */
  DeployingRisk = 'DEPLOYING_RISK',
  /** Review lag risk */
  ReviewLagRisk = 'REVIEW_LAG_RISK',
  /** Review risk */
  ReviewRisk = 'REVIEW_RISK'
}

export enum WipItemsSort {
  /** Batch size */
  BatchSize = 'BATCH_SIZE',
  /** CLT total */
  CltTotal = 'CLT_TOTAL',
  /** Coding time */
  CodingTime = 'CODING_TIME',
  /** Deploying time */
  DeployingTime = 'DEPLOYING_TIME',
  /** Last updated */
  LastUpdated = 'LAST_UPDATED',
  /** Review lag time */
  ReviewLagTime = 'REVIEW_LAG_TIME',
  /** Review time */
  ReviewTime = 'REVIEW_TIME',
  /** Risk count */
  RiskCount = 'RISK_COUNT'
}

export type WipNotificationInputType = {
  environmentName?: InputMaybe<Scalars['String']['input']>;
  orgSlug: Scalars['ID']['input'];
  projectSlug?: InputMaybe<Scalars['ID']['input']>;
  teamSlug?: InputMaybe<Scalars['ID']['input']>;
};

export type WipRecapType = {
  __typename?: 'WipRecapType';
  baselineClt: OrgMetricLeadTimeType;
  currentClt: OrgMetricLeadTimeType;
};

export type WipRiskBreakdownType = {
  __typename?: 'WipRiskBreakdownType';
  cltTotal: Scalars['Int']['output'];
  cltTotalIsRisk: Scalars['Boolean']['output'];
  codingTime: Scalars['Int']['output'];
  codingTimeIsRisk: Scalars['Boolean']['output'];
  deployingTime: Scalars['Int']['output'];
  deployingTimeIsRisk: Scalars['Boolean']['output'];
  reviewLagTime: Scalars['Int']['output'];
  reviewLagTimeIsRisk: Scalars['Boolean']['output'];
  reviewTime: Scalars['Int']['output'];
  reviewTimeIsRisk: Scalars['Boolean']['output'];
};

export type WipSummaryType = {
  __typename?: 'WipSummaryType';
  atRiskCount: Scalars['Int']['output'];
  baselineDeployCount: Scalars['Int']['output'];
  batchSizeAtRiskCount: Scalars['Int']['output'];
  cltTotalAtRiskCount: Scalars['Int']['output'];
  codingTimeAtRiskCount: Scalars['Int']['output'];
  deployingTimeAtRiskCount: Scalars['Int']['output'];
  reviewLagTimeAtRiskCount: Scalars['Int']['output'];
  reviewTimeAtRiskCount: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type WorkInProgressItem = {
  authors: DisplayAuthorConnection;
  batchSize: BatchSize;
  batchSizeIsRisk?: Maybe<Scalars['Boolean']['output']>;
  breadcrumbs?: Maybe<Array<WorkInProgressItemBreadcrumb>>;
  cltTotal?: Maybe<Scalars['Int']['output']>;
  cltTotalIsRisk?: Maybe<Scalars['Boolean']['output']>;
  codingTime: Scalars['Int']['output'];
  codingTimeIsRisk?: Maybe<Scalars['Boolean']['output']>;
  deployingTime?: Maybe<Scalars['Int']['output']>;
  deployingTimeIsRisk?: Maybe<Scalars['Boolean']['output']>;
  itemUpdatedOn: Scalars['DateTime']['output'];
  reviewLagTime?: Maybe<Scalars['Int']['output']>;
  reviewLagTimeIsRisk?: Maybe<Scalars['Boolean']['output']>;
  reviewTime?: Maybe<Scalars['Int']['output']>;
  reviewTimeIsRisk?: Maybe<Scalars['Boolean']['output']>;
  riskCount: Scalars['Int']['output'];
};


export type WorkInProgressItemAuthorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type WorkInProgressItemBreadcrumb = {
  __typename?: 'WorkInProgressItemBreadcrumb';
  displayName: Scalars['String']['output'];
};

export type WorkInProgressItemType = PrInProgress;

export type WorkInProgressItemTypeEdge = {
  __typename?: 'WorkInProgressItemTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: WorkInProgressItemType;
};

export type WorkInProgressItemsConnection = {
  __typename?: 'WorkInProgressItemsConnection';
  batchSizeBreakdown: DeploySizesType;
  cltRiskBreakdown: WipRiskBreakdownType;
  edges: Array<WorkInProgressItemTypeEdge>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of edges. */
  totalCount: Scalars['Int']['output'];
};

export type WorkingHoursInputType = {
  duration: Scalars['String']['input'];
  recurrence: Scalars['String']['input'];
  startTime: Scalars['Time']['input'];
};

export type WorkingHoursType = {
  __typename?: 'WorkingHoursType';
  duration: Scalars['String']['output'];
  recurrence?: Maybe<Scalars['String']['output']>;
  startTime: Scalars['Time']['output'];
};

export type WorkingHoursUsedForPrcltCalculationType = {
  __typename?: 'WorkingHoursUsedForPRCLTCalculationType';
  user?: Maybe<UserType>;
  workingHours: WorkingHoursType;
};

export type WorstDeployInsightType = {
  __typename?: 'WorstDeployInsightType';
  cycleBucketType: CycleBucketType;
  deployLink: Scalars['String']['output'];
  deployShortRevision: Scalars['String']['output'];
  diff: Scalars['Float']['output'];
  icon: Scalars['String']['output'];
  message: Scalars['String']['output'];
};
