<script setup lang="ts">
import { ref } from 'vue'
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide } from 'vue3-carousel'

const currentSlide = ref(0)

function toSlide(slide: number) {
  currentSlide.value = slide
}
</script>

<template>
  <div>
    <Carousel v-model="currentSlide">
      <Slide v-for="(slot, index) of Object.keys($slots)" :key="index">
        <slot :name="slot" />
      </Slide>
    </Carousel>

    <Carousel
      :items-to-show="Object.keys($slots).length"
      :mouse-drag="false"
      :touch-drag="false"
      class="thumbnails tw-mt-2"
    >
      <Slide
        v-for="(slot, index) of Object.keys($slots)"
        :key="index"
        class="tw-max-w-[150px]"
      >
        <div class="tw-mx-1 tw-cursor-pointer" @click="toSlide(index)">
          <slot :name="slot" />
        </div>
      </Slide>
    </Carousel>
  </div>
</template>

<style scoped>
:deep(.carousel__track) {
  margin: 0;
}
:deep(.thumbnails .carousel__track) {
  justify-content: center;
}
</style>
