import DOMPurify from 'dompurify'

import type { LocationQueryValue } from 'vue-router'
import colors from '~/colors.json'
import { DeployHealthType } from '~/types/graphql'

import type { ChangeType, CodeChangeSource } from '~/types/graphql'

export const PAGE_SIZE = 10

const rootStyle = getComputedStyle(document.body)

export function getColor(colorName: string): string {
  return rootStyle.getPropertyValue(`--color-${colorName}`).trim()
}

export function pluralize(word: string, count: number): string {
  if (word === 'more')
    return 'more'
  return count > 1 || count === 0 ? `${word}s` : word
}

export function round(value: number, maximumFractionDigits: number): string {
  return value.toLocaleString(undefined, { maximumFractionDigits })
}

export function healthTextClasses(health: DeployHealthType): string[] {
  return {
    [DeployHealthType.Healthy]: ['tw-text-green-700'],
    [DeployHealthType.Ailing]: ['tw-text-yellow-500'],
    [DeployHealthType.Unhealthy]: ['tw-text-red-400'],
    [DeployHealthType.RolledBack]: ['tw-text-red-400'],
    [DeployHealthType.Incident]: ['tw-text-red-400'],
    [DeployHealthType.Improved]: ['tw-text-blue-400'],
  }[health]
}

export function healthBackgroundClasses(health: DeployHealthType): string[] {
  return {
    [DeployHealthType.Healthy]: ['tw-bg-green-50', 'dark:tw-bg-green-900'],
    [DeployHealthType.Ailing]: ['tw-bg-yellow-50', 'dark:tw-bg-yellow-800'],
    [DeployHealthType.Unhealthy]: ['tw-bg-red-50', 'dark:tw-bg-red-900'],
    [DeployHealthType.RolledBack]: ['tw-bg-red-50', 'dark:tw-bg-red-900'],
    [DeployHealthType.Incident]: ['tw-bg-red-50', 'dark:tw-bg-red-900'],
    [DeployHealthType.Improved]: ['tw-bg-blue-50', 'dark:tw-bg-blue-900'],
  }[health]
}

export function healthColors(health: DeployHealthType): string {
  return {
    [DeployHealthType.Healthy]: colors.green['500'],
    [DeployHealthType.Improved]: colors.blue['500'],
    [DeployHealthType.Ailing]: colors.yellow['400'],
    [DeployHealthType.Unhealthy]: colors.red['500'],
    [DeployHealthType.RolledBack]: colors.red['500'],
    [DeployHealthType.Incident]: colors.red['500'],
  }[health]
}

export function healthColorsDescriptive(health: DeployHealthType): string {
  return {
    [DeployHealthType.Healthy]: 'green',
    [DeployHealthType.Improved]: 'green',
    [DeployHealthType.Ailing]: 'orange',
    [DeployHealthType.Unhealthy]: 'red',
    [DeployHealthType.RolledBack]: 'red',
    [DeployHealthType.Incident]: 'red',
  }[health]
}

export function compareChangeSourcesByLatestChange(a: { latestChange?: { on: string } | null }, b: { latestChange?: { on: string } | null }): number {
  const dateA = a?.latestChange?.on ?? '1970'
  const dateB = b?.latestChange?.on ?? '1970'
  return dateB.localeCompare(dateA)
}

export function getLatestChangeSlug(changeSources: (Pick<CodeChangeSource, 'collectImpact'> & {
  latestChange?: Pick<ChangeType, 'on' | 'slug'> | null
})[]): string | null {
  const relevantChangeSource = changeSources
    .filter(changeSource => changeSource.collectImpact)
    .sort(compareChangeSourcesByLatestChange)[0]

  if (relevantChangeSource?.latestChange)
    return relevantChangeSource.latestChange.slug

  return null
}

export function getCookie(name: string): string | null {
  if (document.cookie && document.cookie !== '') {
    const cookie = document.cookie.split(';').find(c => c.includes(name))
    if (cookie)
      return cookie.split('=')[1] ?? null
  }
  return null
}

export function ensureStringArray(val: LocationQueryValue | LocationQueryValue[] | string | number | string[]): string[] {
  if (!val)
    return []

  return Array.isArray(val)
    ? val.map(v => (v ? v.toString() : '')).filter(Boolean)
    : [val.toString()]
}

export function ensureOptionalString(val: LocationQueryValue | LocationQueryValue[]): string | undefined {
  const singleValue = Array.isArray(val) ? val[0] : val
  return singleValue?.toString()
}

export function ensureInt(val: string | number | undefined | LocationQueryValue | LocationQueryValue[], default_val: number | undefined): number | undefined {
  if (val === undefined)
    return default_val

  if (typeof val === 'number')
    return val

  const string_val = ensureOptionalString(val)
  if (string_val === undefined)
    return default_val
  return Number.parseInt(string_val)
}

function isNumeric(num: number | string) {
  return (typeof num === 'number' || (typeof num === 'string' && num.trim() !== ''))
    && !Number.isNaN(Number.parseInt(num.toString(), 10))
}

export function getSortedItemSlots(slots: Record<string, any>) {
  return Object.keys(slots)
    .filter(name => name.startsWith('item'))
    .sort((a, b) => {
      const [_a, indexA] = a.split('-')
      const [_b, indexB] = b.split('-')
      if (isNumeric(indexA) && isNumeric(indexB))
        return Number(indexA) - Number(indexB)

      return a.localeCompare(b)
    })
}

export function getNumericHealthValue(health: DeployHealthType): number {
  return [
    DeployHealthType.Healthy,
    DeployHealthType.Improved,
    DeployHealthType.Ailing,
    DeployHealthType.Unhealthy,
    DeployHealthType.RolledBack,
    DeployHealthType.Incident,
  ].indexOf(health)
}

export function replaceLocationIfNotSpa(e: Event, isSpa: boolean) {
  const target = e.currentTarget
  if (!isSpa && target instanceof HTMLAnchorElement)
    window.location.replace(target.href)
}

export function getListOfNodes<T>(connection:
  | {
    edges: { node: T }[]
  }
  | undefined): T[] {
  if (!connection)
    return []
  return connection.edges.map(edge => edge.node)
}

export function sanitizeForInnerHtml(value: string) {
  return DOMPurify.sanitize(value, { USE_PROFILES: { html: false } })
}

export function sanitizeDropdownOption<T extends { label: string }>(option: T): T {
  return {
    ...option,
    label: sanitizeForInnerHtml(option.label),
  }
}

export function getDataFromBody(scriptElementId: string): string {
  const scriptElement = document.querySelector(`script#${scriptElementId}`)
  if (!scriptElement)
    return ''
  return JSON.parse(scriptElement.textContent ?? '')
}

export function getCsrfToken(): string {
  return (
    document.querySelector('[name="csrfmiddlewaretoken"]') as HTMLInputElement
  ).value
}

export function shortenString(text: string, maxCharacters: number, insertDots = true): string {
  return (
    text.substring(0, maxCharacters)
    + (text.length > maxCharacters && insertDots ? '...' : '')
  )
}

export const CURSOR_PAGINATION_PARAMS = ['first', 'last', 'before', 'after']
