<script lang="ts" setup>
import { useClipboard, usePermission, useToggle } from '@vueuse/core'
import { computed, ref } from 'vue'

import BaseButtonIcon from './BaseButtonIcon.vue'

import ILightEye from '~icons/light/eye'
import ILightEyeSlash from '~icons/light/eye-slash'

const props = defineProps<{
  text: string
  whitespacePre?: boolean
  obfuscate?: boolean
}>()

const [isObfuscated, toggleObfuscated] = useToggle(props.obfuscate || false)
const icon = computed(() => (isObfuscated.value ? ILightEye : ILightEyeSlash))

const { copy } = useClipboard({ read: false })
// Safari and Firefox don't support this permission yet (ref: https://caniuse.com/?search=clipboard-write)
// and will always return 'prompt'
const permissionWrite = usePermission('clipboard-write')

const copied = ref(false)

async function copyAndInformUser() {
  if (permissionWrite.value !== 'denied') {
    copied.value = true

    window.setTimeout(() => {
      copied.value = false
    }, 2000)

    await copy(props.text)
  }
}

const message = computed(() => (copied.value ? 'Copied' : 'Copy'))
</script>

<template>
  <div
    class="tw-relative tw-rounded-lg tw-bg-gray-100 tw-px-4 tw-py-5 dark:tw-border dark:tw-border-gray-700 dark:tw-bg-gray-800 dark:tw-text-gray-200"
  >
    <div
      class="tw-flex tw-items-center tw-overflow-x-auto tw-break-all tw-font-mono tw-text-sm"
      data-test="clipboard-text"
      :class="whitespacePre ? ['tw-whitespace-pre '] : []"
    >
      <BaseButtonIcon
        v-if="obfuscate"
        :icon="icon"
        :aria-label="obfuscate ? 'reveal' : 'obfuscate'"
        class="tw-mr-2"
        @click="() => toggleObfuscated()"
      />{{ isObfuscated ? '*'.repeat(text.length) : text }}
    </div>
    <div
      class="svg-baseline tw-absolute tw-right-0 tw-top-0 tw-flex tw-cursor-pointer tw-select-none tw-justify-end tw-rounded-tr-lg tw-bg-blue-100 tw-p-0.5 tw-font-mono tw-text-xs hover:tw-text-blue-200 dark:tw-bg-gray-700"
      :class="copied ? ['tw-pointer-events-none'] : []"
      @click="copyAndInformUser"
    >
      <span class="tw-mx-1">{{ message }}</span>
      <ILightCheck v-if="copied" class="tw-text-sm tw-text-green-200" />
      <ILightCopy v-else />
    </div>
  </div>
</template>
