<script setup lang="ts">
import { useQuery } from 'villus'
import { computed, nextTick, watch } from 'vue'
import { useRoute } from 'vue-router'

import { isCustomYamlTool } from '../tools/custom-yaml'
import BaseBreadcrumbs from '~/components/BaseBreadcrumbs.vue'
import BaseButton from '~/components/BaseButton.vue'
import BasePage from '~/components/BasePage.vue'
import PageTitle from '~/components/PageTitle.vue'
import useIsUserLoggedIn from '~/composables/user'
import getTagsAndToolType from '~/pages/automations/tools/tag-mapping'
import { GetToolDetailsDocument } from '~/pages/automations/tools/tool_details/ToolDetails.generated'
import ToolDetails from '~/pages/automations/tools/tool_details/ToolDetails.vue'
import { shortenString } from '~/utils/utils'

const props = defineProps<{
  orgSlug?: string
  toolSlug: string
  baseurl?: string
}>()

const { data, isFetching, isDone } = useQuery({
  query: GetToolDetailsDocument,
  variables: {
    slug: props.toolSlug,
  },
})

const installToolHref = computed(
  () => `${props.baseurl}/install-tool/${props.toolSlug}`,
)

const toolType = computed(
  () => getTagsAndToolType(data.value?.tool?.tags).toolType,
)

const numFormatter = Intl.NumberFormat('en', {
  notation: 'compact',
  maximumFractionDigits: 1,
})
const installationCount = computed(() =>
  numFormatter.format(data.value?.tool?.installationCount ?? 0),
)
const { isUserLoggedIn } = useIsUserLoggedIn()

const route = useRoute()

watch(data, (newData) => {
  if (!newData?.tool)
    return

  window.analytics.track('card_viewed', {
    object: 'automation',
    action: 'viewed',
    page_url: window.location.href,
    card_id: newData.tool.slug,
    card_name: newData.tool.name,
    filter_name: route.query.filter?.toString() ?? '',
  })
})

function trackInstall(event: PointerEvent) {
  const tool = data.value?.tool
  if (!tool)
    return

  window.analytics.track('install_clicked', {
    object: 'install',
    action: 'clicked',
    page_url: window.location.href,
    card_id: tool.slug,
    card_name: tool.name,
    filter_name: route.query.filter?.toString() ?? '',
  })

  // Deferring the navigation to allow the analytics event to be sent
  const href = (event.currentTarget as HTMLLinkElement).href
  void nextTick(() => {
    window.location.href = href
  })
}
</script>

<template>
  <BasePage
    :is-fetching="isFetching"
    :is-auto-re-fetching="false"
    :is-done="isDone"
  >
    <PageTitle :part1="data?.tool?.name ?? ''" part2="Automations" />

    <BaseBreadcrumbs
      organization-name="Automation marketplace"
      :organization-route-link="{
        name: 'automations',
        query: route.query,
      }"
      :project-name="toolType?.label || ''"
      :project-route-link="{
        name: 'automations',
        query: { filter: toolType?.tag },
      }"
      :environment-name="shortenString(data?.tool?.name ?? '', 50)"
    />

    <div
      class="tw-mt-10 tw-flex tw-flex-col dark:tw-text-gray-200 md:tw-flex-row"
    >
      <ToolDetails
        :tool="data?.tool"
        :skip-custom-yaml="isCustomYamlTool(toolSlug)"
      />
      <div class="tw-mt-10 tw-shrink-0 md:tw-ml-12 md:tw-mt-0 md:tw-w-80">
        <template v-if="data?.tool?.linkedFeatureUrl">
          <BaseButton
            :href="data?.tool.linkedFeatureUrl"
            target="_blank"
            variant="regular"
            size="medium"
            class="tw-w-full tw-px-8 md:tw-w-auto"
            data-element="install-tool-button"
          >
            Workflow setup
          </BaseButton>
        </template>
        <template v-else>
          <BaseButton
            v-if="orgSlug && isUserLoggedIn"
            :route="{ name: 'toolInstall' }"
            variant="regular"
            size="medium"
            class="tw-w-full tw-px-8 md:tw-w-auto"
            data-element="install-tool-button"
            data-test="install-tool-button"
          >
            Install
          </BaseButton>
          <BaseButton
            v-else
            variant="regular"
            size="medium"
            class="tw-w-full tw-px-8 md:tw-w-auto"
            data-element="install-tool-button"
            data-test="install-tool-button"
            :href="installToolHref"
            @click.prevent="trackInstall($event)"
          >
            Install in Sleuth
          </BaseButton>
        </template>

        <div
          v-if="!data?.tool?.linkedFeatureUrl && !isCustomYamlTool(toolSlug)"
          class="tw-mt-3 tw-flex tw-items-center tw-text-gray-500 dark:tw-text-gray-400"
        >
          <ILightDownload class="tw-mr-2" /> {{ installationCount }} installs
        </div>
      </div>
    </div>
  </BasePage>
</template>
