import '~/../../Open_Sans/opensans.css'
import '~/../../css/colors.css'
import '~/../../css/custom_bootstrap_style.css'
import '~/../../css/global.css'
import 'tailwindcss/tailwind.css'

import { isEqual } from 'lodash-es'
import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'

import App from './App.vue'
import { routes } from './routes'

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    const pageChanged = !isEqual(from.params, to.params)
    const restoreScroll = to.meta.preserveScroll && savedPosition?.top

    const htmlEl = document.getElementById('html')
    if (!htmlEl)
      return
    if (pageChanged && restoreScroll) {
      // Need to delay the scroll a bit to allow the cards to render
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({ top: savedPosition.top })
        }, 10)
      })
    }
    else {
      return { top: 0 }
    }
  },
})

router.afterEach((to, from) => {
  if (to.fullPath !== from.fullPath) {
    window.analytics.track('page_viewed', {
      object: 'page',
      action: 'viewed',
      page_url: window.location.origin + to.fullPath,
    })
  }
})

const app = createApp(App)

app.use(router)

app.mount('#app')
