<script setup lang="ts">
import { computed } from 'vue'

import { wrapYamlWithRulesRootKey } from '../custom-yaml'
import { getParametersFromSchema } from '../schema-parsing'
import { YamlVariableReplacer } from '../variable-replacement'

import ToolBasicInfo from './ToolBasicInfo.vue'
import ToolDescriptionTab from './ToolDescriptionTab.vue'
import type { GetToolDetailsQuery } from './ToolDetails.generated'
import ToolMetricsTab from './ToolMetricsTab.vue'
import ToolYamlTab from './ToolYamlTab.vue'
import type { ToolInstallationStatsType } from '~/types/graphql'
import getTagsAndToolType from '~/pages/automations/tools/tag-mapping'
import BaseModernTabs from '~/components/BaseModernTabs.vue'

const props = defineProps<{
  tool: GetToolDetailsQuery['tool']
  customToolProperties?: string
  metricsTab?: boolean
  stats?: ToolInstallationStatsType | null
  skipCustomYaml?: boolean
}>()

const { toolType } = getTagsAndToolType(props.tool?.tags)

const isInstalledTool = computed(() => props.metricsTab)

const parsedCustomProperties = computed(() =>
  props.customToolProperties
    ? (JSON.parse(props.customToolProperties) as Record<string, string>)
    : undefined,
)

const customYaml = computed(() => parsedCustomProperties.value?.customYaml)
const showYamlTab = computed(
  () => (props.tool?.sourceYaml || customYaml.value) && !props.skipCustomYaml,
)

const tabs = computed(() => {
  const tabs = [{ label: 'Details', id: 'details' }]

  if (props.metricsTab && toolType?.tag === 'pr_check')
    tabs.push({ label: 'Metrics', id: 'metrics' })

  if (showYamlTab.value)
    tabs.push({ label: 'YAML', id: 'yaml' })

  return tabs
})

const hideTabs = computed(() => tabs.value.length <= 1)

const yamlWithReplacedVariables = computed(() => {
  if (!props.tool?.sourceYaml || !props.tool?.parameterSchema)
    return

  const parsedSchema = JSON.parse(props.tool.parameterSchema)
  const schemaParameters = getParametersFromSchema(parsedSchema)

  const replacer = new YamlVariableReplacer(
    wrapYamlWithRulesRootKey(props.tool.sourceYaml),
    parsedCustomProperties.value ?? {},
    schemaParameters,
  )
  return replacer.replace()
})
</script>

<template>
  <div class="tw-flex-auto tw-overflow-x-hidden">
    <ToolBasicInfo :tool="tool" :custom-name="parsedCustomProperties?.name" />

    <BaseModernTabs :tabs="tabs" :hide-tabs="hideTabs">
      <template #details>
        <ToolDescriptionTab :tool="tool" />
      </template>

      <template #metrics>
        <ToolMetricsTab v-if="stats" :stats="stats" :tool="tool" />
      </template>

      <template #yaml>
        <ToolYamlTab
          v-if="showYamlTab"
          :yaml="yamlWithReplacedVariables || customYaml"
          :custom-description="parsedCustomProperties?.description"
          :show-rule-name-warning="isInstalledTool"
        />
      </template>
    </BaseModernTabs>
  </div>
</template>
