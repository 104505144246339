import MarketplaceToolDetails from '~/pages/automations/marketplace/MarketplaceToolDetails.vue'
import MarketplacePage from '~/pages/automations/marketplace/_Page.vue'

// make sure route names are matching ones with ones in `sleuth/static/js/spa/routes.ts`
export const routes = [
  {
    path: '/',
    name: 'automations',
    redirect: () => ({ name: 'toolsListing' }),
    children: [
      {
        path: '',
        component: MarketplacePage,
        name: 'toolsListing',
        meta: { preserveScroll: true },
      },
      {
        path: ':toolSlug',
        component: MarketplaceToolDetails,
        name: 'marketplaceToolDetails',
        props: true,
      },
    ],
  },
]
