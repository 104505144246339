<script lang="ts" setup>
import FadeInOnFetchWrapper from './FadeInOnFetchWrapper.vue'
import useNavigation from '~/composables/navigation'

defineProps<{
  isFetching: boolean
  isAutoReFetching: boolean
  isDone: boolean
  additionalContainerClasses?: string[]
}>()

const { isNavigating } = useNavigation()
</script>

<template>
  <FadeInOnFetchWrapper
    class="tw-relative tw-bg-gray-50 tw-p-6 tw-text-sm tw-text-gray-700 dark:tw-bg-gray-900 dark:tw-text-gray-200"
    :is-done="isDone && !isNavigating"
    :is-auto-re-fetching="isAutoReFetching"
    :is-fetching="isFetching || isNavigating"
  >
    <template #default>
      <div
        class="tw-mx-auto tw-max-w-[1338px]"
        :class="additionalContainerClasses"
      >
        <slot />
      </div>
    </template>
    <template #out-of-transition>
      <div class="tw-mx-auto tw-max-w-[1338px]">
        <slot name="out-of-transition" />
      </div>
    </template>
  </FadeInOnFetchWrapper>
</template>
