<script lang="ts" setup>
import { useToggle } from '@vueuse/core'
import { computed } from 'vue'

import ILightCircleCheck from '~icons/light/circle-check'
import ILightCircleExclamation from '~icons/light/circle-exclamation'
import ILightCircleInfo from '~icons/light/circle-info'
import ILightTriangleExclamation from '~icons/light/triangle-exclamation'

const props = withDefaults(
  defineProps<{
    variant?: 'warning' | 'danger' | 'success' | 'info'
    dismissible?: boolean
    icon?: boolean
    title?: string
  }>(),
  {
    variant: 'warning',
    title: '',
  },
)
const [visible, hide] = useToggle(true)

const classes = computed(() => {
  return {
    warning: [
      'tw-text-yellow-500',
      'dark:tw-text-gray-200',
      'tw-bg-yellow-25',
      'dark:tw-bg-gray-800',
      'tw-border-yellow-300',
      'dark:tw-border-yellow-200',
    ],
    danger: [
      'tw-text-red-700',
      'dark:tw-text-gray-200',
      'tw-bg-red-25',
      'dark:tw-bg-gray-800',
      'tw-border-red-300',
      'dark:tw-border-red-400',
    ],
    success: [
      'tw-text-green-700',
      'dark:tw-text-gray-200',
      'tw-bg-green-25',
      'dark:tw-bg-gray-800',
      'tw-border-green-300',
      'dark:tw-border-green-500',
    ],
    info: [
      'tw-text-blue-700',
      'dark:tw-text-gray-200',
      'tw-bg-blue-25',
      'dark:tw-bg-gray-800',
      'tw-border-blue-300',
      'dark:tw-border-blue-700',
    ],
  }[props.variant]
})

const icons = {
  warning: ILightTriangleExclamation,
  danger: ILightCircleExclamation,
  success: ILightCircleCheck,
  info: ILightCircleInfo,
}
</script>

<template>
  <!-- Warning: editing classes in this file needs full client rebuild -->
  <div
    v-if="visible"
    class="tw-relative tw-mb-6 tw-flex tw-rounded tw-border tw-border-solid tw-border-opacity-100 tw-p-4"
    :class="classes"
    role="alert"
    :data-test="`alert-${variant}`"
  >
    <ISolidXmark
      v-if="dismissible"
      class="tw-absolute tw-right-2 tw-top-5 tw-cursor-pointer tw-text-gray-800 tw-text-opacity-50 hover:tw-text-opacity-100 dark:tw-text-gray-200"
      @click="hide()"
    />

    <component :is="icons[variant]" v-if="icon" class="tw-mr-3 tw-text-xl" />
    <div>
      <div
        v-if="title"
        class="tw-mb-1 tw-text-sm tw-font-semibold tw-brightness-95"
      >
        {{ props.title }}
      </div>
      <slot />
    </div>
  </div>
</template>

<style scoped>
:slotted(a) {
  color: inherit !important;
  text-decoration: underline !important;
}
</style>
