import { batch } from '@villus/batch'
import { cache, fetch } from 'villus'

import type { ClientPluginContext } from 'villus'
import { getCookie } from '~/utils/utils'

function authPlugin({ opContext }: ClientPluginContext): void {
  const csrfCookie = getCookie('csrftoken')
  if (csrfCookie)
    opContext.headers['X-CSRFToken'] = csrfCookie
}

export const villusClientOptions = {
  url: '/graphql-batch',
  use: [authPlugin, cache(), batch()],
}

export const villusClientOptionsWithoutBatching = {
  url: '/graphql',
  use: [authPlugin, cache(), fetch()],
}
